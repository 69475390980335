import { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import { Container, Button, Modal, Form, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import AuthProvider from "../auth/authProvider";
import noData from "../images/noData.png";
import CustomNavbar from "../headerpages/header";

interface ItemsData {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  mileage: number;
  negotiable: boolean;
  availability: boolean;
  voidStatus: boolean;
}

type RouteParams = {
  id: string;
};

const VendorAddItemDetails = () => {
  const [items, setItems] = useState<ItemsData[]>([]);
  const { id } = useParams<RouteParams>();

  const postId = Number(id);

  const [partCondition, setPartCondition] = useState("");
  const [locationForPickUp, setLocationForPickUp] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [gearCondition, setGearCondition] = useState("");
  const [colorsAvailable, setColorsAvailable] = useState("");
  const [sizesAvailable, setSizesAvailable] = useState("");
  const [bikeCondition, setBikeCondition] = useState("");
  const [mileage, setMileage] = useState(0);
  const [yearOfManufacture, setYearOfManufacture] = useState(0);
  const [engineSize, setEngineSize] = useState(0);
  const [viewingLocation, setViewingLocation] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVendorProductData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/vendor-items/get-item-detail-for-vendor/${postId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const itemData = await response.json();

        if (response.ok) {
          setItems([itemData]);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchVendorProductData();
  }, [postId]);

  const handleBikeDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const bikeDetails = {
        bikeCondition,
        mileage,
        yearOfManufacture,
        engineSize,
        viewingLocation,
        otherDetails,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/create-bike-details/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(bikeDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message, postIdentity } = responseData;

      if (response.ok) {
        toast.success(`Bike details saved. Add images.`);
        navigate(`/item-add-images/${postIdentity}`);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error has occured ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGearDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const gearDetails = {
        gearCondition,
        colorsAvailable,
        sizesAvailable,
        otherDetails,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/create-gear-details/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(gearDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message, postIdentity } = responseData;

      if (response.ok) {
        const successMessage = "Gear details saved. Add images.";
        toast.success(successMessage);
        navigate(`/item-add-images/${postIdentity}`);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error has occured ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePartDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const bikePartsDetails = {
        partCondition,
        locationForPickUp,
        otherDetails,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/create-part-details/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(bikePartsDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message, postIdentity } = responseData;

      if (response.ok) {
        const successMessage = "Parts details saved. Add images.";
        toast.success(successMessage);
        navigate(`/item-add-images/${postIdentity}`);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error has occured ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthProvider>
      <div className="body">
        <CustomNavbar />

        <Container
          fluid
          style={{
            backgroundColor: "#F5F0F0",
            borderRadius: "20px",
            padding: "30px",
          }}
        >
          <Container>
            <h5 style={{ fontWeight: "bold" }} className="text-danger">
              Polite Notice
            </h5>
            <br />
            1. Since we operate in Kenya, we only allow the sale of strictly{" "}
            <b>Kenyan-registered bikes.</b> Non-Kenyan registered posts will be
            removed.
            <br />
            2. Only posts with a <b>description and images</b> are visible to
            customers. Having trouble uploading images? Try uploading 2 images
            at a time.
            <br />
            <br />
            Happy selling!
          </Container>
        </Container>

        <Container style={{ marginTop: "40px" }}>
          <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
            {items.length > 0 ? (
              items.map((item: ItemsData) =>
                item.itemCategory === 1 ||
                item.itemCategory === 2 ||
                item.itemCategory === 3 ? (
                  <>
                    <h5
                      key={item.productName}
                      style={{ fontWeight: "bold" }}
                      className="text-danger"
                    >
                      Add details for {item.productName}
                    </h5>
                    <br />
                    <br style={{ marginBottom: "20px" }} />
                    {item.itemCategory === 1 ? (
                      <>
                        <Form onSubmit={handleBikeDetails}>
                          <Form.Group className="mb-4">
                            <Form.Label>Bike condition</Form.Label>
                            <Form.Control
                              required
                              as="select"
                              value={bikeCondition}
                              onChange={(e) => setBikeCondition(e.target.value)}
                            >
                              <option disabled value="">
                                Select condition
                              </option>
                              <option value="New">New</option>
                              <option value="Used">Used</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group className="mb-4">
                            <Form.Label>Year of manufacture</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              value={yearOfManufacture}
                              onChange={(e) =>
                                setYearOfManufacture(
                                  parseInt(e.target.value, 10)
                                )
                              }
                            />
                          </Form.Group>

                          <Form.Group className="mb-4">
                            <Form.Label>Engine size (cc)</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              value={engineSize}
                              onChange={(e) =>
                                setEngineSize(parseInt(e.target.value, 10))
                              }
                            />
                          </Form.Group>

                          <Form.Group className="mb-4">
                            <Form.Label>Mileage (KMs)</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              value={mileage}
                              onChange={(e) =>
                                setMileage(parseInt(e.target.value, 10))
                              }
                            />
                          </Form.Group>

                          <Form.Group className="mb-4">
                            <Form.Label>Viewing location</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              value={viewingLocation}
                              onChange={(e) =>
                                setViewingLocation(e.target.value)
                              }
                            />
                          </Form.Group>

                          <Form.Group className="mb-4">
                            <Form.Label>Other details</Form.Label>
                            <br />
                            <small>
                              (Give clear narration to attract potential
                              customers)
                            </small>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              value={otherDetails}
                              onChange={(e) => setOtherDetails(e.target.value)}
                            />
                          </Form.Group>

                          <Modal.Footer>
                            <Button
                              variant="primary"
                              type="submit"
                              size="sm"
                              disabled={isSubmitting}
                            >
                              {isSubmitting === true
                                ? "Saving details"
                                : "Save details"}
                            </Button>
                          </Modal.Footer>
                        </Form>
                      </>
                    ) : (
                      <>
                        {item.itemCategory === 2 ? (
                          <>
                            <Form onSubmit={handleGearDetails}>
                              <Form.Group className="mb-4">
                                <Form.Label>Gear condition</Form.Label>
                                <Form.Control
                                  required
                                  as="select"
                                  value={gearCondition}
                                  onChange={(e) =>
                                    setGearCondition(e.target.value)
                                  }
                                >
                                  <option disabled value="">
                                    Select condition
                                  </option>
                                  <option value="New">New</option>
                                  <option value="Used">Used</option>
                                </Form.Control>
                              </Form.Group>

                              <Form.Group className="mb-4">
                                <Form.Label>Colors available</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  value={colorsAvailable}
                                  onChange={(e) =>
                                    setColorsAvailable(e.target.value)
                                  }
                                />
                              </Form.Group>

                              <Form.Group className="mb-4">
                                <Form.Label>Sizes available</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  value={sizesAvailable}
                                  onChange={(e) =>
                                    setSizesAvailable(e.target.value)
                                  }
                                />
                              </Form.Group>

                              <Form.Group className="mb-4">
                                <Form.Label>Other details</Form.Label>
                                <br />
                                <small>
                                  (Give clear narration to attract potential
                                  customers)
                                </small>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  value={otherDetails}
                                  onChange={(e) =>
                                    setOtherDetails(e.target.value)
                                  }
                                />
                              </Form.Group>

                              <Modal.Footer>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  size="sm"
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting === true
                                    ? "Saving details"
                                    : "Save details"}
                                </Button>
                              </Modal.Footer>
                            </Form>
                          </>
                        ) : (
                          <>
                            <Form onSubmit={handlePartDetails}>
                              <Form.Group
                                className="mb-4"
                                controlId="itemCondition"
                              >
                                <Form.Label>Part condition</Form.Label>
                                <Form.Control
                                  required
                                  as="select"
                                  value={partCondition}
                                  onChange={(e) =>
                                    setPartCondition(e.target.value)
                                  }
                                >
                                  <option disabled value="">
                                    Select condition
                                  </option>
                                  <option value="New">New</option>
                                  <option value="Used">Used</option>
                                </Form.Control>
                              </Form.Group>

                              <Form.Group className="mb-4">
                                <Form.Label>
                                  Location for viewing / pick up
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  value={locationForPickUp}
                                  onChange={(e) =>
                                    setLocationForPickUp(e.target.value)
                                  }
                                />
                              </Form.Group>

                              <Form.Group className="mb-4">
                                <Form.Label>Other details</Form.Label>
                                <br />
                                <small>
                                  (Give clear narration to attract potential
                                  customers)
                                </small>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  value={otherDetails}
                                  onChange={(e) =>
                                    setOtherDetails(e.target.value)
                                  }
                                />
                              </Form.Group>

                              <Modal.Footer>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  size="sm"
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting === true
                                    ? "Saving details"
                                    : "Save details"}
                                </Button>
                              </Modal.Footer>
                            </Form>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : null
              )
            ) : (
              <Col md={12} className="image-container">
                <div className="image-wrapper">
                  <img
                    src={noData}
                    alt="Product Images"
                    className="img-fluid"
                  />
                </div>
              </Col>
            )}
          </div>
        </Container>
        <br style={{ marginBottom: "80px" }} />
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default VendorAddItemDetails;
