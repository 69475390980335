import { FormEvent, useState } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../images/logo.png";
import Footer from "../headerpages/footer";
import NavigationPage from "../components/navigationPages";

const PasswordReset = () => {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [password, SetPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");

  const { token } = useParams<{ token: string }>();

  const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const userData = {
        password,
        confirmpassword,
      };
      const response = await fetch(
        `${BASE_API_URL}/auth/new-password-details/${token}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      const data = await response.json();
      const { statusCode, message } = data;

      if (response.ok) {
        const successMessage = "Password reset successful";
        toast.success(successMessage);
        SetPassword("");
        setConfirmpassword("");
        navigate("/login");
      } else {
        const errorMessage = `${statusCode} -  ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`Failed, please try again.${error}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="registration-page" style={{ marginBottom: "100px" }}>
      <Container className="py-5">
        <Row>
          <Col md={3} className="registration-info"></Col>
          <Col md={6} className="registration-info">
            <div className="registration-info-inner">
              <div style={{ textAlign: "center" }}>
                <span role="img" aria-label="Country Flag">
                  <img
                    src={logo}
                    alt=""
                    className="img-fluid"
                    style={{ width: "190px" }}
                  />
                </span>
                <br />
                <br />
                <h6>Set new password</h6>
              </div>
              <hr />
              <br />
              <br />
              <Form onSubmit={handleRegister}>
                <Form.Group controlId="formPassword" className="mb-4">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => SetPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPassword" className="mb-4">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button
                  disabled={submitting}
                  variant="primary"
                  type="submit"
                  className="w-100"
                >
                  {submitting === true
                    ? "Setting up new password"
                    : "Change password"}
                </Button>
              </Form>
              <br />
              <br />
              <br />
              <NavigationPage />
            </div>
          </Col>
          <Col md={3} className="registration-info"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default PasswordReset;
