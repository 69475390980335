import React, { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import CustomNavbar from "../headerpages/header";
import AuthProvider from "../auth/authProvider";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import PageLoader from "../components/pageLoader";
import NoData from "../images/noData.png";
import TimeDisplay from "../components/timeFormat";
import { Eye, Unlock, Trash, Lock, Tag } from "react-bootstrap-icons";

interface Category {
  id: number;
  itemsOnSale: string;
}

interface MyInquries {
  id: number;
  createdAt: string;
  views: number;
  requestTitle: string;
  voidInquiry: boolean;
  requestStatus: boolean;
  requestBody: string;
  itemsOnSale: string;
}

const LookingFor: React.FC = () => {
  const [categories, setcategories] = useState<Category[]>([]);
  const [inquiries, setInquiries] = useState<MyInquries[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [postId, setSelectedInquiryId] = useState(0);
  const [reasonForClosure, setReasonForClosure] = useState("");
  const [comments, setComments] = useState("");
  const [loading, setloading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setRequestTitle("");
    setUserContacts("");
    setRequestBody("Hi,\n\n I am looking for ");
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleShowDelete = (id: number) => {
    setSelectedInquiryId(id);
    setShowDelete(true);
  };
  const handleCloseShowDelete = () => {
    setShowDelete(false);
  };

  const [showCloseInquiry, setShowCloseInquiry] = useState(false);
  const handleShowCloseInquiry = (id: number) => {
    setShowCloseInquiry(true);
    setSelectedInquiryId(id);
  };
  const handleCloseShowCloseInquiry = () => {
    setShowCloseInquiry(false);
    setComments("");
    setReasonForClosure("");
  };

  const [requestTitle, setRequestTitle] = useState("");
  const [requestBody, setRequestBody] = useState("Hi, \n\n I am looking for ");
  const [userContacts, setUserContacts] = useState("");

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const inquiryDetails = { requestTitle, requestBody, userContacts };

      const response = await fetch(`${BASE_API_URL}/looking-for/make-inquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(inquiryDetails),
      });

      const data = await response.json();
      const { statusCode, message } = data;

      if (response.ok) {
        toast.success("Inquiry posted and an email sent to vendors");
        setInquiries((prevInquiries) => [data, ...prevInquiries]);
        handleCloseModal();
      } else {
        toast.error(`${statusCode} : ${message}`);
      }
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchMyInquiries = async () => {
      setloading(true);
      try {
        const response = await fetch(
          `${BASE_API_URL}/looking-for/get-my-inquiries`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response?.ok) {
          const data = await response?.json();
          setInquiries(data.inquiries);
          setcategories(data.categories);
        }
      } catch (error) {
        toast.error(`Error: ${error}`);
      } finally {
        setloading(false);
      }
    };

    fetchMyInquiries();
  }, []);

  const handleClose = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const details = { reasonForClosure, comments };
      const response = await fetch(
        `${BASE_API_URL}/looking-for/close-inquiry/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(details),
        }
      );

      const data = await response.json();
      const { statusCode, message, inquiries } = data;

      if (response.ok) {
        handleCloseShowCloseInquiry();
        toast.success("Inquiry closed successfully.");
        setInquiries(inquiries);
        handleCloseShowCloseInquiry();
      } else {
        toast.error(`${statusCode} : ${message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${BASE_API_URL}/looking-for/void-inquiry/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      const data = await response.json();
      const { statusCode, message } = data;

      if (response.ok) {
        // Filter out the deleted inquiry's ID directly from the current state
        const updatedInquiries = inquiries.filter(
          (inquiry) => inquiry.id !== postId
        );
        setInquiries(updatedInquiries);

        toast.success("Inquiry deleted successfully.");
        handleCloseShowDelete();
      } else {
        toast.error(`${statusCode} : ${message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthProvider>
      <div className="body">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <CustomNavbar />

            <Container
              style={{
                backgroundColor: "#f7f7f7",
                padding: "30px 0",
                borderBottom: "1px solid #ddd",
              }}
              fluid
            >
              <Container style={{ color: "#6c757d", fontSize: "0.9rem" }}>
                <span className="fw-light">Home</span>{" "}
                <span className="px-1">|</span>{" "}
                <span className="fw-light">Inquiries</span>
              </Container>
            </Container>

            <div className="my-4"></div>

            <Container
              style={{
                backgroundColor: "#f9f9f9",
                padding: "20px 30px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="text-dark fw-bold mb-0">My inquiries</h5>
                <Button
                  variant="outline-danger"
                  size="sm"
                  className="shadow-sm"
                  onClick={handleOpenModal}
                >
                  ➕ Make an Inquiry
                </Button>
              </div>
              <h6 className="text-danger fw-bold mb-3">
                Shows last 20 inquries only
              </h6>
              <p
                className="text-muted mb-4"
                style={{ fontSize: "0.95rem", lineHeight: "1.5" }}
              >
                Keep track of the inquiries you’ve made in our marketplace.
                Whether it’s about bikes, gear, or parts, stay connected with
                sellers and find the best deals!
              </p>
            </Container>

            <Container className="margin-top">
              <div className="shadow-lg p-4 mb-5 bg-white rounded">
                <Row>
                  {inquiries.length > 0 ? (
                    <Col md={12}>
                      <Row>
                        {inquiries.map((data, index) => (
                          <Col md={12} key={index}>
                            <div className="mb-4">
                              <div className="card shadow-sm border-1 rounded">
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h5 className="text-primary">
                                      <b className="text-dark">
                                        Category:{" "}
                                        {categories.find(
                                          (cat) =>
                                            cat.id === Number(data.requestTitle)
                                        )?.itemsOnSale || "Unknown Category"}
                                      </b>
                                    </h5>
                                    <small className="text-muted">
                                      <TimeDisplay
                                        timeString={data.createdAt}
                                      />
                                    </small>
                                  </div>

                                  <p
                                    className="text-muted"
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    {data.requestBody ||
                                      "No details provided for this inquiry."}
                                  </p>
                                  <div className="spacing"></div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span className="text-muted d-flex align-items-center gap-2">
                                      <Button
                                        size="sm"
                                        variant="light"
                                        className="d-flex align-items-center"
                                      >
                                        <Tag
                                          className="me-1"
                                          style={{ fontSize: "1.2rem" }}
                                        />
                                        ID: {data.id}
                                      </Button>
                                      <Button
                                        size="sm"
                                        variant="light"
                                        className="d-flex align-items-center"
                                      >
                                        <Eye
                                          className="me-1"
                                          style={{ fontSize: "1.2rem" }}
                                        />
                                        {data.views} views
                                      </Button>
                                    </span>

                                    <div className="d-flex">
                                      <div className="d-flex align-items-center">
                                        {/* Button for closing the inquiry */}
                                        {data.requestStatus ? (
                                          <Button
                                            size="sm"
                                            variant="success"
                                            className="d-flex align-items-center me-2"
                                          >
                                            <Lock className="me-2" />
                                            Closed
                                          </Button>
                                        ) : (
                                          // Button for opening the inquiry
                                          <Button
                                            size="sm"
                                            variant="outline-primary"
                                            className="d-flex align-items-center me-2"
                                            onClick={() =>
                                              handleShowCloseInquiry(data.id)
                                            }
                                          >
                                            <Unlock className="me-2" />
                                            Open
                                          </Button>
                                        )}
                                      </div>
                                      <Button
                                        size="sm"
                                        variant="outline-danger"
                                        className="d-flex align-items-center"
                                        onClick={() =>
                                          handleShowDelete(data.id)
                                        }
                                      >
                                        <Trash className="me-1" /> Delete
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  ) : (
                    <Col md={12}>
                      <div className="text-center py-5">
                        <img
                          src={NoData}
                          style={{ borderRadius: "10px" }}
                          alt="No inquiries available"
                          className="img-fluid mb-4"
                        />
                        <p className="text-muted font-italic">
                          You haven't made any inquiries yet. Start exploring
                          the best deals on sport bikes, gear, and parts today!
                        </p>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="shadow-sm"
                          onClick={handleOpenModal}
                        >
                          ➕ Make Your First Inquiry
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal} centered>
              <Modal.Header closeButton>
                <h5 className="text-danger" style={{ fontWeight: "bold" }}>
                  Inquiry Details
                </h5>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label>Item Category</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      value={requestTitle}
                      onChange={(e) => setRequestTitle(e.target.value)}
                    >
                      <option value=""> </option>
                      {categories.map((category, index) => (
                        <option key={index} value={category.id}>
                          {category.itemsOnSale}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Your contacts</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={userContacts}
                      onChange={(e) => setUserContacts(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="otherBikeDetails" className="mb-3">
                    <Form.Label>Item Details</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={10}
                      value={requestBody}
                      onChange={(e) => setRequestBody(e.target.value)}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={handleCloseModal}
                      disabled={isSubmitting}
                    >
                      Close inquiry
                    </Button>
                    <Button
                      size="sm"
                      variant="primary"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting
                        ? "Submitting inquiry ..."
                        : "Submit inquiry"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>

            <Modal
              show={showCloseInquiry}
              onHide={handleCloseShowCloseInquiry}
              centered
            >
              <Modal.Header closeButton className="bg-light">
                <h6 className="text-danger">Close Inquiry</h6>
              </Modal.Header>
              <Modal.Body>
                <p className="text-muted">
                  Are you sure you want to close this inquiry? Please provide a
                  reason for closure to help us improve our services.
                </p>
                <div className="spacing"></div>
                <Form onSubmit={handleClose}>
                  <Form.Group className="mb-4">
                    <Form.Label>Reason for Closure</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      value={reasonForClosure}
                      onChange={(e) => setReasonForClosure(e.target.value)}
                    >
                      <option value="" disabled></option>
                      <option value="No longer interested">
                        No longer interested
                      </option>
                      <option value="I got assisted here">
                        I got assisted here
                      </option>
                      <option value="I got conned on this inquiry">
                        I got conned on this inquiry
                      </option>
                      <option value="I didn't get the feedback i wanted">
                        I didn't get the feedback i wanted
                      </option>
                      <option value="I got assisted elsewhere">
                        I got assisted elsewhere
                      </option>
                      <option value="I prefer not to give feedback">
                        I prefer not to give feedback
                      </option>
                      <option value="I found better options elsewhere">
                        I found better options elsewhere
                      </option>
                      <option value="The process was too complicated">
                        The process was too complicated
                      </option>
                      <option value="I didn't receive a response">
                        I didn't receive a response
                      </option>
                      <option value="I was happy with the service">
                        I was happy with the service
                      </option>
                      <option value="I had issues with the website">
                        I had issues with the website
                      </option>
                      <option value="Other (please specify)">
                        Other (please specify)
                      </option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Additional Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={handleCloseShowCloseInquiry}
                      disabled={isSubmitting}
                    >
                      Cancel closure
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting ? "Closing Inquiry ..." : "Close Inquiry"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>

            <Modal show={showDelete} onHide={handleCloseShowDelete} centered>
              <Modal.Header closeButton className="bg-light">
                <h6 className="text-danger">Close Inquiry</h6>
              </Modal.Header>
              <Modal.Body>
                <p className="text-muted">
                  Are you sure you want to delete this inquiry? This action is
                  irreversible.
                </p>
                <div className="spacing"></div>
                <Form onSubmit={handleDelete}>
                  <Modal.Footer>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={handleCloseShowCloseInquiry}
                      disabled={isSubmitting}
                    >
                      Cancel deletion
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting ? "Deleting Inquiry ..." : "Delete Inquiry"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Footer />
          </>
        )}
      </div>
    </AuthProvider>
  );
};

export default LookingFor;
