import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

interface DetailsProps {
  userId: number;
}

interface UserDetails {
  businessName: string;
}

const FetchUserDetails: React.FC<DetailsProps> = ({ userId }) => {
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/admin-overview/get-user-details/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.ok) {
          const data: UserDetails = await response.json();
          setUserDetails(data);
        }
      } catch (error) {
        toast.error(`Error fetching details: ${error}`);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <div>
      {userDetails ? (
        <span>{userDetails.businessName}</span>
      ) : (
        <Spinner animation="border" size="sm" />
      )}
    </div>
  );
};

export default FetchUserDetails;
