import React, { useEffect, useState } from "react";
import { Container, Button, Col, Row, Card } from "react-bootstrap";
import Footer from "../headerpages/footer";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate } from "react-router-dom";
import formatNumberWithCommas from "../components/numberFormat";
import PageLoader from "../components/pageLoader";
import CustomNavbar from "../headerpages/header";
import { Eye } from "react-bootstrap-icons";
import SportBike from "../images/sport.jpg";
import AdvertisementsPosted from "../components/fetchAdvertisements";

// Define the interface for the product data
interface ProductsData {
  id: number;
  createdAt: string;
  productName: string;
  priceOfItem: number;
  imageUrl: string | null;
  availability: boolean;
  negotiable: boolean;
  itemCategory: number;
}

const HomePage: React.FC = () => {
  const [items, setItems] = useState<ProductsData[]>([]);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setloading(true);
      try {
        const baseUrl = `${BASE_API_URL}`;
        const methodGet = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        const response = await fetch(
          `${baseUrl}/customer-side/get-bikes-for-customer-home-page`,
          methodGet
        );
        const bikesOnSaleItems = await response.json();

        if (response.ok) {
          setItems(bikesOnSaleItems);
        } else {
          const { statusCode, message } = bikesOnSaleItems;
          toast.error(`${statusCode} - ${message}`);
        }
      } catch (error) {
        toast.error(`Error: ${error}`);
      } finally {
        setloading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleViewClick = (id: number) => {
    navigate(`/item-details/${id}`);
  };

  return (
    <>
      <div className="body">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <CustomNavbar />
            <br />
            {/* Hero Section */}
            <Container>
              <div style={{ borderRadius: "10px" }}>
                <Row
                  className="overlay px-4 py-5 align-items-center py-5"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                    borderRadius: "8px",
                  }}
                >
                  <Col md={6} className="mb-4 mb-md-0">
                    <div>
                      <h5
                        className="hero-title"
                        style={{
                          fontSize: "1.75rem",
                          fontWeight: "bold",
                          marginBottom: "1rem",
                        }}
                      >
                        Welcome to AMD.bike
                      </h5>
                      <p
                        className="hero-subtitle"
                        style={{
                          fontSize: "1rem",
                          lineHeight: "1.6",
                        }}
                      >
                        <b>African Motorcycle Diaries (AMD)</b> is Kenya's
                        largest and most vibrant online motorbike community,
                        where adventure stories, thrilling experiences, and fun
                        come to life!
                        <br />
                        <br />
                        Now, we're excited to introduce our comprehensive
                        marketplace, connecting bikers, buyers, and sellers on
                        one seamless platform. <b>AMD.Bike</b> is your ultimate
                        destination for all things bike-related.
                        <br />
                        <br />
                        To have your post featured in our{" "}
                        <a
                          href="https://www.facebook.com/groups/amd.bike"
                          style={{
                            fontWeight: "bold",
                            textDecoration: "none",
                            color: "#ea1d20",
                          }}
                        >
                          Facebook group
                        </a>{" "}
                        any day of the week, advertise on our site. Join us and
                        experience the best of the biking world in one place!
                      </p>
                    </div>
                  </Col>
                  <Col
                    md={6}
                    className="d-flex flex-wrap gap-3 justify-content-center"
                  >
                    <img
                      src={SportBike}
                      className="img-fluid rounded shadow"
                      alt="Sport Bike"
                      style={{
                        maxHeight: "400px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Container>

            <div className="spacing"></div>

            <Container className="featured-section py-5">
              <h5 className="text-center section-title">
                Sport Bikes listed for sale
              </h5>
              <p className="text-center section-subtitle">
                Browse through our top-rated sport bikes from our verified
                sellers.
              </p>
              <Row>
                {items
                  .filter((item) => item.itemCategory === 1)
                  .map((item) => (
                    <Col key={item.id} md={3} sm={6} xs={6} className="mb-4">
                      <Card
                        className="product-card p-3"
                        style={{ borderRadius: "10px" }}
                      >
                        {/* Image Section */}

                        <div className="image-wrapper position-relative">
                          {item.imageUrl ? (
                            <img
                              src={item.imageUrl}
                              alt={item.productName}
                              style={{ borderRadius: "10px" }}
                              className="img-fluid custom-hover-img custom-hover-col"
                            />
                          ) : (
                            <div className="image-placeholder"></div>
                          )}
                        </div>
                        {/* Product Name */}
                        <br />
                        <h6 className="fw-bold">{item.productName}</h6>
                        {/* Price Section */}
                        <div className="mb-2">
                          <p className="product-price text-danger mb-1">
                            Ksh {formatNumberWithCommas(item.priceOfItem)}
                          </p>
                        </div>
                        {/* Product Details Section */}
                        <p className="text-muted small">
                          <span>
                            {item.negotiable === true ? "Fixed" : "Negotiable"}{" "}
                            price
                          </span>
                        </p>
                        {/* Button Section */}
                        <div className="">
                          <Button
                            onClick={() => handleViewClick(item.id)}
                            variant="danger"
                            size="sm"
                          >
                            Item details <Eye className="ms-2" />
                          </Button>
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Container>

            <Container className="my-5">
              <Row className="align-items-center text-center bg-light rounded py-5">
                <Col>
                  <AdvertisementsPosted />
                </Col>
              </Row>
            </Container>

            <Container className="featured-section py-5">
              <h5 className="text-center section-title">
                Riding gear listed for sale
              </h5>
              <p className="text-center section-subtitle">
                Browse through our top-rated riding gears from our verified
                sellers.
              </p>
              <Row>
                {items
                  .filter((item) => item.itemCategory === 2)
                  .map((item) => (
                    <Col key={item.id} md={3} sm={6} xs={6} className="mb-4">
                      <Card
                        className="product-card p-3"
                        style={{ borderRadius: "10px" }}
                      >
                        {/* Image Section */}

                        <div className="image-wrapper position-relative">
                          {item.imageUrl ? (
                            <img
                              src={item.imageUrl}
                              alt={item.productName}
                              style={{ borderRadius: "10px" }}
                              className="img-fluid custom-hover-img custom-hover-col"
                            />
                          ) : (
                            <div className="image-placeholder"></div>
                          )}
                        </div>
                        {/* Product Name */}
                        <br />
                        <h6 className="fw-bold">{item.productName}</h6>
                        {/* Price Section */}
                        <div className="mb-2">
                          <p className="product-price text-danger mb-1">
                            Ksh {formatNumberWithCommas(item.priceOfItem)}
                          </p>
                        </div>
                        {/* Product Details Section */}
                        <p className="text-muted small">
                          <span>
                            {item.negotiable === true ? "Fixed" : "Negotiable"}{" "}
                            price
                          </span>
                        </p>
                        {/* Button Section */}
                        <div className="">
                          <Button
                            onClick={() => handleViewClick(item.id)}
                            variant="danger"
                            size="sm"
                          >
                            Item details <Eye className="ms-2" />
                          </Button>
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Container>

            <Container className="my-5">
              <Row className="align-items-center text-center bg-light rounded py-5">
                <Col>
                  <AdvertisementsPosted />
                </Col>
              </Row>
            </Container>

            <Container className="featured-section py-5">
              <h5 className="text-center section-title">
                Sport Bikes parts listed for sale
              </h5>
              <p className="text-center section-subtitle">
                Discover discounted parts offered by our registered vendors
                below. Take advantage of these great deals!
              </p>
              <Row>
                {items
                  .filter((item) => item.itemCategory === 3)
                  .map((item) => (
                    <Col key={item.id} md={3} sm={6} xs={6} className="mb-4">
                      <Card
                        className="product-card p-3"
                        style={{ borderRadius: "10px" }}
                      >
                        {/* Image Section */}

                        <div className="image-wrapper position-relative">
                          {item.imageUrl ? (
                            <img
                              src={item.imageUrl}
                              alt={item.productName}
                              style={{ borderRadius: "10px" }}
                              className="img-fluid custom-hover-img custom-hover-col"
                            />
                          ) : (
                            <div className="image-placeholder"></div>
                          )}
                        </div>
                        {/* Product Name */}
                        <br />
                        <h6 className="fw-bold">{item.productName}</h6>
                        {/* Price Section */}
                        <div className="mb-2">
                          <p className="product-price text-danger mb-1">
                            Ksh {formatNumberWithCommas(item.priceOfItem)}
                          </p>
                        </div>
                        {/* Product Details Section */}
                        <p className="text-muted small">
                          <span>
                            {item.negotiable === true ? "Fixed" : "Negotiable"}{" "}
                            price
                          </span>
                        </p>
                        {/* Button Section */}
                        <div className="">
                          <Button
                            onClick={() => handleViewClick(item.id)}
                            variant="danger"
                            size="sm"
                          >
                            Item details <Eye className="ms-2" />
                          </Button>
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Container>

            <Container className="my-5">
              <Row className="align-items-center text-center bg-light rounded py-5">
                <Col>
                  <AdvertisementsPosted />
                </Col>
              </Row>
            </Container>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default HomePage;
