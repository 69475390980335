import { FormEvent, useState } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import Footer from "../headerpages/footer";
import NavigationPage from "../components/navigationPages";
import CustomNavbar from "../headerpages/header";

const ForgotPassword = () => {
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const userData = { registeredEmail };
      const response = await fetch(
        `${BASE_API_URL}/auth/password-reset-token`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      const data = await response?.json();
      const { statusCode, message } = data;

      if (response.ok) {
        toast.success("Email sent");
        setRegisteredEmail("");
      } else {
        const errorMessage = `${statusCode} -  ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <CustomNavbar />

      <Container fluid style={{ backgroundColor: "#F5F0F0", padding: "40px" }}>
        <Container>
          <div style={{ textAlign: "center" }}>
            <h5 className="text-danger" style={{ fontWeight: "bold" }}>
              To reset your password, input your registered email
            </h5>
          </div>
        </Container>
      </Container>

      <Container className="py-5">
        <Row>
          <Col md={3} className="registration-info"></Col>
          <Col md={6} className="registration-form">
            <div className="registration-form-inner">
              <br />
              <Form onSubmit={handleSignIn}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Registered email </Form.Label>
                  <Form.Control
                    type="text"
                    value={registeredEmail}
                    onChange={(e) => setRegisteredEmail(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  disabled={submitting}
                  type="submit"
                  className="w-100"
                >
                  {submitting === true ? "Sending email" : "Send request"}
                </Button>
              </Form>
              <br />
              <br />
              <br />
              <NavigationPage />
            </div>
          </Col>
          <Col md={3} className="registration-info"></Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ForgotPassword;
