import { FormEvent, useState } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import Footer from "../headerpages/footer";
import NavigationPage from "../components/navigationPages";
import CustomNavbar from "../headerpages/header";

const RegistrationPage = () => {
  const [userEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const userData = { fullName, userEmail, password, confirmPassword };
      const response = await fetch(`${BASE_API_URL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();
      const { statusCode, message } = data;

      if (response.ok) {
        toast.success(`Registration successful`);
        toast.success(`Verification email sent`);
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setFullName("");
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("Failed, please try again");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <CustomNavbar />
      <Container fluid style={{ padding: "40px" }}>
        <Container>
          <div style={{ textAlign: "center" }}>
            <h5 style={{ fontWeight: "bold" }} className="text-danger">
              Register to join our team
            </h5>
          </div>
        </Container>
      </Container>

      <Container
        fluid
        style={{
          backgroundColor: "#F5F0F0",
          borderRadius: "10px",
          padding: "30px",
        }}
      >
        <Container style={{ padding: "10px" }}>
          <div className="spacing"></div>
          <br />
          <div className="buyer-info">
            <h6 style={{ fontWeight: "bold" }}>Why Register?</h6>
            <br />
            <b className="text-danger">Buyers</b>
            <p>
              Welcome to our online marketplace dedicated to sport bikes, riding
              gear, and new / used parts. Whether you're searching for the
              latest sport bike models, riding gear, or essential parts for
              maintenance, our platform offers a wide range of options to
              enhance your riding experience.
              <br />
              <br />
              Explore our diverse selection from verified vendors who offer
              top-quality products tailored to your needs. Whether you're a
              seasoned rider looking to upgrade or a newcomer seeking reliable
              equipment, our marketplace ensures you'll find what you're looking
              for.
              <br />
              <br />
              Benefit from our user-friendly interface that simplifies browsing,
              purchasing, and tracking orders. We prioritize on vendor
              reliability and honesty making it a safe and reliable shopping
              platform
            </p>
          </div>
          <br />
          <div className="seller-info">
            <b className="text-danger">Sellers</b>
            <p>
              Selling on our platform is completely free. List your products
              effortlessly, whether it's bikes, riding gear, or parts, and gain
              visibility among a diverse community of enthusiasts.
              <br />
              <br />
              Our platform empowers sellers with advanced analytics to track
              product views and optimize listings for maximum exposure. Take
              advantage of our growing traffic and reach a large audience of
              potential buyers interested in sport bikes and related gear.
              <br />
              <br />
              Enjoy continuous platform enhancements and dedicated support to
              help you manage your listings effectively. We are committed to
              improving our services based on seller feedback, ensuring a
              seamless experience for every user.
            </p>
          </div>
        </Container>
      </Container>

      <div className="spacing"></div>

      <br />
      <Container
        style={{
          padding: "30px",
        }}
      >
        <div className="registration-form-inner">
          <h6 style={{ fontWeight: "bold" }}>Create an Account</h6>
          <br />
          <Form onSubmit={handleRegister}>
            <Form.Group controlId="formFullName" className="mb-4">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-4">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                value={userEmail}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword" className="mb-4">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formConfirmPassword" className="mb-4">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={submitting}
              className="w-100"
            >
              {submitting === false ? "Submit" : "Registering"}
            </Button>
          </Form>
        </div>
        <br />
        <br />
        <br />
        <NavigationPage />
      </Container>
      <div className="spacing"></div>
      <div className="spacing"></div>
      <div className="spacing"></div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default RegistrationPage;
