import React, { useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Popover,
} from "react-bootstrap";
import CustomNavbar from "../headerpages/header";
import AuthProvider from "../auth/authProvider";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import PageLoader from "../components/pageLoader";
import NoData from "../images/noData.png";
import TimeDisplay from "../components/timeFormat";
import { Eye, Tag } from "react-bootstrap-icons";

interface Category {
  id: number;
  itemsOnSale: string;
}

interface Inquries {
  id: number;
  createdAt: string;
  views: number;
  requestTitle: string;
  voidInquiry: boolean;
  requestStatus: boolean;
  userContacts: string;
  requestBody: string;
  userId: number;
}

interface Users {
  id: number;
  fullName: string;
  userEmail: string;
}

const ClientInquiries: React.FC = () => {
  const [categories, setcategories] = useState<Category[]>([]);
  const [inquiries, setInquiries] = useState<Inquries[]>([]);
  const [users, setUsers] = useState<Users[]>([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const fetchMyInquiries = async () => {
      setloading(true);
      try {
        const response = await fetch(
          `${BASE_API_URL}/looking-for-responses/get-client-inquiries`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response?.ok) {
          const data = await response?.json();
          setInquiries(data.inquiries);
          setcategories(data.categories);
          setUsers(data.users);
        }
      } catch (error) {
        toast.error(`Error: ${error}`);
      } finally {
        setloading(false);
      }
    };

    fetchMyInquiries();
  }, []);

  const handleView = async (postId: number) => {
    try {
      const response = await fetch(
        `${BASE_API_URL}/looking-for-responses/looking-for-view-count/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    }
  };

  return (
    <AuthProvider>
      <div className="body">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <CustomNavbar />

            <Container
              style={{
                backgroundColor: "#f7f7f7",
                padding: "30px 0",
                borderBottom: "1px solid #ddd",
              }}
              fluid
            >
              <Container style={{ color: "#6c757d", fontSize: "0.9rem" }}>
                <span className="fw-light">Home</span>{" "}
                <span className="px-1">|</span>{" "}
                <span className="fw-light">Client inquiries</span>
              </Container>
            </Container>

            <div className="my-4"></div>

            <Container
              style={{
                backgroundColor: "#f9f9f9",
                padding: "20px 30px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="text-dark fw-bold mb-0">Client inquiries</h5>
              </div>
              <p
                className="text-muted mb-4"
                style={{ fontSize: "0.95rem", lineHeight: "1.5" }}
              >
                Keep track of the client inquiries made in our marketplace.
                Whether it’s about bikes, gear, or parts, stay connected with
                clients and increase your sales!
              </p>
            </Container>

            <Container className="margin-top">
              <div className="shadow-lg p-4 mb-5 bg-white rounded">
                <Row>
                  {inquiries.length > 0 ? (
                    <Col md={12}>
                      <Row>
                        {inquiries.map((data, index) => (
                          <Col md={12} key={index}>
                            <div className="mb-4">
                              <div className="card shadow-sm border-1 rounded">
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h5 className="text-primary">
                                      <b className="text-dark">
                                        Category:{" "}
                                        {categories.find(
                                          (cat) =>
                                            cat.id === Number(data.requestTitle)
                                        )?.itemsOnSale || "Unknown Category"}
                                      </b>
                                    </h5>
                                    <small className="text-muted">
                                      <TimeDisplay
                                        timeString={data.createdAt}
                                      />
                                    </small>
                                  </div>

                                  <p
                                    className="text-muted"
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    Posted by :{" "}
                                    {(
                                      users.find(
                                        (owner) =>
                                          owner.id === Number(data.userId)
                                      )?.fullName || "Unknown"
                                    ).toLowerCase()}
                                    <br />
                                    User email :{" "}
                                    {(
                                      users.find(
                                        (owner) =>
                                          owner.id === Number(data.userId)
                                      )?.userEmail || "Unknown"
                                    ).toLowerCase()}
                                    <br />
                                    Contacts:{" "}
                                    {data.userContacts || "Unavailable"}
                                  </p>
                                  <div className="spacing"></div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span className="text-muted d-flex align-items-center gap-2">
                                      <Button
                                        size="sm"
                                        variant="light"
                                        className="d-flex align-items-center"
                                      >
                                        <Tag
                                          className="me-1"
                                          style={{ fontSize: "1.2rem" }}
                                        />
                                        ID: {data.id}
                                      </Button>
                                      <Button
                                        size="sm"
                                        variant="light"
                                        className="d-flex align-items-center"
                                      >
                                        <Eye
                                          className="me-1"
                                          style={{ fontSize: "1.2rem" }}
                                        />
                                        {data.views} views
                                      </Button>
                                    </span>

                                    <div className="d-flex">
                                      <div className="d-flex align-items-center">
                                        <div className="d-flex">
                                          <div className="d-flex align-items-center">
                                            <OverlayTrigger
                                              trigger="click"
                                              placement="top"
                                              overlay={
                                                <Popover
                                                  id={`popover-${data.id}`}
                                                  className="custom-popover"
                                                >
                                                  <Popover.Header as="h3">
                                                    Inquiry Details
                                                  </Popover.Header>
                                                  <Popover.Body>
                                                    <p className="mb-1">
                                                      <strong>Title:</strong>{" "}
                                                      <span className="text-secondary">
                                                        {categories.find(
                                                          (cat) =>
                                                            cat.id ===
                                                            Number(
                                                              data.requestTitle
                                                            )
                                                        )?.itemsOnSale ||
                                                          "Unknown Category"}
                                                      </span>
                                                    </p>
                                                    <p className="mb-1">
                                                      <strong>
                                                        Description:
                                                      </strong>{" "}
                                                      <span className="text-secondary">
                                                        {data.requestBody}
                                                      </span>
                                                    </p>
                                                    <p className="mb-1">
                                                      <strong>Status:</strong>{" "}
                                                      <span
                                                        className={`badge ${
                                                          data.requestStatus
                                                            ? "bg-success"
                                                            : "bg-warning"
                                                        }`}
                                                      >
                                                        {data.requestStatus
                                                          ? "Closed"
                                                          : "Open"}
                                                      </span>
                                                    </p>
                                                    <p className="mb-0">
                                                      <strong>
                                                        Date Created:
                                                      </strong>{" "}
                                                      <span className="text-secondary">
                                                        <TimeDisplay
                                                          timeString={
                                                            data.createdAt
                                                          }
                                                        />
                                                      </span>
                                                    </p>
                                                  </Popover.Body>
                                                </Popover>
                                              }
                                              rootClose
                                            >
                                              <Button
                                                size="sm"
                                                variant="outline-danger"
                                                className="d-flex align-items-center me-2"
                                                onClick={() =>
                                                  handleView(data.id)
                                                }
                                              >
                                                <Eye className="me-2" />
                                                Click to view inquiry
                                              </Button>
                                            </OverlayTrigger>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  ) : (
                    <Col md={12}>
                      <div className="text-center py-5">
                        <img
                          src={NoData}
                          style={{ borderRadius: "10px" }}
                          alt="No inquiries available"
                          className="img-fluid mb-4"
                        />
                        <p className="text-muted font-italic">
                          There are no available customer inquiries found on our
                          database
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Container>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Footer />
          </>
        )}
      </div>
    </AuthProvider>
  );
};

export default ClientInquiries;
