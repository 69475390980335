// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 8px 12px;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 15px;
  flex: 1 1;
  margin-right: 8px;
}

.search-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #f76d2b;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/searchBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,SAAO;EACP,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".search-bar {\n  display: flex;\n  align-items: center;\n}\n\n.search-input {\n  padding: 8px 12px;\n  border: 2px solid #ccc;\n  border-radius: 10px;\n  font-size: 15px;\n  flex: 1;\n  margin-right: 8px;\n}\n\n.search-button {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  padding: 8px 16px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.search-button:hover {\n  background-color: #f76d2b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
