import React, { useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import { Button, Card, Col, Container, Pagination, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate } from "react-router-dom";
import formatNumberWithCommas from "../components/numberFormat";
import { Eye } from "react-bootstrap-icons";
import CustomNavbar from "../headerpages/header";
import NoData from "../images/noData.png";
import PageLoader from "../components/pageLoader";
import AdvertisementsPosted from "../components/fetchAdvertisements";

interface ProductsData {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
  imageUrl: string | null;
}

const PartsOnSale: React.FC = () => {
  const [parts, setParts] = useState<ProductsData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50); // Set items per page here
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      setloading(true);
      try {
        const baseUrl = `${BASE_API_URL}`;
        const methodGet = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        // Get parts on sale
        const bikesOnSaleResponse = await fetch(
          `${baseUrl}/customer-side/get-all-parts-for-customer`,
          methodGet
        );
        const bikesOnSaleItems = await bikesOnSaleResponse.json();

        if (bikesOnSaleResponse.ok) {
          setParts(bikesOnSaleItems);
        } else {
          const { statusCode, message } = bikesOnSaleItems;
          const errorMessage = `${statusCode} - ${message}`;
          toast.error(errorMessage);
        }
      } catch (error) {
        toast.error(`${error}`);
      } finally {
        setloading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleViewClick = (id: number) => {
    navigate(`/item-details/${id}`);
  };

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = parts.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="body">
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <CustomNavbar />

          <Container
            style={{ backgroundColor: "#F5F0F0", padding: "40px" }}
            fluid
          >
            <Container style={{ color: "GrayText" }}>
              Home | Bike parts on sale
            </Container>
          </Container>

          {currentItems.length > 0 ? (
            <>
              <Container className="py-4">
                <h5 style={{ fontWeight: "bold" }}>Parts on Sale</h5>
                <p>
                  Discover discounted parts offered by our registered vendors
                  below. Take advantage of these great deals!
                </p>
                <div className="spacing"></div>
                <Row>
                  {currentItems.map((item, index) => (
                    <Col key={item.id} md={3} sm={4} xs={6} className="mb-4">
                      <Card
                        className="product-card p-3"
                        style={{ borderRadius: "10px" }}
                      >
                        {/* Image Section */}

                        <div className="image-wrapper position-relative">
                          {item.imageUrl ? (
                            <img
                              src={item.imageUrl}
                              alt={item.productName}
                              style={{ borderRadius: "10px" }}
                              className="img-fluid custom-hover-img custom-hover-col"
                            />
                          ) : (
                            <div className="image-placeholder"></div>
                          )}
                        </div>
                        {/* Product Name */}
                        <br />
                        <h6 className="fw-bold">{item.productName}</h6>
                        {/* Price Section */}
                        <div className="mb-2">
                          <p className="product-price text-danger mb-1">
                            Ksh {formatNumberWithCommas(item.priceOfItem)}
                          </p>
                        </div>
                        {/* Product Details Section */}
                        <p className="text-muted small">
                          <span>
                            {item.negotiable === true ? "Fixed" : "Negotiable"}{" "}
                            price
                          </span>
                        </p>
                        {/* Button Section */}
                        <div className="">
                          <Button
                            onClick={() => handleViewClick(item.id)}
                            variant="danger"
                            size="sm"
                          >
                            Item details <Eye className="ms-2" />
                          </Button>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <div className="spacing"></div>

                <Pagination>
                  <Pagination.First onClick={() => paginate(1)} />
                  <Pagination.Prev
                    onClick={() =>
                      paginate(currentPage === 1 ? 1 : currentPage - 1)
                    }
                  />
                  {[...Array(Math.ceil(parts.length / itemsPerPage))].map(
                    (_, i) => (
                      <Pagination.Item
                        key={i}
                        active={i + 1 === currentPage}
                        onClick={() => paginate(i + 1)}
                      >
                        {i + 1}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={() =>
                      paginate(
                        currentPage === Math.ceil(parts.length / itemsPerPage)
                          ? currentPage
                          : currentPage + 1
                      )
                    }
                  />
                  <Pagination.Last
                    onClick={() =>
                      paginate(Math.ceil(parts.length / itemsPerPage))
                    }
                  />
                </Pagination>
              </Container>
            </>
          ) : (
            <>
              <Container
                style={{
                  marginTop: "100px",
                  marginBottom: "100px",
                  textAlign: "center",
                }}
              >
                <img
                  src={NoData}
                  style={{ borderRadius: "10px" }}
                  alt="Product Images"
                  className="img-fluid custom-hover-img custom-hover-col"
                />
                <h5>No bike parts on sale found</h5>
              </Container>
            </>
          )}

          <Container className="my-5">
            <Row className="align-items-center text-center bg-light rounded py-5">
              <Col>
                <AdvertisementsPosted />
              </Col>
            </Row>
          </Container>

          <Footer />
        </>
      )}
    </div>
  );
};

export default PartsOnSale;
