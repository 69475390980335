import React, { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import CustomNavbar from "../headerpages/header";
import AuthProvider from "../auth/authProvider";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import PageLoader from "../components/pageLoader";
import NoData from "../images/noData.png";
import { FaEdit, FaPowerOff, FaTrashAlt } from "react-icons/fa";

interface postedAdverts {
  id: number;
  createdAt: string;
  postTitle: string;
  postBody: string;
  contacts: string;
  images: { imageUrl: string }[];
  createdById: number;
  activeStatus: boolean;
}

interface user {
  id: number;
  fullName: string;
}

const Advertisements: React.FC = () => {
  const [adverts, setAdverts] = useState<postedAdverts[]>([]);
  const [user, setUsers] = useState<user[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [postId, setSelectedAdvert] = useState(0);
  const [postTitle, setPostTitle] = useState("");
  const [postBody, setPostBody] = useState("");
  const [contacts, setContacts] = useState("254");
  const [files, setFiles] = useState<FileList | null>(null);
  const [loading, setloading] = useState(false);
  const [reasonForDeactivation, setReasonForDeactivation] = useState("");
  const [reasonForActivation, setReasonForActivation] = useState("");

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setPostBody("");
    setPostTitle("");
    setContacts("254");
    setFiles(null);
    setShowModal(false);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleShowDelete = (id: number) => {
    setSelectedAdvert(id);
    setShowDelete(true);
  };
  const handleCloseShowDelete = () => {
    setShowDelete(false);
  };

  const [showEdit, setShowEdit] = useState(false);
  const handleEdit = (id: number) => {
    setSelectedAdvert(id);
    setShowEdit(true);
  };
  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const [deactivate, setDeactivate] = useState(false);
  const handleDeactivateAdd = (id: number) => {
    setSelectedAdvert(id);
    setDeactivate(true);
  };
  const handleCloseDeactivate = () => {
    setDeactivate(false);
    setReasonForDeactivation("");
  };

  const [activate, setActivate] = useState(false);
  const handleActivateAdd = (id: number) => {
    setSelectedAdvert(id);
    setActivate(true);
  };
  const handleCloseActivate = () => {
    setActivate(false);
    setReasonForActivation("");
    setReasonForActivation("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formData = new FormData();

      // Check if files were selected
      if (!files || files.length === 0) {
        console.error("No files selected.");
        setIsSubmitting(false);
        return;
      }

      // Append files to formData
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });

      // Append other form fields to FormData
      formData.append("postTitle", postTitle);
      formData.append("postBody", postBody);
      formData.append("contacts", contacts);

      // Send request
      const response = await fetch(
        `${BASE_API_URL}/marketing/create-advertisement`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: formData,
        }
      );

      const data = await response.json();
      const { statusCode, message } = data;

      if (response.ok) {
        toast.success("Advertisement created successfully");
        setAdverts((adv) => [data, ...adv]);
        handleCloseModal();
      } else {
        toast.error(`${statusCode} : ${message}`);
      }
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditData = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (!editedPostTitle || !editedPostBody || !editedContacts) {
        toast.error("Please fill in all fields");
        setIsSubmitting(false);
        return;
      }

      const formData = new FormData();

      // Check if files were selected and validate types
      if (files && files.length > 0) {
        Array.from(files).forEach((file) => {
          if (["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
            formData.append("files", file);
          } else {
            toast.error("Only PNG, JPEG, and JPG files are allowed");
            setIsSubmitting(false);
            return;
          }
        });
      }

      // Append other form fields to FormData
      formData.append("postTitle", editedPostTitle);
      formData.append("postBody", editedPostBody);
      formData.append("contacts", editedContacts);

      // Send request
      const response = await fetch(
        `${BASE_API_URL}/marketing/edit-advertisement/${postId}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: formData,
        }
      );

      const data = await response.json();
      const { statusCode, message, adds } = data;

      if (response.ok) {
        toast.success("Advertisement edited successfully");
        setAdverts(adds);
        handleCloseEdit();
      } else {
        toast.error(`${statusCode} : ${message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchMyInquiries = async () => {
      setloading(true);
      try {
        const response = await fetch(
          `${BASE_API_URL}/marketing/get-advertisements-posted-by-admin`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response?.ok) {
          const data = await response?.json();
          setAdverts(data.adds);
          setUsers(data.user);
        }
      } catch (error) {
        toast.error(`Error: ${error}`);
      } finally {
        setloading(false);
      }
    };

    fetchMyInquiries();
  }, []);

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${BASE_API_URL}/marketing/void-advertisement/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      const data = await response.json();
      const { statusCode, message } = data;

      if (response.ok) {
        const updatedData = adverts.filter((inquiry) => inquiry.id !== postId);
        setAdverts(updatedData);

        toast.success("Advertisement deleted successfully.");
        handleCloseShowDelete();
      } else {
        toast.error(`${statusCode} : ${message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeactivate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const content = { reasonForDeactivation };
      const response = await fetch(
        `${BASE_API_URL}/marketing/deactivate-advertisement/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(content),
        }
      );

      const data = await response.json();
      const { statusCode, message, adds } = data;

      if (response.ok) {
        setAdverts(adds);
        toast.success("Advertisement deactivated successfully.");
        handleCloseDeactivate();
      } else {
        toast.error(`${statusCode} : ${message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleActivate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const content = { reasonForDeactivation: reasonForActivation };
      const response = await fetch(
        `${BASE_API_URL}/marketing/activate-advertisement/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(content),
        }
      );

      const data = await response.json();
      const { statusCode, message, adds } = data;

      if (response.ok) {
        setAdverts(adds);
        toast.success("Advertisement deactivated successfully.");
        handleCloseActivate();
      } else {
        toast.error(`${statusCode} : ${message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Edit post
  const [editedPostBody, setEditedPostBody] = useState("");
  const [editedPostTitle, setEditedPostTitle] = useState("");
  const [editedContacts, setEditedContacts] = useState("");

  useEffect(() => {
    const selectedAdvert = adverts.find((advert) => advert.id === postId);
    if (selectedAdvert) {
      setEditedPostBody(selectedAdvert.postBody);
      setEditedPostTitle(selectedAdvert.postTitle);
      setEditedContacts(selectedAdvert.contacts);
    }
  }, [postId, adverts]);

  return (
    <AuthProvider>
      <div className="body">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <CustomNavbar />

            <Container
              style={{ backgroundColor: "#F5F0F0", padding: "40px" }}
              fluid
            >
              <Container style={{ color: "GrayText" }}>
                Home | Advertisements
              </Container>
            </Container>

            <div className="my-4"></div>

            <Container
              style={{
                backgroundColor: "#f9f9f9",
                padding: "20px 30px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                className="d-flex flex-column align-items-start bg-white p-4 rounded shadow-sm"
                style={{
                  border: "1px solid #e0e0e0",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
                }}
              >
                <div
                  className="d-flex justify-content-between align-items-center w-100 mb-3 p-3 rounded shadow-sm"
                  style={{
                    backgroundColor: "#ffffff",
                    border: "1px solid #e0e0e0",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <div>
                    <h5 className="text-danger fw-bold mb-1">Advertisements</h5>
                  </div>

                  <Button
                    variant="outline-danger"
                    size="sm"
                    className="shadow-sm"
                    onClick={handleOpenModal}
                    style={{ fontWeight: "500" }}
                  >
                    ➕ Create an Advert
                  </Button>
                </div>

                <p
                  className="text-muted mb-3"
                  style={{ fontSize: "0.95rem", lineHeight: "1.6" }}
                >
                  Advertising is essential for building brand visibility,
                  reaching new customers, and staying competitive in today’s
                  market. Strategic advertisements place your company directly
                  in front of your target audience, enhancing brand awareness,
                  trust, and customer loyalty. Advertising generates growth by
                  driving leads, increasing sales, and showcasing your unique
                  offerings. Each ad is a powerful tool to highlight the value
                  your products or services provide. Advertising is more than
                  promotion—it’s an investment in your company’s future and a
                  vital part of connecting with customers who are ready to
                  engage with your brand.
                </p>
              </div>
            </Container>

            <Container className="margin-top">
              <div className="shadow-lg p-4 mb-5 bg-white rounded">
                <Row>
                  {adverts.length > 0 ? (
                    <Col md={12}>
                      <Row>
                        {adverts.map((data, index) => (
                          <Col md={6} lg={4} key={index} className="mb-4">
                            <Card
                              className="shadow border-0 rounded"
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#ffffff",
                                border: "1px solid #e0e0e0",
                                transition: "transform 0.3s, box-shadow 0.3s",
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.transform = "scale(1.02)";
                                e.currentTarget.style.boxShadow =
                                  "0 4px 20px rgba(0, 0, 0, 0.1)";
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.transform = "scale(1)";
                                e.currentTarget.style.boxShadow = "none";
                              }}
                            >
                              {/* Image at the top of the card */}
                              <div className="image-carousel">
                                {data.images.length > 0 && (
                                  <img
                                    src={data.images[0].imageUrl}
                                    alt={data.postTitle}
                                    style={{
                                      borderRadius: "10px",
                                      height: "200px",
                                      objectFit: "cover",
                                      width: "100%",
                                    }}
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <Card.Header className="d-flex justify-content-between align-items-center p-3">
                                <div className="d-flex align-items-center">
                                  <h5
                                    className="mb-0"
                                    style={{
                                      fontSize: "1.25rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Title: {data.postTitle}
                                  </h5>
                                </div>
                              </Card.Header>
                              <Card.Header className="d-flex justify-content-between align-items-center p-3">
                                <div className="d-flex align-items-center gap-2 mb-2">
                                  {data.activeStatus ? (
                                    <Button
                                      variant="outline-success"
                                      size="sm"
                                      className="shadow-sm mb-2"
                                      onClick={() =>
                                        handleDeactivateAdd(data.id)
                                      }
                                    >
                                      <FaPowerOff
                                        className="text-warning"
                                        title="Deactivate"
                                      />{" "}
                                      Active
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outline-danger"
                                      size="sm"
                                      className="shadow-sm"
                                      onClick={() => handleActivateAdd(data.id)}
                                    >
                                      <FaPowerOff
                                        className="text-warning"
                                        title="Deactivate"
                                      />{" "}
                                      Inactive
                                    </Button>
                                  )}
                                </div>

                                <div className="d-flex align-items-center gap-2 mb-2">
                                  <Button
                                    variant="outline-primary"
                                    size="sm"
                                    className="shadow-sm d-flex align-items-center"
                                    onClick={() => handleEdit(data.id)}
                                  >
                                    <FaEdit
                                      className="text-primary me-1"
                                      size={16}
                                      title="Edit"
                                    />
                                    Edit
                                  </Button>

                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="shadow-sm d-flex align-items-center"
                                    onClick={() => handleShowDelete(data.id)}
                                  >
                                    <FaTrashAlt
                                      className="text-danger me-1"
                                      size={16}
                                      title="Delete"
                                    />
                                    Delete
                                  </Button>
                                </div>
                              </Card.Header>

                              <Card.Body className="p-3">
                                <div className="text-first">
                                  <h6
                                    style={{
                                      color: "#333",
                                      fontWeight: "bold",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Advertisement Details
                                  </h6>

                                  <p
                                    style={{
                                      fontSize: "0.95rem",
                                      color: "#555",
                                      lineHeight: "1.6",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {data.postBody}
                                  </p>
                                </div>
                              </Card.Body>

                              <Card.Footer className="text-muted text-center p-3">
                                <small>
                                  Created on{" "}
                                  {new Date(
                                    data.createdAt
                                  ).toLocaleDateString()}{" "}
                                  by{" "}
                                  {user.find(
                                    (ui) => ui.id === Number(data.createdById)
                                  )?.fullName || "Unknown User"}
                                </small>
                              </Card.Footer>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  ) : (
                    <Col md={12}>
                      <div className="text-center py-5">
                        <img
                          src={NoData}
                          style={{ borderRadius: "10px" }}
                          alt="No adverts available"
                          className="img-fluid mb-4"
                        />
                        <p className="text-muted font-italic">
                          No advertisements posted yet. Click on the button
                          bellow to create a post.
                        </p>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="shadow-sm"
                          onClick={handleOpenModal}
                        >
                          ➕ Create first advert post
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal} centered>
              <Modal.Header closeButton>
                <h5 className="text-danger" style={{ fontWeight: "bold" }}>
                  Create advert
                </h5>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label>Advert title</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={postTitle}
                      onChange={(e) => setPostTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Body content</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={5}
                      value={postBody}
                      onChange={(e) => setPostBody(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Advert contacts</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={contacts}
                      onChange={(e) => setContacts(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control
                      required
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      multiple
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        setFiles(target.files);
                      }}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={handleCloseModal}
                      disabled={isSubmitting}
                    >
                      Close creating advert
                    </Button>
                    <Button
                      size="sm"
                      variant="primary"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting
                        ? "Submitting advertisement data ..."
                        : "Create advertisement"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>

            <Modal show={showEdit} onHide={handleCloseEdit} centered>
              <Modal.Header closeButton>
                <h5 className="text-danger" style={{ fontWeight: "bold" }}>
                  Edit advert
                </h5>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleEditData}>
                  <Form.Group className="mb-4">
                    <Form.Label>Advert title</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={editedPostTitle}
                      onChange={(e) => setEditedPostTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Body content</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={5}
                      value={editedPostBody}
                      onChange={(e) => setEditedPostBody(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Advert contacts</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={editedContacts}
                      onChange={(e) => setEditedContacts(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      multiple
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        setFiles(target.files);
                      }}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={handleCloseEdit}
                      disabled={isSubmitting}
                    >
                      Close editing advert
                    </Button>
                    <Button
                      size="sm"
                      variant="primary"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting
                        ? "Editing advertisement data ..."
                        : "Edit advertisement"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>

            <Modal show={showDelete} onHide={handleCloseShowDelete} centered>
              <Modal.Header closeButton className="bg-light">
                <h6 className="text-danger">Delete advertisement</h6>
              </Modal.Header>
              <Modal.Body>
                <p className="text-muted">
                  Are you sure you want to delete this advert? This action is
                  irreversible.
                </p>
                <div className="spacing"></div>
                <Form onSubmit={handleDelete}>
                  <Modal.Footer>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      disabled={isSubmitting}
                      onClick={handleCloseShowDelete}
                    >
                      Cancel deletion
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting
                        ? "Deleting advertisement ..."
                        : "Delete advertisement"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>

            <Modal show={deactivate} onHide={handleCloseDeactivate} centered>
              <Modal.Header closeButton className="bg-light">
                <h6 className="text-danger">Deactivate advertisement</h6>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleDeactivate}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Reason for deactivating advertisement
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={5}
                      value={reasonForDeactivation}
                      onChange={(e) => setReasonForDeactivation(e.target.value)}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      disabled={isSubmitting}
                      onClick={handleCloseDeactivate}
                    >
                      Cancel deactivation
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting
                        ? "Deactivating advertisement ..."
                        : "Deactivate advertisement"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>

            <Modal show={activate} onHide={handleCloseActivate} centered>
              <Modal.Header closeButton className="bg-light">
                <h6 className="text-danger">Activate advertisement</h6>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleActivate}>
                  <Form.Group className="mb-4">
                    <Form.Label>Reason for activating advertisement</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={5}
                      value={reasonForActivation}
                      onChange={(e) => setReasonForActivation(e.target.value)}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      disabled={isSubmitting}
                      onClick={handleCloseActivate}
                    >
                      Cancel Activation
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting
                        ? "Activating advertisement ..."
                        : "Activate advertisement"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
            <br></br>
            <br></br>
            <br></br>
            <Footer />
          </>
        )}
      </div>
    </AuthProvider>
  );
};

export default Advertisements;
