// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border-radius: 15px;
}

.input-group-text {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px 0 0 5px;
}

.input-group .form-control {
  border-left: 0;
  border-radius: 0 5px 5px 0;
}

button {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/form.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".card {\n  border-radius: 15px;\n}\n\n.input-group-text {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 5px 0 0 5px;\n}\n\n.input-group .form-control {\n  border-left: 0;\n  border-radius: 0 5px 5px 0;\n}\n\nbutton {\n  background-color: #007bff;\n  border: none;\n  border-radius: 5px;\n}\n\nbutton:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
