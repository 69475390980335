export const getUserId = (): string => {
  const uniqueUserIdentity = "uniqueUserIdentity";
  let uniqueName = localStorage.getItem(uniqueUserIdentity);
  if (!uniqueName) {
    uniqueName = generateUniqueId();
    localStorage.setItem(uniqueUserIdentity, uniqueName);
  }
  return uniqueName;
};

const generateUniqueId = (): string => {
  const length = 20;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:',.<>?/~`-=";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
