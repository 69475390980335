import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";

interface ContactViews {
  userId: number;
}

const FetchItemsPosted: React.FC<ContactViews> = ({ userId }) => {
  const [viewsCount, setViewsCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/admin-overview/get-items-posted-count/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setViewsCount(data);
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };

    fetchData();
  }, [userId]);

  return <>{viewsCount !== null ? viewsCount : 0}</>;
};

export default FetchItemsPosted;
