import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

interface DetailsProps {
  postId: number;
}

interface UserDetails {
  businessName: string;
}

const FetchBusinessName: React.FC<DetailsProps> = ({ postId }) => {
  const [businessDetails, setBusinessDetails] = useState<UserDetails | null>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/admin-overview/get-business-name/${postId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setBusinessDetails(data);
        }
      } catch (error) {
        toast.error(`Error fetching details: ${error}`);
      }
    };

    fetchData();
  }, [postId]);

  return (
    <div>
      {businessDetails ? (
        <span>{businessDetails.businessName}</span>
      ) : (
        <Spinner animation="border" size="sm" />
      )}
    </div>
  );
};

export default FetchBusinessName;
