import React, { useContext } from "react";
import {
  Navbar,
  Nav,
  Button,
  Container,
  Form,
  Dropdown,
} from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaSearch,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import logo from "../images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../constants/tokenData";
import { UserContext } from "../constants/userContext";
import {
  PeopleFill,
  BoxSeam,
  MegaphoneFill,
  EnvelopeFill,
  PersonFill,
  TagFill,
  PersonWorkspace,
} from "react-bootstrap-icons";

const CustomNavbar: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const { adminRights, fullName } = useContext(UserContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    window.location.href = "/";
  };

  const location = useLocation();
  const currentPath = location.pathname;
  const shouldHideElements = currentPath.includes("item-search");

  const handleSellItemClick = () => {
    navigate("/my-items-on-sale");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const navigate = useNavigate();

  const handleHomePage = () => {
    navigate("/");
  };

  const handleLookingFor = () => {
    navigate("/looking-for");
  };

  const handleSearchNavigation = () => {
    navigate("/item-search");
  };

  const handleClientInquiries = () => {
    navigate("/client-inquiries");
  };

  const handleBusinessProfile = () => {
    navigate("/my-business-profile");
  };

  const handleMyProfile = () => {
    navigate("/my-profile");
  };

  return (
    <>
      <div style={{ backgroundColor: "#1B2A49", color: "#FFDDC1" }}>
        <Navbar className="py-0 border-bottom">
          <Container>
            <Nav className="me-auto">
              <Nav.Link
                href="https://www.facebook.com/groups/amd.bike"
                target="blank"
                className="text-muted small"
              >
                <FaFacebookF style={{ color: "#1877F2" }} />
              </Nav.Link>
              <Nav.Link
                href="https://www.instagram.com/amd.bike/"
                className="text-muted small"
              >
                <FaInstagram style={{ color: "#E4405F" }} />
              </Nav.Link>
              <Nav.Link
                href="https://wa.me/+254774310747"
                target="blank"
                className="text-muted small"
              >
                <FaWhatsapp style={{ color: "#25D366" }} />
              </Nav.Link>
              <Nav.Link
                href="https://www.youtube.com/@AMD.bike254"
                target="blank"
                className="text-muted small"
              >
                <FaYoutube style={{ color: "#FF0000" }} />
              </Nav.Link>
            </Nav>

            <Nav className="ml-auto">
              <Nav className="ml-3" style={{ marginRight: "5px" }}>
                {fullName}
              </Nav>
              {!isAuthenticated ? (
                <>
                  <span onClick={handleLogin} className="ms-1">
                    Login / Register
                  </span>
                </>
              ) : (
                <>
                  {isAuthenticated && (
                    <>
                      <div className="logout" onClick={handleLogout}>
                        <span>Logout</span>
                      </div>
                    </>
                  )}
                </>
              )}
            </Nav>
          </Container>
        </Navbar>
      </div>

      {/* Main Navbar with Logo, Search Bar, and User Actions */}
      <Navbar bg="light" className="py-3 border-bottom">
        <Container>
          <Navbar.Brand onClick={handleHomePage} style={{ cursor: "pointer" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ maxHeight: "55px" }}
              className="img-fluid"
            />
          </Navbar.Brand>

          {shouldHideElements ? (
            <>
              <Form>
                <Button disabled size="sm" variant="default" className="ms-2">
                  SEARCH ITEM
                  <FaSearch className="me-2" style={{ marginLeft: "5px" }} />
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Form>
                <Button
                  size="sm"
                  onClick={handleSearchNavigation}
                  variant="danger"
                  className="ms-2"
                >
                  SEARCH ITEM
                  <FaSearch className="me-2" style={{ marginLeft: "5px" }} />
                </Button>
              </Form>
            </>
          )}
        </Container>
      </Navbar>

      {/* Bottom Navbar for Categories and Navigation Links */}
      <Navbar bg="light" expand="lg" className="py-2">
        <Container>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="me-auto align-items-center">
              <Nav.Link href="/" style={{ color: "black" }}>
                Home
              </Nav.Link>
              <Nav.Link href="/bikes-on-sale" style={{ color: "black" }}>
                Bikes on sale
              </Nav.Link>
              <Nav.Link href="/gears-on-sale" style={{ color: "black" }}>
                Riding Gear on sale
              </Nav.Link>
              <Nav.Link href="/parts-on-sale" style={{ color: "black" }}>
                Parts on sale
              </Nav.Link>
              <Nav.Link href="/vendors-list" style={{ color: "black" }}>
                Vendors
              </Nav.Link>

              {isAuthenticated ? (
                <div>
                  <Nav.Link
                    onClick={handleSellItemClick}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <TagFill style={{ marginRight: "8px" }} />
                    <div className="bold colour">Sell an item</div>
                  </Nav.Link>
                </div>
              ) : (
                <div>
                  <Nav.Link
                    onClick={handleLogin}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <TagFill style={{ marginRight: "8px" }} />
                    <div className="bold colour">Sell an item</div>
                  </Nav.Link>
                </div>
              )}

              {isAuthenticated ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Inquiries
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleLookingFor}>
                      <PersonFill style={{ marginRight: "8px" }} />
                      My Inquiries
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleClientInquiries}>
                      <PeopleFill style={{ marginRight: "8px" }} />
                      Customer inquiries
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Customer Inquiries
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleLogin}>
                      <PersonFill style={{ marginRight: "8px" }} />
                      My Inquiries
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogin}>
                      <PeopleFill style={{ marginRight: "8px" }} />
                      Customer inquiries
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {adminRights === true && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Admin
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/all-system-users">
                      <PeopleFill style={{ marginRight: "8px" }} />
                      Users
                    </Dropdown.Item>
                    <Dropdown.Item href="/all-posted-items">
                      <BoxSeam style={{ marginRight: "8px" }} />
                      Items Posted
                    </Dropdown.Item>
                    <Dropdown.Item href="/advertisements">
                      <MegaphoneFill style={{ marginRight: "8px" }} />
                      Advertisements
                    </Dropdown.Item>
                    <Dropdown.Item href="/marketing-emails">
                      <EnvelopeFill style={{ marginRight: "8px" }} />
                      Marketing Emails
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {isAuthenticated ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Profile
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleMyProfile}>
                      <PersonFill style={{ marginRight: "8px" }} />
                      My profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleBusinessProfile}>
                      <PersonWorkspace style={{ marginRight: "8px" }} />
                      My business account
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Profile
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleLogin}>
                      <PersonFill style={{ marginRight: "8px" }} />
                      My profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogin}>
                      <PersonWorkspace style={{ marginRight: "8px" }} />
                      My business account
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
