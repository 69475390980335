import { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  BsPencilSquare,
  BsImageFill,
  BsTag,
  BsTrash,
  BsCheck2Circle,
} from "react-icons/bs";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import AuthProvider from "../auth/authProvider";
import formatNumberWithCommas from "../components/numberFormat";
import VendorLogo from "../images/noImage.jpg";
import { FaEye } from "react-icons/fa";
import FetchPostViews from "../components/fetchViews";
import CustomNavbar from "../headerpages/header";

interface Category {
  id: number;
  itemsOnSale: string;
}

interface ItemsData {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
  voidStatus: boolean;
}

interface ItemDetail {
  bikeCondition: string;
  yearOfManufacture: number;
  engineSize: number;
  viewingLocation: string;
  otherDetails: string;
  gearCondition: string;
  mileage: number;
  colorsAvailable: string;
  sizesAvailable: string;
  partCondition: string;
  locationForPickUp: string;
}

interface imagedata {
  id: number;
  imageUrl: string;
}

const VendorItemDetails = () => {
  const [items, setItems] = useState<ItemsData | null>(null);
  const [categories, setcategories] = useState<Category[]>([]);
  const [itemDetails, setItemDetails] = useState<ItemDetail | null>(null);
  const [partCondition, setPartCondition] = useState("");
  const [locationForPickUp, setLocationForPickUp] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [gearCondition, setGearCondition] = useState("");
  const [colorsAvailable, setColorsAvailable] = useState("");
  const [mileage, setMileage] = useState(0);
  const [sizesAvailable, setSizesAvailable] = useState("");
  const [bikeCondition, setBikeCondition] = useState("");
  const [yearOfManufacture, setYearOfManufacture] = useState(0);
  const [engineSize, setEngineSize] = useState(0);
  const [viewingLocation, setViewingLocation] = useState("");
  const [editedBikeConditionCondition, setEditedBikeConditionCondition] =
    useState("");
  const [editedMileage, setEditedMileage] = useState(0);
  const [editedYearOfManufacture, setEditedYearOfManufacture] = useState(0);
  const [editedEngineSize, setEditedEngineSize] = useState(0);
  const [editedViewingLocation, setEditedViewingLocation] = useState("");
  const [editedOtherDetails, setEditedOtherDetails] = useState("");
  const [editedGearCondition, setEditedGearCondition] = useState("");
  const [editedColorsAvailable, setEditedColorsAvailable] = useState("");
  const [editedSizesAvailable, setEditedSizesAvailable] = useState("");
  const [editedPartCondition, setEditedPartCondition] = useState("");
  const [editedLocationForPickUp, setEditedLocationForPickUp] = useState("");
  const [editedProductName, setEditedProductName] = useState("");
  const [editedPriceOfItem, setEditedPriceOfItem] = useState(0);
  const [editeditemCategory, setEditeditemCategory] = useState(0);
  const [editedNegotiable, setEditedNegotiable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saleComments, setSaleComments] = useState("");
  const [productImages, setProductImages] = useState<imagedata[]>([]);
  const [itemId, setItemId] = useState<number | null>(null);

  const { id } = useParams<string>();
  const postId = Number(id);

  const navigate = useNavigate();

  const [detailsModal, setDetailsModal] = useState(false);
  const showProdModal = () => setDetailsModal(true);
  const closeProdModal = () => setDetailsModal(false);

  const [imageModal, setImageModal] = useState(false);
  const showImageModal = () => setImageModal(true);
  const closeImageModal = () => setImageModal(false);

  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const openDeleteImageModal = (id: number) => {
    setItemId(id);
    setDeleteImageModal(true);
  };
  const closeDeleteImageModal = () => setDeleteImageModal(false);

  const [editProduct, setEditProduct] = useState(false);
  const showEditProductModal = () => {
    if (items) {
      setEditedProductName(items.productName);
      setEditedPriceOfItem(items.priceOfItem);
      setEditeditemCategory(items.itemCategory);
      setEditedNegotiable(items.negotiable);
    }
    setEditProduct(true);
  };
  const closeEditProductModal = () => setEditProduct(false);

  const [saleModal, setSaleModal] = useState(false);
  const saleModalOpen = () => setSaleModal(true);
  const saleModalClose = () => {
    setSaleComments("");
    setSaleModal(false);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const deleteModalOpen = () => setDeleteModal(true);
  const deleteModalClose = () => setDeleteModal(false);

  const [editProductDetails, setEditProductDetails] = useState(false);
  const showEditProductDetailsModal = () => {
    if (itemDetails) {
      setEditedBikeConditionCondition(itemDetails.bikeCondition);
      setEditedEngineSize(itemDetails.engineSize);
      setEditedYearOfManufacture(itemDetails.yearOfManufacture);
      setEditedMileage(itemDetails.mileage);
      setEditedViewingLocation(itemDetails.viewingLocation);
      setEditedOtherDetails(itemDetails.otherDetails);
      setEditedGearCondition(itemDetails.gearCondition);
      setEditedColorsAvailable(itemDetails.colorsAvailable);
      setEditedSizesAvailable(itemDetails.sizesAvailable);
      setEditedPartCondition(itemDetails.partCondition);
      setEditedLocationForPickUp(itemDetails.locationForPickUp);
    }
    setEditProductDetails(true);
  };
  const closeEditProductDetailsModal = () => setEditProductDetails(false);

  useEffect(() => {
    const fetchVendorProductData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/vendor-items/get-item-detail-for-vendor/${postId}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const itemData = await response.json();

        if (response.ok) {
          setItems(itemData);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchVendorProductData();
  }, [postId]);

  useEffect(() => {
    const fetchVendorProductDetail = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/vendor-items/get-item-detail-for-product/${postId}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const itemDetail = await response.json();

        if (response.ok) {
          setItemDetails(itemDetail);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchVendorProductDetail();
  }, [postId]);

  const handleBikeDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const bikeDetails = {
        bikeCondition,
        yearOfManufacture,
        engineSize,
        mileage,
        viewingLocation,
        otherDetails,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/create-bike-details/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(bikeDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Item details added";
        toast.success(successMessage);
        closeProdModal();
        setItemDetails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleGearDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const gearDetails = {
        gearCondition,
        colorsAvailable,
        sizesAvailable,
        otherDetails,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/create-gear-details/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(gearDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Item details added";
        toast.success(successMessage);
        closeProdModal();
        setItemDetails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handlePartDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const bikePartsDetails = {
        partCondition,
        locationForPickUp,
        otherDetails,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/create-part-details/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(bikePartsDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Item details added";
        toast.success(successMessage);
        closeProdModal();
        setItemDetails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  // Edit
  const handleEditBikeDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const editedBikeDetails = {
        bikeCondition: editedBikeConditionCondition,
        yearOfManufacture: editedYearOfManufacture,
        engineSize: editedEngineSize,
        mileage: editedMileage,
        viewingLocation: editedViewingLocation,
        otherDetails: editedOtherDetails,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/edit-bike-details/${postId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(editedBikeDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Item details updated";
        toast.success(successMessage);
        closeEditProductDetailsModal();
        setItemDetails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleEditGearDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const gearDetails = {
        gearCondition: editedGearCondition,
        colorsAvailable: editedColorsAvailable,
        sizesAvailable: editedSizesAvailable,
        otherDetails: editedOtherDetails,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/edit-gear-details/${postId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(gearDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Item details updated";
        toast.success(successMessage);
        closeEditProductDetailsModal();
        setItemDetails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleEditPartDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const bikePartsDetails = {
        partCondition: editedPartCondition,
        locationForPickUp: editedLocationForPickUp,
        otherDetails: editedOtherDetails,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/edit-parts-details/${postId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(bikePartsDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Item details updated";
        toast.success(successMessage);
        closeEditProductDetailsModal();
        setItemDetails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/system-data/get-items-on-sale-categories`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = await response?.json();

        if (response?.ok) {
          setcategories(data);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchCategoriesData();
  }, []);

  const handleEditProduct = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const bikePartsDetails = {
        productName: editedProductName,
        priceOfItem: editedPriceOfItem,
        itemCategory: editeditemCategory,
        negotiable: editedNegotiable,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/edit-product/${postId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(bikePartsDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        toast.success("Success: Item details updated");
        closeEditProductModal();
        setItems(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const uploadImages = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      const fileInput = e.currentTarget[0] as HTMLInputElement;
      const files = fileInput.files;

      if (!files || files.length === 0) {
        console.error("No files selected.");
        setIsSubmitting(false);
        return;
      }

      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });

      const response = await fetch(
        `${BASE_API_URL}/product-images/product-images-upload/${postId}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: formData,
        }
      );

      if (response.ok) {
        setIsSubmitting(false);
        window.location.reload();
      } else {
        const error = await response.json();
        toast.error(error.message);
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      throw error;
    }
  };

  const handleSold = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      const comments = {
        saleComments,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/mark-item-as-sold/${postId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(comments),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        toast.info("Congratulations on the sale");
        toast.success("Success: Item has been marked as sold");
        saleModalClose();
        navigate("/my-items-on-sale");
        setIsSubmitting(false);
        setItemDetails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
        setIsSubmitting(false);
        saleModalClose();
      }
      navigate("/my-items-on-sale");
    } catch (error) {
      toast.error(`${error}`);
      setIsSubmitting(true);
    }
  };

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${BASE_API_URL}/vendor-items/delete-product/${postId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        toast.warn("Success: Post deleted");
        deleteModalClose();
        setIsSubmitting(false);
        navigate("/my-items-on-sale");
        setItemDetails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
        setIsSubmitting(false);
        deleteModalClose();
      }
    } catch (error) {
      toast.error(`${error}`);
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/product-images/get-product-images/${postId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.ok) {
          const imageData = await response.json();
          setProductImages(imageData);
        } else {
          throw new Error("Failed to fetch details");
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };

    fetchData();
  }, [postId]);

  const selectedItem = productImages.find((identity) => identity.id === itemId);

  const handleVoidImage = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(
        `${BASE_API_URL}/product-images/void-image/${itemId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        toast.success("Image deleted successfully");
        closeDeleteImageModal();
        const returnedData = productImages.filter((item) => item.id !== itemId);
        setProductImages(returnedData);
      } else {
        throw new Error("Failed to void image");
      }
    } catch (error) {
      console.error("Error voiding image:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthProvider>
      <div className="body">
        <CustomNavbar />

        <Container
          fluid
          style={{
            backgroundColor: "#F5F0F0",
            borderRadius: "20px",
            padding: "30px",
          }}
        >
          <Container>
            <h5 style={{ fontWeight: "bold" }} className="text-danger">
              Polite Notice
            </h5>
            <br />
            1. Since we operate in Kenya, we only allow the sale of strictly{" "}
            <b>Kenyan-registered bikes.</b> Non-Kenyan registered posts will be
            removed.
            <br />
            2. Only posts with a <b>description and images</b> are visible to
            customers. Having trouble uploading images? Try uploading 2 images
            at a time.
            <br />
            <br />
            Happy selling!
          </Container>
        </Container>

        <Container>
          <div
            className="shadow-lg p-3 mb-5 bg-white rounded"
            style={{ marginTop: "40px" }}
          >
            {items ? (
              <Row>
                <Col md={6}>
                  {items?.availability === false ? (
                    <h5
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <BsCheck2Circle style={{ marginRight: "8px" }} />
                      This item has been sold!
                    </h5>
                  ) : (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Edit product</Tooltip>}
                      >
                        <Button
                          size="sm"
                          onClick={showEditProductModal}
                          className="margin-space"
                        >
                          <BsPencilSquare className="button-icon" /> Edit
                          product
                        </Button>
                      </OverlayTrigger>
                      {itemDetails ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">Edit product details</Tooltip>
                          }
                        >
                          <Button
                            size="sm"
                            onClick={showEditProductDetailsModal}
                            className="margin-space"
                          >
                            <BsPencilSquare className="button-icon" /> Edit
                            product details
                          </Button>
                        </OverlayTrigger>
                      ) : (
                        <>
                          <Button size="sm" disabled className="margin-space">
                            <BsPencilSquare className="button-icon" /> Edit
                            product details
                          </Button>
                        </>
                      )}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Add images</Tooltip>}
                      >
                        <Button
                          onClick={showImageModal}
                          size="sm"
                          className="margin-space"
                        >
                          <BsImageFill className="button-icon" /> Add images
                        </Button>
                      </OverlayTrigger>
                    </>
                  )}
                </Col>

                <Col md={6}>
                  {items?.availability === false ? null : (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Delete post</Tooltip>}
                      >
                        <Button
                          onClick={deleteModalOpen}
                          size="sm"
                          className="margin-space delete-button"
                        >
                          <BsTrash className="button-icon" /> Delete post
                        </Button>
                      </OverlayTrigger>
                    </>
                  )}

                  {items?.availability === false ? null : (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Mark as sold</Tooltip>}
                      >
                        <Button
                          onClick={saleModalOpen}
                          size="sm"
                          className="margin-space pulsate-button"
                        >
                          <BsTag className="button-icon" /> Mark as sold
                        </Button>
                      </OverlayTrigger>
                    </>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <br />
            <br />
            <Row>
              <Col md={6}>
                <div className="box-bordered">
                  <p style={{ fontWeight: "bold" }} className="text-danger">
                    Product details
                  </p>
                  <hr />

                  {items ? (
                    <div className="margin-botton">
                      <div className="position-relative">
                        <b>Product name:</b>{" "}
                        {items.productName || "Product name not found"}
                        <br />
                        <b>Price:</b>{" "}
                        {formatNumberWithCommas(items.priceOfItem)}
                        <br />
                        <b>Negotiable:</b>{" "}
                        {items.negotiable === true ? "Negotiable" : "Fixed"}
                        <br />
                        <br />
                        <b>Availability :</b>{" "}
                        {items.availability === true ? "Available" : "Sold"}
                        <br />
                        <div style={{ color: "gray", marginTop: "20px" }}>
                          <FaEye className="margin-space" />
                          <FetchPostViews postId={items.id} /> views
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p>No item found</p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="box-bordered">
                  <p style={{ fontWeight: "bold" }} className="text-danger">
                    More details
                  </p>
                  <hr />
                  {itemDetails ? (
                    <>
                      {itemDetails && items ? (
                        <div>
                          {/* Check category */}
                          {(() => {
                            switch (items.itemCategory) {
                              case 1:
                                return (
                                  <>
                                    <b>Condition: </b>
                                    {itemDetails.bikeCondition}
                                    <br />
                                    <b>Year of manufacture: </b>
                                    {itemDetails.yearOfManufacture}
                                    <br />
                                    <b>Engine size: </b>
                                    {itemDetails.engineSize}cc
                                    <br />
                                    <b>Bike mileage: </b>
                                    {itemDetails.mileage}KMs
                                    <br />
                                    <b>Viewing location: </b>
                                    {itemDetails.viewingLocation}
                                    <br />
                                    <br />
                                    <b>Other details: </b>
                                    {itemDetails.otherDetails}
                                    <br />
                                  </>
                                );
                              case 2:
                                return (
                                  <>
                                    <b>Condition: </b>
                                    {itemDetails.gearCondition}
                                    <br />
                                    <b>Colors available: </b>
                                    {itemDetails.colorsAvailable}
                                    <br />
                                    <b>Sizes available: </b>
                                    {itemDetails.sizesAvailable}
                                    <br />
                                    <br />
                                    <b>Details: </b>
                                    {itemDetails.otherDetails ||
                                      "Details not found"}
                                    <br />
                                  </>
                                );
                              case 3:
                                return (
                                  <>
                                    <b>Condition: </b>
                                    {itemDetails.partCondition}
                                    <br />
                                    <b>Location for pick up: </b>
                                    {itemDetails.locationForPickUp ||
                                      "Unspecified"}
                                    <br />
                                    <br />
                                    <b>Details: </b>
                                    {itemDetails.otherDetails ||
                                      "Details not found"}
                                    <br />
                                  </>
                                );
                              default:
                                return null;
                            }
                          })()}
                        </div>
                      ) : (
                        <p>No item found</p>
                      )}
                    </>
                  ) : (
                    <>
                      <p>
                        No details for the product found. use the button below
                        to add details
                      </p>
                      <hr />
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">Add product details</Tooltip>
                        }
                      >
                        <Button
                          onClick={showProdModal}
                          size="sm"
                          className="margin-space"
                        >
                          <BsPencilSquare className="button-icon" /> Add product
                          details
                        </Button>
                      </OverlayTrigger>

                      <Modal show={detailsModal} onHide={closeProdModal}>
                        <Modal.Body>
                          <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
                            {items ? (
                              <>
                                <div key={items.productName}>
                                  <b>Add details for {items.productName}</b>
                                </div>
                                <hr />
                                <br />
                                {items.itemCategory === 1 ? (
                                  <Form onSubmit={handleBikeDetails}>
                                    <Form.Group className="mb-4">
                                      <Form.Label>Bike condition</Form.Label>
                                      <Form.Control
                                        required
                                        as="select"
                                        value={bikeCondition}
                                        onChange={(e) =>
                                          setBikeCondition(e.target.value)
                                        }
                                      >
                                        <option disabled value="">
                                          Select condition
                                        </option>
                                        <option value="New">New</option>
                                        <option value="Used">Used</option>
                                      </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>
                                        Year of manufacture
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        type="number"
                                        value={yearOfManufacture}
                                        onChange={(e) =>
                                          setYearOfManufacture(
                                            parseInt(e.target.value, 10)
                                          )
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>Engine size (cc)</Form.Label>
                                      <Form.Control
                                        required
                                        type="number"
                                        value={engineSize}
                                        onChange={(e) =>
                                          setEngineSize(
                                            parseInt(e.target.value, 10)
                                          )
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>Mileage (KMs)</Form.Label>
                                      <Form.Control
                                        required
                                        type="number"
                                        value={mileage}
                                        onChange={(e) =>
                                          setMileage(
                                            parseInt(e.target.value, 10)
                                          )
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>Viewing location</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        value={viewingLocation}
                                        onChange={(e) =>
                                          setViewingLocation(e.target.value)
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>Other details</Form.Label>
                                      <br />
                                      <small>
                                        (Give clear narration to attract
                                        potential customers)
                                      </small>
                                      <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={otherDetails}
                                        onChange={(e) =>
                                          setOtherDetails(e.target.value)
                                        }
                                      />
                                    </Form.Group>

                                    <Modal.Footer>
                                      <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={closeProdModal}
                                      >
                                        Exit modal
                                      </Button>
                                      <Button
                                        variant="primary"
                                        type="submit"
                                        size="sm"
                                      >
                                        Save data
                                      </Button>
                                    </Modal.Footer>
                                  </Form>
                                ) : items.itemCategory === 2 ? (
                                  <Form onSubmit={handleGearDetails}>
                                    <Form.Group className="mb-4">
                                      <Form.Label>Gear condition</Form.Label>
                                      <Form.Control
                                        required
                                        as="select"
                                        value={gearCondition}
                                        onChange={(e) =>
                                          setGearCondition(e.target.value)
                                        }
                                      >
                                        <option disabled value="">
                                          Select condition
                                        </option>
                                        <option value="New">New</option>
                                        <option value="Used">Used</option>
                                      </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>Colors available</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        value={colorsAvailable}
                                        onChange={(e) =>
                                          setColorsAvailable(e.target.value)
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>Sizes available</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        value={sizesAvailable}
                                        onChange={(e) =>
                                          setSizesAvailable(e.target.value)
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>Other details</Form.Label>
                                      <br />
                                      <small>
                                        (Give clear narration to attract
                                        potential customers)
                                      </small>
                                      <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={otherDetails}
                                        onChange={(e) =>
                                          setOtherDetails(e.target.value)
                                        }
                                      />
                                    </Form.Group>

                                    <Modal.Footer>
                                      <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={closeProdModal}
                                      >
                                        Exit modal
                                      </Button>
                                      <Button
                                        variant="primary"
                                        type="submit"
                                        size="sm"
                                      >
                                        Save data
                                      </Button>
                                    </Modal.Footer>
                                  </Form>
                                ) : items.itemCategory === 3 ? (
                                  <Form onSubmit={handlePartDetails}>
                                    <Form.Group
                                      className="mb-4"
                                      controlId="itemCondition"
                                    >
                                      <Form.Label>Part condition</Form.Label>
                                      <Form.Control
                                        required
                                        as="select"
                                        value={partCondition}
                                        onChange={(e) =>
                                          setPartCondition(e.target.value)
                                        }
                                      >
                                        <option disabled value="">
                                          Select condition
                                        </option>
                                        <option value="New">New</option>
                                        <option value="Used">Used</option>
                                      </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>
                                        Location for viewing / pick up
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        value={locationForPickUp}
                                        onChange={(e) =>
                                          setLocationForPickUp(e.target.value)
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                      <Form.Label>Other details</Form.Label>
                                      <br />
                                      <small>
                                        (Give clear narration to attract
                                        potential customers)
                                      </small>
                                      <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={otherDetails}
                                        onChange={(e) =>
                                          setOtherDetails(e.target.value)
                                        }
                                      />
                                    </Form.Group>

                                    <Modal.Footer>
                                      <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={closeProdModal}
                                      >
                                        Exit modal
                                      </Button>
                                      <Button
                                        variant="primary"
                                        type="submit"
                                        size="sm"
                                      >
                                        Save data
                                      </Button>
                                    </Modal.Footer>
                                  </Form>
                                ) : null}
                              </>
                            ) : (
                              <p>No item found</p>
                            )}
                          </div>
                        </Modal.Body>
                      </Modal>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <p style={{ fontWeight: "bold" }} className="text-danger">
                  Product images
                  <br />
                  <br />
                </p>
                {items ? (
                  <>
                    <div className="image-gallery">
                      {productImages.length > 0 ? (
                        productImages.map((image, index) => (
                          <Container>
                            <Row className="justify-content-center">
                              <Col
                                key={index}
                                md={4}
                                className="image-container"
                              >
                                <img
                                  src={image.imageUrl}
                                  alt="Product"
                                  className="img-fluid custom-hover-img custom-hover-col"
                                  style={{
                                    borderRadius: "10px",
                                    marginBottom: "10px",
                                  }}
                                />
                                <Button
                                  onClick={() => openDeleteImageModal(image.id)}
                                  variant="danger"
                                  size="sm"
                                >
                                  Delete image
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        ))
                      ) : (
                        <Col md={12} className="text-center">
                          <img
                            src={VendorLogo}
                            alt="Product Images"
                            className="img-fluid"
                          />
                        </Col>
                      )}
                    </div>
                  </>
                ) : (
                  <p>No images found</p>
                )}
              </Col>
            </Row>
          </div>
        </Container>

        <Modal show={imageModal} onHide={closeImageModal}>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              <Form onSubmit={uploadImages}>
                <Form.Group className="mb-4">
                  <Form.Label>Add images for item</Form.Label>
                  <Form.Control
                    required
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    multiple
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={closeImageModal}
                  >
                    Exit modal
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    size="sm"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Save item images"}
                  </Button>
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={deleteModal} onHide={deleteModalClose}>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                Delete?
              </h4>
              <hr />
              Please note this action cannot be reversed. You will also loose
              views count on the post. Please proceed if you are sure.
              <Form onSubmit={handleDelete} style={{ marginTop: "30px" }}>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={deleteModalClose}
                  >
                    Exit modal
                  </Button>
                  <Button
                    variant="danger"
                    type="submit"
                    size="sm"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Delete post"}
                  </Button>
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={saleModal} onHide={saleModalClose}>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                SOLD!!
              </h4>
              <hr />
              By marking this item as sold, it will be permanently removed from
              the marketplace, making it no longer available for other buyers.
              <br />
              <br />
              We are delighted to hear that your item has been sold. To help us
              improve our services and understand your experience, please leave
              us a comment in the form below.
              <br />
              <br />
              We value your feedback and appreciate any insights you can provide
              about the selling process, the buyer's interaction, or any
              suggestions for improvement.
              <br />
              <br />
              Thank you for using our platform, and congratulations on your
              sale!
              <Form onSubmit={handleSold} style={{ marginTop: "30px" }}>
                <Form.Group className="mb-4">
                  <Form.Label>Leave comments (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    accept="image/png, image/jpeg, image/jpg"
                    multiple
                    value={saleComments}
                    onChange={(e) => setSaleComments(e.target.value)}
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={saleModalClose}
                  >
                    Exit modal
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    size="sm"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Item Sold"}
                  </Button>
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={editProduct} onHide={closeEditProductModal}>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              {items ? (
                <>
                  <div key={items.productName}>
                    <b>Edit {items.productName}</b>
                  </div>
                  <hr />
                  <br />
                </>
              ) : (
                <p>No item selected</p>
              )}
              <Form onSubmit={handleEditProduct}>
                <Form.Group className="mb-4">
                  <Form.Label>Product name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Example: Yamaha R6, LS2 Helmet, Yoshimura Exhaust"
                    value={editedProductName}
                    onChange={(e) => setEditedProductName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Item Category</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    value={editeditemCategory}
                    onChange={(e) =>
                      setEditeditemCategory(parseInt(e.target.value, 10))
                    }
                  >
                    <option value=""> </option>
                    {categories.map((category, index) => (
                      <option key={index} value={category.id}>
                        {category.itemsOnSale}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Item price (Ksh.)</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    value={editedPriceOfItem}
                    onChange={(e) =>
                      setEditedPriceOfItem(parseInt(e.target.value, 10))
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Price is negotiable</Form.Label>
                  <Form.Check
                    type="checkbox"
                    defaultChecked={editedNegotiable}
                    onChange={(e) => setEditedNegotiable(e.target.checked)}
                  />
                </Form.Group>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={closeEditProductModal}
                  >
                    Exit modal
                  </Button>
                  <Button variant="primary" type="submit" size="sm">
                    Save data
                  </Button>
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={editProductDetails} onHide={closeEditProductDetailsModal}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              {items ? (
                <>
                  <div key={items.productName}>
                    <b>Edit details for {items.productName}</b>
                  </div>
                  <hr />
                  <br />
                  {items.itemCategory === 1 ? (
                    <Form onSubmit={handleEditBikeDetails}>
                      <Form.Group className="mb-4">
                        <Form.Label>Bike condition</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          value={editedBikeConditionCondition}
                          onChange={(e) =>
                            setEditedBikeConditionCondition(e.target.value)
                          }
                        >
                          <option disabled value="">
                            Select condition
                          </option>
                          <option value="New">New</option>
                          <option value="Used">Used</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Year of manufacture</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          value={editedYearOfManufacture}
                          onChange={(e) =>
                            setEditedYearOfManufacture(
                              parseInt(e.target.value, 10)
                            )
                          }
                        />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Engine size (cc)</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          value={editedEngineSize}
                          onChange={(e) =>
                            setEditedEngineSize(parseInt(e.target.value, 10))
                          }
                        />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Mileage (KMs)</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          value={editedMileage}
                          onChange={(e) =>
                            setEditedMileage(parseInt(e.target.value, 10))
                          }
                        />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Viewing location</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={editedViewingLocation}
                          onChange={(e) =>
                            setEditedViewingLocation(e.target.value)
                          }
                        />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Other details</Form.Label>
                        <br />
                        <small>
                          (Give clear narration to attract potential customers)
                        </small>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value={editedOtherDetails}
                          onChange={(e) =>
                            setEditedOtherDetails(e.target.value)
                          }
                        />
                      </Form.Group>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={closeProdModal}
                        >
                          Exit modal
                        </Button>
                        <Button variant="primary" type="submit" size="sm">
                          Save data
                        </Button>
                      </Modal.Footer>
                    </Form>
                  ) : items.itemCategory === 2 ? (
                    <Form onSubmit={handleEditGearDetails}>
                      <Form.Group className="mb-4">
                        <Form.Label>Gear condition</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          value={editedGearCondition}
                          onChange={(e) =>
                            setEditedGearCondition(e.target.value)
                          }
                        >
                          <option disabled value="">
                            Select condition
                          </option>
                          <option value="New">New</option>
                          <option value="Used">Used</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Colors available</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={editedColorsAvailable}
                          onChange={(e) =>
                            setEditedColorsAvailable(e.target.value)
                          }
                        />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Sizes available</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={editedSizesAvailable}
                          onChange={(e) =>
                            setEditedSizesAvailable(e.target.value)
                          }
                        />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Other details</Form.Label>
                        <br />
                        <small>
                          (Give clear narration to attract potential customers)
                        </small>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value={editedOtherDetails}
                          onChange={(e) =>
                            setEditedOtherDetails(e.target.value)
                          }
                        />
                      </Form.Group>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={closeProdModal}
                        >
                          Exit modal
                        </Button>
                        <Button variant="primary" type="submit" size="sm">
                          Save data
                        </Button>
                      </Modal.Footer>
                    </Form>
                  ) : items.itemCategory === 3 ? (
                    <Form onSubmit={handleEditPartDetails}>
                      <Form.Group className="mb-4" controlId="itemCondition">
                        <Form.Label>Part condition</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          value={editedPartCondition}
                          onChange={(e) =>
                            setEditedPartCondition(e.target.value)
                          }
                        >
                          <option disabled value="">
                            Select condition
                          </option>
                          <option value="New">New</option>
                          <option value="Used">Used</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Location for viewing / pick up</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={editedLocationForPickUp}
                          onChange={(e) =>
                            setEditedLocationForPickUp(e.target.value)
                          }
                        />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Other details</Form.Label>
                        <br />
                        <small>
                          (Give clear narration to attract potential customers)
                        </small>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value={editedOtherDetails}
                          onChange={(e) =>
                            setEditedOtherDetails(e.target.value)
                          }
                        />
                      </Form.Group>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={closeEditProductDetailsModal}
                        >
                          Exit modal
                        </Button>
                        <Button variant="primary" type="submit" size="sm">
                          Save data
                        </Button>
                      </Modal.Footer>
                    </Form>
                  ) : null}
                </>
              ) : (
                <p>No item selected</p>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={deleteImageModal} onHide={closeDeleteImageModal}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              Are you sure you want to delete this image? This action cannot be
              reversed.
              {selectedItem ? (
                <Form onSubmit={handleVoidImage}>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={closeDeleteImageModal}
                      disabled={isSubmitting}
                    >
                      Exit modal
                    </Button>
                    <Button
                      variant="danger"
                      type="submit"
                      size="sm"
                      disabled={isSubmitting}
                    >
                      Delete permanently
                    </Button>
                  </Modal.Footer>
                </Form>
              ) : (
                <>No item selected</>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default VendorItemDetails;
