import React, { FormEvent, useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import {
  Card,
  Spinner,
  Table,
  Pagination,
  Container,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { AuthProvider } from "../constants/tokenData";
import Footer from "../headerpages/footer";
import FetchUserName from "../admin-fetch-data/fetchUserName";
import FetchDataCount from "../adminFetchNumbers/fetchTotalUsers";
import FetchDataCountDeletedAccounts from "../adminFetchNumbers/deletedAccounts";
import CustomNavbar from "../headerpages/header";
import FetchItemsPosted from "../components/itemsPosted";

interface Userdata {
  accountDeletion: boolean;
  accountVerification: boolean;
  id: number;
  fullName: string;
  createdAt: string;
  lastSeen: string;
  emailVerified: boolean;
}

interface emailsSent {
  createdAt: string;
  emailTo: number;
  messageTitle: string;
  messageContent: string;
  sentBy: number;
}

const FetchAllUsers: React.FC = () => {
  const [recentUsers, setRecentUsers] = useState<Userdata[] | null>(null);
  const [emails, setEmails] = useState<emailsSent[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [userId, setUserId] = useState(0);
  const itemsPerPage = 100;

  const [show, setShow] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const handleClose = () => {
    setMessageTitle("");
    setMessageContent("");
    setShow(false);
  };

  const handleShow = async (id: number) => {
    setSelectedUserId(id);
    setUserId(id);
    setShow(true);

    // Fetch emails sent to the user
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_API_URL}/send-email/get-emails-sent/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setEmails(data);
      }
    } catch (error) {
      toast.error(`Error fetching emails: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/admin-overview/get-all-users`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setRecentUsers(data);
        }
      } catch (error) {
        toast.error(`Error fetching details: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSendEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const gearDetails = {
        emailTo: userId,
        messageTitle,
        messageContent,
      };

      const response = await fetch(
        `${BASE_API_URL}/send-email/send-email-to-user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(gearDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        handleClose();
        toast.success(`Email sent successfully`);
        setEmails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error has occured ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Calculate the number of pages
  const totalPages = recentUsers
    ? Math.ceil(recentUsers.length / itemsPerPage)
    : 1;

  // Determine the users to display on the current page
  const currentUsers = recentUsers
    ? recentUsers.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    : [];

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <AuthProvider>
      <div className="body">
        <CustomNavbar />

        <Container
          fluid
          style={{
            backgroundColor: "#F5F0F0",
            borderRadius: "20px",
            padding: "30px",
          }}
        >
          <div className="spacing"></div>
          <h5 className="text-danger" style={{ fontWeight: "bold" }}>
            Registered users
          </h5>
          <h6>All registered users</h6>
        </Container>
        <div className="spacing"></div>
        <Container fluid>
          <Card>
            <Card.Body>
              Total registered users: <FetchDataCount />
              <br></br>
              Total deleted accounts: <FetchDataCountDeletedAccounts />
            </Card.Body>
          </Card>
          <div className="spacing"></div>
          <Card>
            <Card.Body>
              <div className="table-responsive">
                {loading ? (
                  <>
                    Loading new users table{" "}
                    <Spinner animation="border" size="sm" />
                  </>
                ) : (
                  <>
                    <Table hover responsive>
                      <thead style={{ backgroundColor: "#FFF5EE" }}>
                        <tr>
                          <th>Registered</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Last seen</th>
                          <th>Deleted</th>
                          <th>Verification</th>
                          <th style={{ textAlign: "center" }}>Items posted</th>
                          <th>Contact</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers && currentUsers.length > 0 ? (
                          currentUsers.map((user) => (
                            <tr key={user.id}>
                              <td>
                                {new Date(user.createdAt).toLocaleDateString()}
                              </td>
                              <td>{user.fullName}</td>
                              <td
                                style={{
                                  color: !user.emailVerified ? "red" : "black",
                                }}
                              >
                                {user.emailVerified ? "Verified" : "Unverified"}
                              </td>
                              <td>
                                {user.lastSeen
                                  ? new Date(user.lastSeen).toLocaleDateString()
                                  : "00:00:0000"}
                              </td>
                              <td
                                style={{
                                  color:
                                    user.accountDeletion === null
                                      ? "black"
                                      : "red",
                                }}
                              >
                                {user.accountDeletion === null
                                  ? "Active"
                                  : "Deleted"}
                              </td>
                              <td
                                style={{
                                  color:
                                    user.accountVerification === null
                                      ? "black"
                                      : "red",
                                }}
                              >
                                {user.accountVerification === null
                                  ? "Verified"
                                  : "Unverified"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <FetchItemsPosted userId={user.id} />
                              </td>
                              <td>
                                <Button
                                  size="sm"
                                  variant="primary"
                                  onClick={() => handleShow(user.id)}
                                >
                                  Contact
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7} style={{ textAlign: "center" }}>
                              No users found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <div className="spacing"></div>
                    <Pagination>
                      <Pagination.First
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                      />
                      <Pagination.Prev
                        onClick={() =>
                          handlePageChange(
                            currentPage > 1 ? currentPage - 1 : 1
                          )
                        }
                        disabled={currentPage === 1}
                      />
                      {Array.from({ length: totalPages }, (_, idx) => (
                        <Pagination.Item
                          key={idx + 1}
                          active={idx + 1 === currentPage}
                          onClick={() => handlePageChange(idx + 1)}
                        >
                          {idx + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() =>
                          handlePageChange(
                            currentPage < totalPages
                              ? currentPage + 1
                              : totalPages
                          )
                        }
                        disabled={currentPage === totalPages}
                      />
                      <Pagination.Last
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Container>

        {/* Email modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <FetchUserName userId={Number(selectedUserId)} />
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
                {emails && emails.length > 0 ? (
                  <div>
                    <h6 style={{ fontWeight: "bold" }}>Previous Emails Sent</h6>
                    <div className="spacing"></div>
                    <ul>
                      {emails.map((email, index) => (
                        <li key={index}>
                          <strong>Title:</strong> {email.messageTitle}
                          <br />{" "}
                          <small style={{ color: "grey" }}>
                            Sent on {new Date(email.createdAt).toLocaleString()}
                          </small>
                          <p>
                            <strong>Email content:</strong>{" "}
                            {email.messageContent}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <>
                    <p>No emails sent to this user.</p>
                  </>
                )}
              </Card.Body>
            </Card>
            <div className="spacing"></div>
            <Card>
              <Card.Body>
                <Form onSubmit={handleSendEmail}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      required
                      type="number"
                      hidden
                      defaultValue={userId}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email title</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={messageTitle}
                      onChange={(e) => setMessageTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email content</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={12}
                      value={messageContent}
                      onChange={(e) => setMessageContent(e.target.value)}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      size="sm"
                      disabled={isSubmitting}
                      variant="secondary"
                      onClick={handleClose}
                    >
                      {isSubmitting === true ? "Close" : "Close"}
                    </Button>
                    <Button
                      size="sm"
                      type="submit"
                      disabled={isSubmitting}
                      variant="primary"
                    >
                      {isSubmitting === true ? "Sending email" : "Send email"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default FetchAllUsers;
