// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-page {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.registration-info {
  margin-bottom: 30px;
}

.registration-info-inner {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.buyer-info h4,
.seller-info h4 {
  margin-bottom: 10px;
}

.buyer-info p,
.seller-info p {
  font-size: 14px;
  line-height: 1.6;
  color: #555555;
}

.registration-form-inner {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-info {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.login-info hr {
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 10px;
}

.login-info b {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
  font-weight: bold;
}

.login-info b:hover {
  color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/registration.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;;EAEE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".registration-page {\n  background-color: #f8f9fa;\n  min-height: 100vh;\n}\n\n.registration-info {\n  margin-bottom: 30px;\n}\n\n.registration-info-inner {\n  background-color: #ffffff;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.buyer-info h4,\n.seller-info h4 {\n  margin-bottom: 10px;\n}\n\n.buyer-info p,\n.seller-info p {\n  font-size: 14px;\n  line-height: 1.6;\n  color: #555555;\n}\n\n.registration-form-inner {\n  background-color: #ffffff;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.login-info {\n  text-align: center;\n  margin-top: 20px;\n  margin-bottom: 40px;\n}\n\n.login-info hr {\n  border: none;\n  border-top: 1px solid #ccc;\n  margin-bottom: 10px;\n}\n\n.login-info b {\n  cursor: pointer;\n  color: #007bff;\n  text-decoration: underline;\n  font-weight: bold;\n}\n\n.login-info b:hover {\n  color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
