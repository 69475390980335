import { FormEvent, useState } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../images/logo.png";
import Footer from "../headerpages/footer";
import NavigationPage from "../components/navigationPages";
import CustomNavbar from "../headerpages/header";

const AccountVerification = () => {
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  const handleSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const userData = { verificationToken: token };
      const response = await fetch(
        `${BASE_API_URL}/auth/account-verification/${token}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      const data = await response?.json();
      const { statusCode, message } = data;

      if (response.ok) {
        navigate("/login");
        toast.success("Account verified");
      } else {
        const errorMessage = `${statusCode} -  ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <CustomNavbar />
      <Container fluid style={{ backgroundColor: "#F5F0F0", padding: "40px" }}>
        <Container>
          <div style={{ textAlign: "center" }}>
            <h5 className="text-danger" style={{ fontWeight: "bold" }}>
              Verify your account
            </h5>
          </div>
        </Container>
      </Container>
      <Container className="py-5">
        <Row>
          <Col md={3} className="registration-info"></Col>
          <Col md={6} className="registration-form">
            <div className="registration-form-inner">
              <div style={{ textAlign: "center" }}>
                <span role="img" aria-label="Country Flag">
                  <img
                    src={logo}
                    alt=""
                    className="img-fluid"
                    style={{ width: "190px" }}
                  />
                </span>
                <br />
                <br />
                <h6>
                  Click the verification button below to verify your account
                </h6>
              </div>
              <hr />
              <br />
              <Form onSubmit={handleSignIn}>
                <Button
                  variant="primary"
                  disabled={submitting}
                  type="submit"
                  className="w-100"
                >
                  {submitting === true ? "Verifying account" : "Verify account"}
                </Button>
              </Form>
              <br />
              <br />
              <br />
              <NavigationPage />
            </div>
          </Col>
          <Col md={3} className="registration-info"></Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AccountVerification;
