import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { Button, Card, Carousel, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaBullhorn } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";

interface PostedAdverts {
  id: number;
  createdAt: string;
  postTitle: string;
  postBody: string;
  contacts: string;
  images: { imageUrl: string }[];
  createdById: number;
  activeStatus: boolean;
}

const AdvertisementsPosted: React.FC = () => {
  const [advert, setAdvert] = useState<PostedAdverts | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/customer-side/get-advertisements`,
          {
            method: "GET",
          }
        );

        const data = await response.json();
        const { message, statusCode, adds } = data;

        if (response.ok) {
          if (adds.length > 0) {
            // Select a random advertisement
            const randomIndex = Math.floor(Math.random() * adds.length);
            setAdvert(adds[randomIndex]);
          }
        } else {
          toast.error(`${statusCode} - ${message}`);
        }
      } catch (error) {
        toast.error(`Error fetching advertisements: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container my-4">
      <Card className="border-0 shadow-sm p-3 mb-5 bg-white rounded">
        <Card.Header className="bg-danger text-white d-flex align-items-center">
          <FaBullhorn className="me-2" />
          <h5 className="mb-0">Sponsored Content</h5>
        </Card.Header>

        {loading ? (
          <Card.Body className="text-center">
            <Spinner animation="border" size="sm" />
          </Card.Body>
        ) : advert ? (
          <Card.Body>
            {/* Carousel for Images */}
            {advert.images.length > 0 ? (
              <Carousel interval={null} className="mb-4">
                {advert.images.map((image, index) => (
                  <Carousel.Item key={index}>
                    <img
                      src={image.imageUrl}
                      alt={`Slide ${index + 1}`}
                      className="d-block w-100 rounded"
                      style={{ height: "200px", objectFit: "cover" }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <div className="text-muted text-center ">No images available</div>
            )}

            <h5 className="font-weight-bold mb-3 bold">{advert.postTitle}</h5>
            <p
              className="text-muted"
              style={{ lineHeight: "1.6", whiteSpace: "pre-line" }}
            >
              {advert.postBody}
            </p>
            <p className="font-weight-bold text-success bold">
              <BsTelephone className="me-2" />
              Contact: {advert.contacts}
            </p>
          </Card.Body>
        ) : (
          <Card.Body className="text-center bg-light border rounded py-5">
            <h3 className="mb-3">Advertise Here</h3>
            <p className="text-muted">
              Reach tens of potential customers by placing your ad with us.
            </p>
            <Button variant="primary" size="sm" className="px-4">
              Contact Us
            </Button>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default AdvertisementsPosted;
