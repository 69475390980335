import React, { ChangeEvent, FormEvent, useState } from "react";
import Footer from "../headerpages/footer";
import CustomNavbar from "../headerpages/header";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  FormSelect,
  Row,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Search from "../images/search.png";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { FaSearch } from "react-icons/fa";
import { Eye, CurrencyDollar } from "react-bootstrap-icons";
import FetchImage from "../components/fetchImage";
import formatNumberWithCommas from "../components/numberFormat";
import { useNavigate } from "react-router-dom";

interface SearchResults {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
  createdAt: string;
}

const SearchItem: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<SearchResults[]>([]);
  const [itemName, setItemName] = useState("");
  const [price, setPrice] = useState("");
  const [year, setYear] = useState("");
  const [mileage, setMileage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSearch = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const DataQuery = {
        category: selectedCategory,
        itemName: itemName,
        price: Number(price),
        year: Number(year),
        mileage: Number(mileage),
      };

      const response = await fetch(`${BASE_API_URL}/search/search-results`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(DataQuery),
      });

      const dataResponse = await response.json();

      if (response.ok) {
        setSearchResults(dataResponse);
      } else {
        const { statusCode, message } = dataResponse;
        toast.error(`${statusCode} - ${message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(Number(e.target.value));
  };

  const handleViewClick = (id: number) => {
    navigate(`/item-details/${id}`);
  };

  const handleInquiriesPage = () => {
    navigate(`/looking-for`);
  };

  return (
    <div className="body">
      <CustomNavbar />
      <Container fluid style={{ backgroundColor: "#F5F0F0", padding: "40px" }}>
        <Container style={{ color: "GrayText" }}>Home | Search</Container>
      </Container>

      <div className="spacing"></div>

      <Container style={{ textAlign: "center" }}>
        <h5 className="text-danger" style={{ fontWeight: "bold" }}>
          Search for an item <FaSearch />
        </h5>
        <p className="text-muted">
          Didn't find what you're looking for? Try making an inquiry{" "}
          <b onClick={() => handleInquiriesPage()}>on our inquries page</b>
        </p>
        <br />
        <Form
          onSubmit={handleSearch}
          className="p-4 border rounded bg-light shadow-sm"
        >
          <Row className="g-3">
            <Col md={3}>
              <FormSelect
                required
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="rounded"
              >
                <option value="">Select a category</option>
                <option value={1}>Sport Bikes</option>
                <option value={2}>Riding Gear</option>
                <option value={3}>Bike Parts</option>
              </FormSelect>
            </Col>

            <Col md={3}>
              <FormControl
                type="text"
                placeholder="Item name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                className="rounded"
              />
            </Col>

            <Col md={3}>
              <FormControl
                type="number"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                min="0"
                className="rounded"
              />
            </Col>

            {selectedCategory === 1 && (
              <>
                <Col md={3}>
                  <FormControl
                    type="number"
                    placeholder="Year"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    min="1900"
                    max={new Date().getFullYear()}
                    className="rounded"
                  />
                </Col>
                <Col md={3}>
                  <FormControl
                    type="number"
                    placeholder="Mileage"
                    value={mileage}
                    onChange={(e) => setMileage(e.target.value)}
                    min="0"
                    className="rounded"
                  />
                </Col>
              </>
            )}
          </Row>
          <br />
          <Row className="mt-3">
            <Col md={3}>
              <Button
                type="submit"
                size="sm"
                variant="danger"
                className="w-100 rounded"
              >
                Search item <FaSearch className="ms-2" />
              </Button>
            </Col>
          </Row>
        </Form>
        <div className="spacing"></div>
        <br />
        <br />
        {loading ? (
          <div className="text-center my-5">
            <Spinner animation="border" variant="danger" />
            <p>Searching for results...</p>
          </div>
        ) : searchResults.length > 0 ? (
          <>
            <Container className="py-4">
              <h5 style={{ fontWeight: "bold" }}>Sport Bikes on sale</h5>
              <p>
                Explore a variety of bikes sold by our registered vendors below.
                Take advantage of these great deals!
              </p>
              <Row>
                {searchResults.map((item) => (
                  <Col key={item.id} md={3} style={{ textAlign: "center" }}>
                    <Card className="mb-4 shadow-sm">
                      <FetchImage postId={item.id} />
                      <Card.Body>
                        <Card.Title>{item.productName}</Card.Title>
                        <p>
                          <CurrencyDollar className="me-1" />
                          <span className="price">
                            Ksh: {formatNumberWithCommas(item.priceOfItem)}
                          </span>
                        </p>
                        <Button
                          onClick={() => handleViewClick(item.id)}
                          variant="danger"
                          size="sm"
                        >
                          View more details <Eye className="ms-1" />
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </>
        ) : (
          <>
            <br />
            <br />
            <img src={Search} alt="Search" className="img-fluid" />
            <p className="text-muted">
              No items found. Try a different search.
            </p>
          </>
        )}
        <br />
        <br />
        <br />
        <br />
      </Container>

      <Footer />
    </div>
  );
};

export default SearchItem;
