import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  EnvelopeFill,
  TelephoneFill,
  GeoAltFill,
  Facebook,
  Whatsapp,
  Instagram,
  Youtube,
} from "react-bootstrap-icons";

const Footer: React.FC = () => {
  return (
    <>
      <div
        style={{
          background: "linear-gradient(150deg, #F5F0F0, #ECECEC)",
          color: "#333",
          padding: "40px 0",
          marginTop: "100px",
          border: "1px solid #ddd",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Container>
          <Row>
            {/* Contact Us */}
            <Col md={4} sm={12} xs={12} className="mb-4">
              <h5 style={{ fontWeight: "700", color: "#333" }}>Contact Us</h5>
              <p className="d-flex align-items-center mb-3">
                <EnvelopeFill
                  style={{ marginRight: "12px", color: "#FF7F50" }}
                />
                <strong>Email:</strong>{" "}
                <a
                  href="mailto:admin@amd.bike"
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  admin@amd.bike
                </a>
              </p>
              <p className="d-flex align-items-center mb-3">
                <TelephoneFill
                  style={{ marginRight: "12px", color: "#FF7F50" }}
                />
                <strong>Phone:</strong>{" "}
                <a
                  href="tel:+254734690591"
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  (+254) 734-690-591
                </a>
              </p>
              <p className="d-flex align-items-center">
                <GeoAltFill style={{ marginRight: "12px", color: "#FF7F50" }} />
                Nairobi, Kenya
              </p>
            </Col>

            {/* Quick Links */}
            <Col md={4} sm={12} xs={12} className="mb-4">
              <h5 style={{ fontWeight: "700", color: "#333" }}>Quick Links</h5>
              <ul style={{ listStyleType: "none", padding: "0" }}>
                <li className="mb-2">
                  <a
                    href="/about"
                    style={{ color: "#333", textDecoration: "none" }}
                  >
                    About Us
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/services"
                    style={{ color: "#333", textDecoration: "none" }}
                  >
                    Services
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/contact"
                    style={{ color: "#333", textDecoration: "none" }}
                  >
                    Contact
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/privacy-policy"
                    style={{ color: "#333", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </Col>

            {/* Subscribe to Newsletter */}
            <Col
              md={4}
              sm={12}
              xs={12}
              className="p-4 rounded shadow-lg"
              style={{ backgroundColor: "#FFF", border: "2px solid #EEE" }}
            >
              <h5 style={{ fontWeight: "700", color: "#FF7F50" }}>
                Subscribe to Our Newsletter
              </h5>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    className="my-3 p-2 rounded"
                    style={{
                      border: "1px solid #ddd",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                    }}
                  />
                </Form.Group>
                <Button
                  variant="danger"
                  type="submit"
                  className="w-100 py-2"
                  style={{ fontWeight: "bold", letterSpacing: "0.5px" }}
                >
                  <EnvelopeFill style={{ marginRight: "8px" }} /> SUBSCRIBE
                </Button>
              </Form>
            </Col>
          </Row>

          {/* Social Media Links */}
          <Row className="text-center mt-4">
            <Col>
              <h5 style={{ fontWeight: "700", color: "#333" }}>Follow Us</h5>
              <div>
                <a
                  className="text-primary"
                  href="https://www.facebook.com/groups/amd.bike"
                  style={{ margin: "0 15px", color: "#3b5998" }}
                >
                  <Facebook size={30} />
                </a>
                <a
                  className="text-success"
                  href="https://whatsapp.com/channel/0029Va4XdMP2ZjCoCYI9b201"
                  style={{ margin: "0 15px", color: "#3b5998" }}
                >
                  <Whatsapp size={30} />
                </a>
                <a
                  className="text-danger"
                  href="https://www.instagram.com/amd.bike/"
                  style={{ margin: "0 15px", color: "#C13584" }}
                >
                  <Instagram size={30} />
                </a>
                <a
                  className="text-danger"
                  href="https://www.youtube.com/@AMD.bike254"
                  style={{ margin: "0 15px", color: "#C13584" }}
                >
                  <Youtube size={30} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Footer Bottom */}
      <Container
        fluid
        className="footer-bottom text-center py-3"
        style={{
          backgroundColor: "#1B2A49",
          color: "#FFDDC1",
          fontSize: "0.9rem",
          padding: "20px",
          marginBottom: 0,
        }}
      >
        <p style={{ margin: 0, fontWeight: "500" }}>
          &copy; {new Date().getFullYear()} Developed and maintained by{" "}
          <strong>Videll</strong> | Contact:{" "}
          <a
            href="tel:+254711414182"
            style={{ color: "#FFDDC1", textDecoration: "none" }}
          >
            254711414182
          </a>
        </p>
      </Container>
    </>
  );
};

export default Footer;
