import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import VendorLogo from "../images/noImage2.png";
import { Col } from "react-bootstrap";

interface ViewsPerPost {
  postId: number;
}

const FetchImageVendor: React.FC<ViewsPerPost> = ({ postId }) => {
  const [productImage, setProductImage] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/product-images/get-product-image-vendor/${postId}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setProductImage(data.imageUrl);
        }
      } catch (error) {}
    };

    fetchData();
  }, [postId]);

  return (
    <div className="image-gallery">
      {productImage ? (
        <Col
          md={12}
          className="image-container"
          style={{ textAlign: "center" }}
        >
          <div className="image-wrapper">
            <img
              src={productImage}
              alt="Product Images"
              className="img-fluid custom-hover-img custom-hover-col"
            />
          </div>
        </Col>
      ) : (
        <Col md={12} className="image-container">
          <div className="image-wrapper">
            <img src={VendorLogo} alt="Product Images" className="img-fluid" />
          </div>
        </Col>
      )}
    </div>
  );
};

export default FetchImageVendor;
