import { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import {
  BsBuilding,
  BsCardImage,
  BsFileText,
  BsPencilSquare,
  BsTrash,
} from "react-icons/bs";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../auth/authProvider";
import VendorLogo from "../images/vendorImage.png";
import TimeDisplayProps from "../components/timeFormat";
import CustomNavbar from "../headerpages/header";
import PageLoader from "../components/pageLoader";
import { PersonWorkspace } from "react-bootstrap-icons";

interface userBusiness {
  createdAt: string;
  id: number;
  itemsSold: number;
  businessContacts: string;
  businessEmail: string;
  businessLocation: string;
  returnPolicy: boolean;
  returnDays: number;
  businessName: string;
  businessNature: string;
  businessDescription: string;
}

const BusinessProfile = () => {
  const [logo, setLogo] = useState<string | null>(null);
  const [businessData, setBusinessData] = useState<userBusiness | null>(null);
  const [voidBusinessProfile, setVoidBusinessProfile] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [businessContacts, setBusinessContacts] = useState("254 ");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [returnPolicy, setReturnPolicy] = useState(false);
  const [returnDays, setReturnDays] = useState(0);
  const [businessDescription, setBusinessDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editedBusinessName, setEditedBusinessName] = useState("");
  const [editedBusinessContacts, setEditedBusinessContacts] = useState("");
  const [editedBusinessEmail, setEditedBusinessEmail] = useState("");
  const [editedBusinessNature, setEditedBusinessNature] = useState("");
  const [editedBusinessLocation, setEditedBusinessLocation] = useState("");
  const [editedReturnPolicy, setEditedReturnPolicy] = useState(false);
  const [editedReturnDays, setEditedReturnDays] = useState(0);
  const [editedBusinessDescription, setEditedBusinessDescription] =
    useState("");
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();

  // Modal for user profile creation
  const [profileModal, setProfileModal] = useState(false);
  const showProfileModal = () => setProfileModal(true);
  const CloseProfileModal = () => setProfileModal(false);

  // Modal for profile edit
  const [editProfile, setEditProfile] = useState(false);
  const showEditProdile = () => {
    if (businessData) {
      // Set initial values from businessData when the modal is shown
      setEditedBusinessName(businessData.businessName);
      setEditedBusinessContacts(businessData.businessContacts);
      setEditedBusinessEmail(businessData.businessEmail);
      setEditedBusinessNature(businessData.businessNature);
      setEditedBusinessLocation(businessData.businessLocation);
      setEditedReturnPolicy(businessData.returnPolicy);
      setEditedReturnDays(businessData.returnDays);
      setEditedBusinessDescription(businessData.businessDescription);
    }
    setEditProfile(true);
  };
  const hideEditProfile = () => {
    setEditedBusinessName("");
    setEditedBusinessContacts("");
    setEditedBusinessEmail("");
    setEditedBusinessNature("");
    setEditedBusinessLocation("");
    setEditedReturnPolicy(false);
    setEditedReturnDays(0);
    setEditedBusinessDescription("");
    setEditProfile(false);
  };

  // Modal for logo change
  const [changeLogo, setChangeLogo] = useState(false);
  const showEditLogo = () => setChangeLogo(true);
  const hideEditLogo = () => setChangeLogo(false);

  // Modal for profile delete
  const [profileDelete, setProfileDelete] = useState(false);
  const showProfileDelete = () => setProfileDelete(true);
  const hideProfileDelete = () => {
    setVoidBusinessProfile(false);
    setProfileDelete(false);
  };

  useEffect(() => {
    const fetchBusinessData = async () => {
      setloading(true);
      try {
        const response = await fetch(
          `${BASE_API_URL}/user/get-business-details`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response?.ok) {
          const businessDetails = await response?.json();
          setBusinessData(businessDetails);
        }
      } catch (error) {
        toast.error(`${error}`);
      } finally {
        setloading(false);
      }
    };

    fetchBusinessData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        // Fetch logo
        const getLogo = await fetch(
          `${BASE_API_URL}/product-images/get-business-logo`,
          { method: "GET", headers }
        );

        if (getLogo.ok) {
          const logoData = await getLogo.text();
          setLogo(logoData);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchData();
  }, []);

  const handleCreateProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const businessProfile = {
        businessName,
        businessContacts,
        businessEmail,
        businessNature,
        businessLocation,
        returnPolicy,
        returnDays,
        businessDescription,
      };

      const response = await fetch(
        `${BASE_API_URL}/user/create-business-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(businessProfile),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Business profile created!";
        toast.success(successMessage);
        CloseProfileModal();
        setBusinessData(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const businessProfile = {
        businessName: editedBusinessName,
        businessContacts: editedBusinessContacts,
        businessEmail: editedBusinessEmail,
        businessNature: editedBusinessNature,
        businessLocation: editedBusinessLocation,
        returnPolicy: editedReturnPolicy,
        returnDays: editedReturnDays,
        businessDescription: editedBusinessDescription,
      };

      const response = await fetch(
        `${BASE_API_URL}/user/edit-business-details`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(businessProfile),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Business profile edited!";
        toast.success(successMessage);
        CloseProfileModal();
        setBusinessData(responseData);
        hideEditProfile();
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUploadlogo = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      const fileInput = e.currentTarget[0] as HTMLInputElement;
      const file = fileInput.files && fileInput.files[0];

      if (!file) {
        console.error("No file selected.");
        setIsSubmitting(false);
        return;
      }

      formData.append("file", file);

      const response = await fetch(
        `${BASE_API_URL}/product-images/upload-logo`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: formData,
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = `Business logo uploaded successfully`;
        toast.success(successMessage);

        hideEditLogo();
        setIsSubmitting(false);

        window.location.reload();

        // Reset the file input after successful submission
        e.currentTarget.reset();
      } else {
        const errorMessage = `${statusCode} -  ${message}`;
        toast.error(errorMessage);
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVoidBusiness = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const voidbusiness = { voidBusinessProfile };

      const response = await fetch(
        `${BASE_API_URL}/user/void-business-details`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(voidbusiness),
        }
      );

      if (response.ok) {
        toast.success("Profile has been deleted sucessfully!");
        hideProfileDelete();
        navigate("/");
      } else {
        const error = await response.json();
        toast.error(error.message);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthProvider>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className="body">
            <CustomNavbar />
            <Container
              fluid
              style={{
                backgroundColor: "#F5F0F0",
                borderRadius: "20px",
                padding: "30px",
              }}
            >
              <Container style={{ color: "gray" }}>
                Home | Profile | My business Profile
              </Container>
            </Container>

            <br />
            <br />
            <Container className="my-4">
              <div className="shadow-lg p-4 bg-light rounded">
                {/* Header */}
                <Row className="border-bottom pb-2 mb-4">
                  <Col xs={12} md={6}>
                    <h5 className="text-danger d-flex align-items-center bold">
                      <BsBuilding className="me-2" /> Business Overview
                    </h5>
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    className="text-md-end text-muted mt-2 mt-md-0"
                  >
                    <small>Review and update your business details</small>
                  </Col>
                </Row>

                {/* Main Content */}
                <Row>
                  {/* Business Logo Section */}
                  {businessData ? (
                    <Col
                      md={4}
                      className="d-flex flex-column align-items-center"
                    >
                      <div className="border p-3 rounded bg-white shadow-sm mb-4 w-100 text-center">
                        <h5>
                          <BsCardImage className="me-2" />
                          <strong>Business Logo</strong>
                        </h5>
                        <p className="text-muted">
                          Your logo enhances brand recognition.
                        </p>
                        <hr />
                        <div
                          style={{
                            backgroundColor: "#F5F0F0",
                            padding: "20px",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src={logo || VendorLogo}
                            alt="Business Logo"
                            className="img-fluid rounded mb-2"
                            style={{ maxHeight: "200px", objectFit: "contain" }}
                          />

                          {/* Edit Button for Logo */}
                          <br />
                          <Button
                            onClick={showEditLogo}
                            size="sm"
                            variant="outline-dark"
                            className="mt-2"
                          >
                            <BsCardImage className="button-icon" /> Change Logo
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}

                  {/* Business Profile Section */}
                  <Col md={8}>
                    <div className="border p-4 rounded bg-white mb-4 shadow-sm">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="text-dark">
                          <BsFileText className="me-2" />
                          <strong>Business Profile</strong>
                        </h5>
                      </div>
                      <p className="text-muted">
                        Ensure your business information is up-to-date and
                        engaging.
                      </p>
                      <hr />
                      {businessData ? (
                        <div
                          style={{
                            backgroundColor: "#F5F0F0",
                            padding: "20px",
                            borderRadius: "10px",
                          }}
                        >
                          <p>
                            <b>Business Name:</b> {businessData.businessName}
                          </p>
                          <p>
                            <b>Contact:</b> {businessData.businessContacts}
                          </p>
                          <p>
                            <b>Email:</b>{" "}
                            {businessData.businessEmail || "No email provided"}
                          </p>
                          <p>
                            <b>Nature:</b> {businessData.businessNature}
                          </p>
                          <p>
                            <b>Location:</b>{" "}
                            {businessData.businessLocation ||
                              "Unspecified location"}
                          </p>
                          <p>
                            <b>Return Policy:</b>{" "}
                            {businessData.returnDays ? "Yes" : "No"}
                          </p>
                          <p>
                            <b>Return Days:</b> {businessData.returnDays || 0}{" "}
                            days
                          </p>
                          <p>
                            <b>Description:</b>{" "}
                            {businessData.businessDescription ||
                              "No business description available"}
                          </p>
                          <small className="text-muted">
                            Registered Date:{" "}
                            <TimeDisplayProps
                              timeString={businessData.createdAt}
                            />
                          </small>
                          <br />
                          <br />
                          <br />
                          {/* Footer with Action Buttons */}
                          <div className="d-flex gap-2 justify-content-start border-top pt-3 mt-4">
                            {businessData && (
                              <>
                                <Button
                                  onClick={showEditProdile}
                                  size="sm"
                                  variant="primary"
                                >
                                  <BsPencilSquare className="button-icon" />{" "}
                                  Edit business
                                </Button>
                                <Button
                                  onClick={showProfileDelete}
                                  size="sm"
                                  variant="danger"
                                >
                                  <BsTrash className="button-icon" /> Delete
                                  business
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <p>
                            No business profile found! Click{" "}
                            <span
                              onClick={showProfileModal}
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              here
                            </span>{" "}
                            to create your business profile.
                          </p>
                          <br />
                          <br />
                          <Button
                            variant="outline-danger"
                            size="sm"
                            className="shadow-sm d-flex align-items-center"
                            onClick={showProfileModal}
                          >
                            <PersonWorkspace style={{ marginRight: "8px" }} />
                            Create business profile
                          </Button>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>

            <Modal show={profileModal} onHide={CloseProfileModal} centered>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
                  <Form onSubmit={handleCreateProfile}>
                    <Form.Group className="mb-4">
                      <Form.Label>Business name *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business contacts *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={businessContacts}
                        onChange={(e) => setBusinessContacts(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business email / Personal email</Form.Label>
                      <Form.Control
                        type="email"
                        required
                        value={businessEmail}
                        onChange={(e) => setBusinessEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business Nature</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        value={businessNature}
                        onChange={(e) => setBusinessNature(e.target.value)}
                      >
                        <option value="" disabled>
                          Select business nature
                        </option>
                        <option value="Online based">Online based</option>
                        <option value="Physical location">
                          Physical location
                        </option>
                        <option value="Hybrid">Hybrid</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business location</Form.Label>
                      <Form.Control
                        type="text"
                        value={businessLocation}
                        onChange={(e) => setBusinessLocation(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Return policy</Form.Label>
                      <Form.Check
                        type="checkbox"
                        checked={returnPolicy}
                        onChange={(e) => setReturnPolicy(e.target.checked)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>
                        Return days{" "}
                        <small>(If return policy is accepted)</small>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={returnDays}
                        onChange={(e) =>
                          setReturnDays(parseInt(e.target.value, 10))
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>
                        Business description <small>(Optional)</small>{" "}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="What makes your business to stand out ... . . 
                quality, experience, delivery, customer reviews, location, honesty, 
                customer care, years of experience, vast knowledge of products ... . e.t.c"
                        rows={10}
                        value={businessDescription}
                        onChange={(e) => setBusinessDescription(e.target.value)}
                      />
                    </Form.Group>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={CloseProfileModal}
                        disabled={isSubmitting}
                      >
                        Close modal
                      </Button>
                      <Button variant="primary" type="submit" size="sm">
                        {isSubmitting === true
                          ? "Creating profile"
                          : "Create profile"}
                      </Button>
                    </Modal.Footer>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={editProfile} onHide={hideEditProfile} centered>
              <Modal.Header closeButton className="strong">
                Edit business profile
              </Modal.Header>
              <Modal.Body>
                <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
                  {businessData ? (
                    <div>
                      <Form onSubmit={handleEditProfile}>
                        <Form.Group className="mb-4">
                          <Form.Label>Business name *</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={editedBusinessName}
                            onChange={(e) =>
                              setEditedBusinessName(e.target.value)
                            }
                          />
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label>Business contacts *</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={editedBusinessContacts}
                            onChange={(e) =>
                              setEditedBusinessContacts(e.target.value)
                            }
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label>
                            Business email / Personal email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            required
                            value={editedBusinessEmail}
                            onChange={(e) =>
                              setEditedBusinessEmail(e.target.value)
                            }
                          />
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label>Business Nature</Form.Label>
                          <Form.Control
                            required
                            as="select"
                            value={editedBusinessNature}
                            onChange={(e) =>
                              setEditedBusinessNature(e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Select business nature
                            </option>
                            <option value="Online based">Online based</option>
                            <option value="Physical location">
                              Physical location
                            </option>
                            <option value="Hybrid">Hybrid</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label>Business location</Form.Label>
                          <Form.Control
                            type="text"
                            value={editedBusinessLocation}
                            onChange={(e) =>
                              setEditedBusinessLocation(e.target.value)
                            }
                          />
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label>Return policy</Form.Label>
                          <Form.Check
                            type="checkbox"
                            checked={editedReturnPolicy}
                            onChange={(e) =>
                              setEditedReturnPolicy(e.target.checked)
                            }
                          />
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label>
                            Return days{" "}
                            <small>(If return policy is accepted)</small>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            value={editedReturnDays}
                            onChange={(e) =>
                              setEditedReturnDays(parseInt(e.target.value, 10))
                            }
                          />
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Label>
                            Business description <small>(Optional)</small>{" "}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="What makes your business to stand out ... . . 
                quality, experience, delivery, customer reviews, location, honesty, 
                customer care, years of experience, vast knowledge of products ... . e.t.c"
                            rows={10}
                            value={editedBusinessDescription}
                            onChange={(e) =>
                              setEditedBusinessDescription(e.target.value)
                            }
                          />
                        </Form.Group>

                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={CloseProfileModal}
                            disabled={isSubmitting}
                          >
                            Close modal
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            size="sm"
                            disabled={isSubmitting}
                          >
                            {isSubmitting === true
                              ? "Editing profile"
                              : "Create profile"}
                          </Button>
                        </Modal.Footer>
                      </Form>
                    </div>
                  ) : (
                    <div>
                      No business profile found! Click{" "}
                      <b
                        onClick={showProfileModal}
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        here
                      </b>{" "}
                      to create a business profile.
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={changeLogo} onHide={hideEditLogo} centered>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
                  <Form onSubmit={handleUploadlogo}>
                    <Form.Group className="mb-4">
                      <Form.Label>Add images for item</Form.Label>
                      <Form.Control
                        required
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        multiple
                      />
                    </Form.Group>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={hideEditLogo}
                        disabled={isSubmitting}
                      >
                        Close modal
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        size="sm"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Updating logo" : "Change logo"}
                      </Button>
                    </Modal.Footer>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={profileDelete} onHide={hideProfileDelete} centered>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
                  Delete business profile
                  <br />
                  <br />
                  Are you sure you want to delete your profile? This action is
                  irreversible. However, you can reactivate your profile by
                  writing to the <b>admin.</b>
                  <br />
                  Please note that all your sale posts will be deactivated and
                  account archieved.
                  <hr />
                  <br />
                  <Form onSubmit={handleVoidBusiness}>
                    <Form.Group className="mb-4">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Check
                          style={{
                            transform: "scale(1.5)",
                            marginRight: "10px",
                          }}
                          type="checkbox"
                          checked={voidBusinessProfile}
                          onChange={(e) =>
                            setVoidBusinessProfile(e.target.checked)
                          }
                        />
                        <span style={{ color: "red" }}>I confirm deletion</span>
                      </div>
                    </Form.Group>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={hideProfileDelete}
                        disabled={isSubmitting}
                      >
                        Close modal
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        size="sm"
                        disabled={isSubmitting}
                        className="btn-delete"
                      >
                        {isSubmitting === true
                          ? "Deleting profile"
                          : "Delete profile"}
                      </Button>
                    </Modal.Footer>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>
            <Footer />
          </div>
        </>
      )}
    </AuthProvider>
  );
};

export default BusinessProfile;
