import { FormEvent, useState } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import NavigationPage from "../components/navigationPages";
import { EnvelopeFill, LockFill } from "react-bootstrap-icons";

const LoginPage = () => {
  const [userEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const userData = { userEmail, password };
      const response = await fetch(`${BASE_API_URL}/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();
      const { statusCode, message } = data;

      if (response.ok) {
        const { access_token } = data.token;
        localStorage.setItem("token", access_token);
        window.location.href = "/";
      } else {
        toast.error(`${statusCode} - ${message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container fluid className="login-page">
      <div className="login-container">
        <h2 className="animated-header">Welcome Back, Rider!</h2>
        <p className="animated-paragraph">
          Log in to get back to the fast lane!
        </p>

        <Form onSubmit={handleSignIn}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              <EnvelopeFill /> Email address
            </Form.Label>
            <Form.Control
              type="email"
              value={userEmail}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="off"
              className="input-field"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              <LockFill /> Password
            </Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="off"
              className="input-field"
            />
          </Form.Group>
          <Button
            variant="primary"
            size="lg"
            disabled={submitting}
            type="submit"
            className="w-100 submit-btn"
          >
            {submitting ? "Signing In..." : "Sign In!"}
          </Button>
        </Form>

        <NavigationPage />
      </div>
    </Container>
  );
};

export default LoginPage;
