import { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../auth/authProvider";
import formatNumberWithCommas from "../components/numberFormat";
import FetchBusinessCompleteness from "../components/fetchBusiness";
import FetchPostViews from "../components/fetchViews";
import FetchImageVendor from "../components/fetchImageVendor";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import FetchImageCompleteness from "../components/fetchImageCompleteness";
import profile from "../images/profile.png";
import noPost from "../images/noPost.png";
import CustomNavbar from "../headerpages/header";
import PageLoader from "../components/pageLoader";

interface Category {
  id: number;
  itemsOnSale: string;
}

interface ItemsData {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
}

interface userBusiness {
  createdAt: string;
  id: number;
  itemsSold: number;
  businessContacts: string;
  businessEmail: string;
  businessLocation: string;
  returnPolicy: boolean;
  returnDays: number;
  businessName: string;
  businessNature: string;
  businessDescription: string;
}

const VendorItems = () => {
  const [categories, setcategories] = useState<Category[]>([]);
  const [items, setItems] = useState<ItemsData[]>([]);
  const [businessData, setBusinessData] = useState<userBusiness | null>(null);
  const [productName, setProductName] = useState("");
  const [itemCategory, setitemCategory] = useState(0);
  const [priceOfItem, setPriceOfItem] = useState(0);
  const [negotiable, setNegotiable] = useState(true);
  const [businessName, setBusinessName] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [businessContacts, setBusinessContacts] = useState("254 ");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [returnPolicy, setReturnPolicy] = useState(false);
  const [returnDays, setReturnDays] = useState(0);
  const [businessDescription, setBusinessDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();

  // Modal for posting an add
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Modal for user profile creation
  const [profileModal, setProfileModal] = useState(false);
  const showProfileModal = () => setProfileModal(true);
  const CloseProfileModal = () => setProfileModal(false);

  useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/system-data/get-items-on-sale-categories`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = await response?.json();

        if (response?.ok) {
          setcategories(data);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchCategoriesData();
  }, []);

  useEffect(() => {
    const fetchBusinessData = async () => {
      setloading(true);
      try {
        const response = await fetch(
          `${BASE_API_URL}/user/get-business-details`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response?.ok) {
          const businessDetails = await response?.json();
          setBusinessData(businessDetails);
        }
      } catch (error) {
        toast.error(`Error: ${error}`);
      } finally {
        setloading(false);
      }
    };

    fetchBusinessData();
  }, []);

  useEffect(() => {
    const fetchVendorItemsData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/vendor-items/get-my-items-for-sale`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const dataOne = await response.json();

        if (response.ok) {
          setItems(dataOne);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchVendorItemsData();
  }, []);

  const handleCreateProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const businessProfile = {
        businessName,
        businessContacts,
        businessEmail,
        businessNature,
        businessLocation,
        returnPolicy,
        returnDays,
        businessDescription,
      };

      const response = await fetch(
        `${BASE_API_URL}/user/create-business-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(businessProfile),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Business profile created!";
        toast.success(successMessage);
        CloseProfileModal();
        setBusinessData(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePostItem = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const itemsOnSale = {
        productName,
        itemCategory,
        priceOfItem,
        negotiable,
      };

      const response = await fetch(
        `${BASE_API_URL}/vendor-items/create-item-for-sale`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(itemsOnSale),
        }
      );

      const responseData = await response?.json();
      const { statusCode, message, itemId } = responseData;

      if (response.ok) {
        toast.success(`Product saved. Add product details.`);
        navigate(`/item-add-details/${itemId}`);

        // Clear form fields after successful submission
        setProductName("");
        setitemCategory(0);
        setPriceOfItem(0);
        setNegotiable(true);
      } else {
        const errorMessage = `${statusCode} -  ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewClick = (id: number) => {
    navigate(`/my-items-on-sale/${id}`);
  };

  const handleMyBusinessProfile = () => {
    navigate(`/my-business-profile`);
  };

  const handleSalesDashbaord = () => {
    navigate(`/my-sales-dashboard`);
  };

  return (
    <AuthProvider>
      <div className="body">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <CustomNavbar />

            {businessData ? (
              <>
                <Container
                  fluid
                  style={{
                    backgroundColor: "#F5F0F0",
                    borderRadius: "20px",
                    padding: "30px",
                  }}
                >
                  <Container>
                    <h5 style={{ fontWeight: "bold" }} className="text-danger">
                      Polite Notice
                    </h5>
                    <br />
                    1. Since we operate in Kenya, we only allow the sale of
                    strictly <b>Kenyan-registered bikes.</b> Non-Kenyan
                    registered posts will be removed.
                    <br />
                    2. Only posts with a <b>description and images</b> are
                    visible to customers. Having trouble uploading images? Try
                    uploading 2 images at a time.
                    <br />
                    <br />
                    Happy selling!
                  </Container>
                </Container>
                <Container>
                  <Row>
                    <Col
                      md={12}
                      style={{
                        marginRight: "50px",
                        marginBottom: "50px",
                        marginTop: "50px",
                      }}
                    >
                      <h5
                        className="text-danger"
                        style={{
                          marginRight: "30px",
                          marginBottom: "40px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        My items on sale
                      </h5>
                      <Button
                        variant="primary"
                        onClick={handleShow}
                        size="sm"
                        style={{ marginRight: "10px" }}
                      >
                        Click here to sell
                      </Button>
                      <Button
                        variant="outline-primary"
                        onClick={handleSalesDashbaord}
                        size="sm"
                        style={{ marginRight: "10px" }}
                      >
                        Sales dashboard
                      </Button>
                      <Button
                        variant="outline-primary"
                        onClick={handleMyBusinessProfile}
                        size="sm"
                      >
                        Manage profile
                      </Button>

                      <hr />
                    </Col>
                  </Row>
                </Container>

                <Container className="center" style={{ marginBottom: "0px" }}>
                  <Row>
                    <>
                      {items.length > 0 ? (
                        <>
                          {items.map((item) => (
                            <Col key={item.id} md={3} className="margin-bottom">
                              <div className="product-card">
                                <div className="image-container position-relative">
                                  <FetchImageVendor postId={item.id} />
                                  <div
                                    className={`availability-badge ${
                                      item.availability === true
                                        ? "sold"
                                        : "available"
                                    }`}
                                  >
                                    {item.availability ? "Available" : "Sold"}
                                  </div>

                                  <div
                                    onClick={() => handleViewClick(item.id)}
                                    className="icon-container business"
                                  ></div>
                                </div>
                                <br />
                                {item.productName}
                                <br />
                                Ksh: {formatNumberWithCommas(item.priceOfItem)}
                                <br />
                                <FetchBusinessCompleteness postId={item.id} />
                                <FetchImageCompleteness postId={item.id} />
                                <br />
                                <div style={{ color: "gray" }}>
                                  <FaEye className="margin-space" />
                                  <FetchPostViews postId={item.id} /> views
                                </div>
                                <div className="view-more-container">
                                  <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => handleViewClick(item.id)}
                                  >
                                    <FaEye className="margin-space" />
                                    view item details
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={noPost}
                            alt="Business Logo"
                            className="img-fluid"
                          />
                          <br />
                          <br />
                          <h5>You havent posted anything yet</h5>
                        </div>
                      )}
                    </>
                  </Row>
                </Container>
              </>
            ) : (
              <>
                <Container
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={profile}
                    alt="Business Logo"
                    className="img-fluid"
                  />
                  <br />
                  <br />
                  <span>
                    <ExclamationTriangleFill className="mr-2" size={15} /> You
                    have no business account, create one and start selling!
                  </span>
                  <br />
                  <br />
                  <Button onClick={showProfileModal} variant="info" size="sm">
                    Create business profile
                  </Button>
                </Container>
              </>
            )}

            <Modal show={profileModal} onHide={CloseProfileModal}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
                  <Form onSubmit={handleCreateProfile}>
                    <Form.Group className="mb-4">
                      <Form.Label>Business name *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business contacts *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={businessContacts}
                        onChange={(e) => setBusinessContacts(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business email / Personal email</Form.Label>
                      <Form.Control
                        type="email"
                        required
                        value={businessEmail}
                        onChange={(e) => setBusinessEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business Nature</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        value={businessNature}
                        onChange={(e) => setBusinessNature(e.target.value)}
                      >
                        <option value="" disabled>
                          Select business nature
                        </option>
                        <option value="Online based">Online based</option>
                        <option value="Physical location">
                          Physical location
                        </option>
                        <option value="Hybrid">Hybrid</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business location</Form.Label>
                      <Form.Control
                        type="text"
                        value={businessLocation}
                        onChange={(e) => setBusinessLocation(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Return policy</Form.Label>
                      <Form.Check
                        type="checkbox"
                        checked={returnPolicy}
                        onChange={(e) => setReturnPolicy(e.target.checked)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>
                        Return days{" "}
                        <small>(If return policy is accepted)</small>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={returnDays}
                        onChange={(e) =>
                          setReturnDays(parseInt(e.target.value, 10))
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>
                        Business description <small>(Optional)</small>{" "}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="What makes your business to stand out ... . . 
                quality, experience, delivery, customer reviews, location, honesty, 
                customer care, years of experience, vast knowledge of products ... . e.t.c"
                        rows={10}
                        value={businessDescription}
                        onChange={(e) => setBusinessDescription(e.target.value)}
                      />
                    </Form.Group>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleClose}
                        disabled={isSubmitting}
                      >
                        Close modal
                      </Button>
                      <Button variant="primary" type="submit" size="sm">
                        {isSubmitting === true
                          ? "Creating profile"
                          : "Create profile"}
                      </Button>
                    </Modal.Footer>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <Form onSubmit={handlePostItem}>
                  <Form.Group className="mb-4">
                    <Form.Label>Product name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Example: Yamaha R6, LS2 Helmet, Yoshimura Exhaust"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Item Category</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      value={itemCategory}
                      onChange={(e) =>
                        setitemCategory(parseInt(e.target.value, 10))
                      }
                    >
                      <option value=""> </option>
                      {categories.map((category, index) => (
                        <option key={index} value={category.id}>
                          {category.itemsOnSale}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Item price (Ksh.)</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      value={priceOfItem}
                      onChange={(e) =>
                        setPriceOfItem(parseInt(e.target.value, 10))
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Price is negotiable</Form.Label>
                    <Form.Check
                      type="checkbox"
                      defaultChecked={negotiable}
                      onChange={(e) => setNegotiable(e.target.checked)}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={handleClose}
                      disabled={isSubmitting}
                    >
                      Close modal
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      size="sm"
                      disabled={isSubmitting}
                    >
                      {isSubmitting === true
                        ? "Creating advert"
                        : "Create advert"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>

            <Footer />
          </>
        )}
      </div>
    </AuthProvider>
  );
};

export default VendorItems;
