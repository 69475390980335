import { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Image,
} from "react-bootstrap";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import { BsBuilding, BsFileText } from "react-icons/bs";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import AuthProvider from "../auth/authProvider";
import CustomNavbar from "../headerpages/header";
import PageLoader from "../components/pageLoader";

interface userProfileData {
  createdAt: string;
  id: number;
  fullName: string;
  userEmail: string;
  password: string;
  emailVerified: boolean;
  lastSeen: string;
  restrictEmails: boolean;
}

const MyProfile = () => {
  const [profile, setprofile] = useState<userProfileData | null>(null);
  const [editedFullName, setEditedFullname] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [loading, setloading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const FetchProfileData = async () => {
      setloading(true);
      try {
        const response = await fetch(`${BASE_API_URL}/user/get-my-profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });

        if (response?.ok) {
          const data = await response?.json();
          setprofile(data.user);
        }
      } catch (error) {
        toast.error(`${error}`);
      } finally {
        setloading(false);
      }
    };

    FetchProfileData();
  }, []);

  useEffect(() => {
    if (profile) {
      setEditedFullname(profile.fullName);
      setEditedEmail(profile.userEmail);
    }
  }, [profile]);

  const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const userData = {
        fullName: editedFullName,
        userEmail: editedEmail,
        password,
        confirmPassword,
      };
      const response = await fetch(`${BASE_API_URL}/user/edit-profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();
      const { statusCode, message } = data;

      if (response.ok) {
        toast.success(`New profile changes saved`);
        setPassword("");
        setConfirmPassword("");
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("Failed, please try again");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AuthProvider>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className="body">
            <CustomNavbar />
            <Container
              fluid
              className="py-4"
              style={{ backgroundColor: "#F5F0F0" }}
            >
              <Container style={{ color: "gray" }}>
                Home | Profile | My Profile
              </Container>
            </Container>
            <br />
            <br />
            <Container className="my-4">
              <div className="shadow-lg p-4 bg-light rounded">
                {/* Header */}
                <Row className="border-bottom pb-2 mb-4">
                  <Col xs={12} md={6}>
                    <h5 className="text-danger d-flex align-items-center bold">
                      <BsBuilding className="me-2 bold" /> My Profile
                    </h5>
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    className="text-md-end text-muted mt-2 mt-md-0"
                  >
                    <small>Review and update your account details</small>
                  </Col>
                </Row>

                {/* Profile Section */}
                <Row>
                  <Col md={4}>
                    <Card className="profile-card">
                      <Card.Body className="text-center">
                        {/* Profile Picture */}
                        <Image
                          src="https://via.placeholder.com/150"
                          alt="Profile Picture"
                          roundedCircle
                          className="profile-image mb-3"
                        />
                        {profile && (
                          <>
                            <div className="profile-info-card">
                              <h5 className="profile-name">
                                {profile.fullName}
                              </h5>
                              <hr />
                              <div className="profile-details">
                                <div className="profile-item">
                                  <span className="profile-item-label">
                                    Created:
                                  </span>
                                  <span className="profile-item-value">
                                    {profile.createdAt}
                                  </span>
                                </div>
                                <div className="profile-item">
                                  <span className="profile-item-label">
                                    Email:
                                  </span>
                                  <span className="profile-item-value">
                                    {profile.userEmail}
                                  </span>
                                </div>
                                <div className="profile-item">
                                  <span className="profile-item-label">
                                    Verification:
                                  </span>
                                  <span className="profile-item-value">
                                    {profile.emailVerified
                                      ? "Verified"
                                      : "Not Verified"}
                                  </span>
                                </div>
                                <div className="profile-item">
                                  <span className="profile-item-label">
                                    Promotional emails:
                                  </span>
                                  <span className="profile-item-value">
                                    {profile.restrictEmails
                                      ? "Restricted"
                                      : "Unrestricted"}
                                  </span>
                                </div>
                                <div className="profile-item">
                                  <span className="profile-item-label">
                                    Last seen:
                                  </span>
                                  <span className="profile-item-value">
                                    {profile.lastSeen}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>

                  {/* Edit Profile Section */}
                  <Col md={8}>
                    <div className="border p-4 rounded bg-white mb-4 shadow-sm">
                      <p className="profile-info-text">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h5 className="text-dark">
                            <BsFileText className="me-2" />
                            <strong>Edit Profile</strong>
                          </h5>
                        </div>
                        <span className="profile-description">
                          Ensure your account information is up-to-date and
                          correct.
                        </span>
                      </p>

                      <Form onSubmit={handleRegister}>
                        <Form.Group controlId="formFullName" className="mb-4">
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            value={editedFullName}
                            onChange={(e) => setEditedFullname(e.target.value)}
                            required
                            className="input-style"
                          />
                        </Form.Group>
                        <Form.Group controlId="formEmail" className="mb-4">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            value={editedEmail}
                            onChange={(e) => setEditedEmail(e.target.value)}
                            required
                            className="input-style"
                          />
                        </Form.Group>
                        <Form.Group controlId="formPassword" className="mb-4">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="input-style"
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="formConfirmPassword"
                          className="mb-4"
                        >
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="input-style"
                          />
                        </Form.Group>
                        <Button
                          variant="danger"
                          type="submit"
                          disabled={submitting}
                          className="w-100 mt-3"
                        >
                          {submitting
                            ? "Saving new details..."
                            : "Save changes"}
                        </Button>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <Footer />
          </div>
        </>
      )}
    </AuthProvider>
  );
};

export default MyProfile;
