import { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Card,
  Table,
} from "react-bootstrap";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import AuthProvider from "../auth/authProvider";
import { Spinner } from "react-bootstrap";
import formatNumberWithCommas from "../components/numberFormat";
import FetchBusinessCompleteness from "../components/fetchBusiness";
import FetchPostViews from "../components/fetchViews";
import FetchImageCompleteness from "../components/fetchImageCompleteness";
import noPost from "../images/noPost.png";
import TimeDisplayProps from "../components/timeFormat";
import ContactClicks from "../vendorNumbers/get-contact-views";
import GetCategory from "../vendorNumbers/get-category";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CustomNavbar from "../headerpages/header";

interface ItemsData {
  id: number;
  productName: string;
  createdAt: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
  productImages: number;
}

interface userBusiness {
  createdAt: string;
  id: number;
  itemsSold: number;
  businessContacts: string;
  businessEmail: string;
  businessLocation: string;
  returnPolicy: boolean;
  returnDays: number;
  businessName: string;
  businessNature: string;
  businessDescription: string;
}

const SalesDashboard = () => {
  const [items, setItems] = useState<ItemsData[]>([]);
  const [allItems, setAll] = useState<number | null>(null);
  const [availableItems, setAvailable] = useState<number | null>(null);
  const [itemViews, setViews] = useState<number | null>(null);
  const [soldItems, setSold] = useState<number | null>(null);
  const [businessData, setBusinessData] = useState<userBusiness | null>(null);
  const [businessName, setBusinessName] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [businessContacts, setBusinessContacts] = useState("254 ");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [returnPolicy, setReturnPolicy] = useState(false);
  const [returnDays, setReturnDays] = useState(0);
  const [businessDescription, setBusinessDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  // Modal for user profile creation
  const [profileModal, setProfileModal] = useState(false);
  const showProfileModal = () => setProfileModal(true);
  const CloseProfileModal = () => setProfileModal(false);

  useEffect(() => {
    const fetchVendorItemsData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/vendor-items/get-my-items-for-sale`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const dataOne = await response.json();

        if (response.ok) {
          setItems(dataOne);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchVendorItemsData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        // Fetch all items
        const allResponse = await fetch(
          `${BASE_API_URL}/vendor-items/get-items-posted`,
          { method: "GET", headers }
        );
        const allData = await allResponse.json();
        if (allResponse.ok) {
          setAll(allData);
        }

        // Fetch available
        const availableItems = await fetch(
          `${BASE_API_URL}/vendor-items/get-items-available`,
          { method: "GET", headers }
        );
        const available = await availableItems.json();
        if (allResponse.ok) {
          setAvailable(available);
        }

        // Fetch sold items
        const soldItems = await fetch(
          `${BASE_API_URL}/vendor-items/get-items-sold`,
          { method: "GET", headers }
        );
        const sold = await soldItems.json();
        if (allResponse.ok) {
          setSold(sold);
        }

        // Fetch items view
        const totalViews = await fetch(
          `${BASE_API_URL}/vendor-items/get-all-vendor-views`,
          { method: "GET", headers }
        );
        const Views = await totalViews.json();
        if (allResponse.ok) {
          setViews(Views);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/user/get-business-details`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response?.ok) {
          const businessDetails = await response?.json();
          setBusinessData(businessDetails);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchBusinessData();
  }, []);

  const handleCreateProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const businessProfile = {
        businessName,
        businessContacts,
        businessEmail,
        businessNature,
        businessLocation,
        returnPolicy,
        returnDays,
        businessDescription,
      };

      const response = await fetch(
        `${BASE_API_URL}/user/create-business-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(businessProfile),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Business profile created!";
        toast.success(successMessage);
        CloseProfileModal();
        setBusinessData(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewClick = (id: number) => {
    navigate(`/my-items-on-sale/${id}`);
  };

  return (
    <AuthProvider>
      <div className="body">
        <CustomNavbar />

        <Container
          fluid
          style={{
            backgroundColor: "#F5F0F0",
            borderRadius: "20px",
            padding: "30px",
          }}
        >
          <Container>
            <Row>
              <Col md={12}>
                <p className="title">
                  <h5
                    className="text-danger"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Business summary
                  </h5>
                </p>
                <hr />
                {businessData ? (
                  <>
                    <b>Total items posted: </b>
                    {allItems !== null ? (
                      allItems
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}
                    <br />
                    <b>Total available items: </b>{" "}
                    {availableItems !== null ? (
                      availableItems
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}
                    <br />
                    <b>Total sold items: </b>{" "}
                    {soldItems !== null ? (
                      soldItems
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}
                    <br />
                    <br />
                    <b>Total item views: </b>{" "}
                    {itemViews !== null ? (
                      itemViews
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}
                    <br />
                    <br />
                    Your contact has been clicked: <ContactClicks /> times
                  </>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={noPost}
                      alt="Business Logo"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    <h5>No business profile found</h5>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Container>
        {businessData ? (
          <>
            <Container style={{ marginTop: "70px" }}>
              <Row>
                <Col md={12}>
                  <h5 style={{ fontWeight: "bold" }} className="text-danger">
                    Sales tracking table
                  </h5>
                  Track your products details here and watch your business grow.
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <div className="table-responsive">
                        <Table hover responsive>
                          <thead style={{ backgroundColor: "#FFF5EE" }}>
                            <tr>
                              <th>Date</th>
                              <th>Item</th>
                              <th>Category</th>
                              <th>Price</th>
                              <th>Negotiable</th>
                              <th>Availability</th>
                              <th>Views</th>
                              <th>Details</th>
                              <th>Images</th>
                              <th>Detail</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.length > 0 ? (
                              items.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    {" "}
                                    <TimeDisplayProps
                                      timeString={item.createdAt}
                                    />
                                  </td>
                                  <td>{item.productName}</td>
                                  <td>
                                    <GetCategory
                                      categoryId={item.itemCategory}
                                    />
                                  </td>
                                  <td>
                                    {formatNumberWithCommas(item.priceOfItem)}
                                  </td>
                                  <td>
                                    {item.negotiable === true ? "Yes" : "No"}
                                  </td>
                                  <td>
                                    {item.availability === true
                                      ? "Available"
                                      : "Sold"}
                                  </td>
                                  <td>
                                    <FetchPostViews postId={item.id} />
                                  </td>
                                  <td>
                                    <FetchBusinessCompleteness
                                      postId={item.id}
                                    />
                                  </td>
                                  <td>
                                    <FetchImageCompleteness postId={item.id} />
                                  </td>
                                  <td>
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      onClick={() => handleViewClick(item.id)}
                                    >
                                      <FaEye className="margin-space" />
                                      Item details
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={10}
                                  style={{ textAlign: "center" }}
                                >
                                  No items found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Card.Body>
                  </Card>
                  <br></br>
                </Col>
                <Col md={6}></Col>
              </Row>
            </Container>
          </>
        ) : (
          <></>
        )}
        {/* Modal for posting an AD */}
        {businessData ? null : (
          <Button
            onClick={showProfileModal}
            variant="info"
            size="sm"
            style={{
              position: "fixed",
              bottom: "180px",
              right: "80px",
            }}
          >
            Create business profile
          </Button>
        )}
        <Modal show={profileModal} onHide={CloseProfileModal}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              <Form onSubmit={handleCreateProfile}>
                <Form.Group className="mb-4">
                  <Form.Label>Business name *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Business contacts *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={businessContacts}
                    onChange={(e) => setBusinessContacts(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Business email / Personal email</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    value={businessEmail}
                    onChange={(e) => setBusinessEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Business Nature</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    value={businessNature}
                    onChange={(e) => setBusinessNature(e.target.value)}
                  >
                    <option value="" disabled>
                      Select business nature
                    </option>
                    <option value="Online based">Online based</option>
                    <option value="Physical location">Physical location</option>
                    <option value="Hybrid">Hybrid</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Business location</Form.Label>
                  <Form.Control
                    type="text"
                    value={businessLocation}
                    onChange={(e) => setBusinessLocation(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Return policy</Form.Label>
                  <Form.Check
                    type="checkbox"
                    checked={returnPolicy}
                    onChange={(e) => setReturnPolicy(e.target.checked)}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Return days <small>(If return policy is accepted)</small>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={returnDays}
                    onChange={(e) =>
                      setReturnDays(parseInt(e.target.value, 10))
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Business description <small>(Optional)</small>{" "}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="What makes your business to stand out ... . . 
                quality, experience, delivery, customer reviews, location, honesty, 
                customer care, years of experience, vast knowledge of products ... . e.t.c"
                    rows={10}
                    value={businessDescription}
                    onChange={(e) => setBusinessDescription(e.target.value)}
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={CloseProfileModal}
                    disabled={isSubmitting}
                  >
                    Close modal
                  </Button>
                  <Button variant="primary" type="submit" size="sm">
                    {isSubmitting === true
                      ? "Creating profile"
                      : "Create profile"}
                  </Button>
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default SalesDashboard;
