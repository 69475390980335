import React, { useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import * as jwt from "jwt-decode";
import { toast } from "react-toastify";

interface AuthProviderProps {
  children: ReactNode;
}

interface DecodedToken extends jwt.JwtPayload {
  exp: number;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem("token");
      const tokenExists = !!token;

      if (!tokenExists) {
        navigate("/");
        toast.error("Error: Access denied due to unverified token");
        return;
      }

      try {
        const decodedToken = jwt.jwtDecode(token) as DecodedToken;

        const expiresAt = decodedToken.exp;
        const currentTimestamp = Date.now() / 1000;

        if (expiresAt < currentTimestamp) {
          localStorage.removeItem("token");
          toast.info("Session has expired. Please log in again.");
          navigate("/");
          window.location.reload();
        }
      } catch (error) {
        toast.error("Error verifying token");
        localStorage.removeItem("token");
        navigate("/");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  return <div>{children}</div>;
};

export default AuthProvider;
