import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import { CheckCircle, XCircle } from "react-bootstrap-icons";

interface ImagesDetails {
  postId: number;
}

const FetchImageCompleteness: React.FC<ImagesDetails> = ({ postId }) => {
  const [completeness, setCompleteness] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/vendor-items/get-image-completeness/${postId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCompleteness(data ? "Image/s available" : "Image/s unavailable");
        }
      } catch (error) {
        toast.error(`${error}`);
        setCompleteness("Image/s unavailable");
      }
    };

    fetchData();
  }, [postId]);

  return (
    <div
      style={{
        color: completeness === "Image/s unavailable" ? "red" : "black",
      }}
    >
      {completeness ? (
        completeness === "Image/s available" ? (
          <div>
            <CheckCircle style={{ color: "green" }} /> {completeness}
          </div>
        ) : (
          <div>
            <XCircle style={{ color: "red" }} /> {completeness}
          </div>
        )
      ) : (
        "Image/s unavailable"
      )}
    </div>
  );
};

export default FetchImageCompleteness;
