import React from "react";
import { Spinner } from "react-bootstrap";

const PageLoader: React.FC = () => {
  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{
          position: "absolute",
          padding: "20px",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Slightly more opaque overlay
          zIndex: 1050,
        }}
      >
        <h2
          style={{
            fontSize: "1.75rem",
            fontWeight: 600,
            color: "#dc3545",
            marginBottom: "0.5rem",
          }}
        >
          AMD.bike
        </h2>
        <p
          className="text-muted"
          style={{ fontSize: "1rem", marginBottom: "1rem" }}
        >
          Find Your Dream Sport Bike, Riding Gear & Helmets Today!
        </p>
        <Spinner
          animation="border"
          role="status"
          variant="danger"
          style={{ width: "2.5rem", height: "2.5rem" }}
        />
        <p
          style={{
            fontSize: "1rem",
            color: "#dc3545",
            marginTop: "1rem",
          }}
        >
          Fetching available products...
        </p>
      </div>
    </>
  );
};
export default PageLoader;
