import React, { createContext, useState, useEffect, ReactNode } from "react";
import * as jwt from "jwt-decode";

// Define the shape of the decoded JWT token
interface DecodedToken {
  id: number;
  fullName: string;
  userEmail: string;
  role: number;
  adminRights: boolean;
  superAdmin: boolean;
}

// Define the shape of the context value
interface UserContextType {
  id: number;
  fullName: string;
  userEmail: string;
  role: number;
  adminRights: boolean;
  superAdmin: boolean;
}

// Define the default value for context
const defaultContextValue: UserContextType = {
  id: 0,
  fullName: "",
  userEmail: "",
  role: 0,
  adminRights: false,
  superAdmin: false,
};

const UserContext = createContext<UserContextType>(defaultContextValue);

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [id, setUserId] = useState<number>(0);
  const [fullName, setFullName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [role, setRole] = useState<number>(0);
  const [adminRights, setAdminRights] = useState<boolean>(false);
  const [superAdmin, setSuperAdmin] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt.jwtDecode(token) as DecodedToken;
      setUserId(decodedToken.id);
      setFullName(decodedToken.fullName);
      setUserEmail(decodedToken.userEmail);
      setRole(decodedToken.role);
      setAdminRights(decodedToken.adminRights);
      setSuperAdmin(decodedToken.superAdmin);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        id,
        fullName,
        userEmail,
        role,
        adminRights,
        superAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
