// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.load-more-button {
  background-color: #dbd8d8;
  border-color: #007bff;
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  border-radius: 0.25rem;
}

.load-more-button:hover {
  transform: scale(1.05);
  background-color: #0056b3;
  border-color: #0056b3;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.borders-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.text-end {
  text-align: end !important;
}

.btn-delete {
  background-color: red;
  border-color: red;
}

.design {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  background-color: white;
  border-radius: 10px;
}

.backgroundColor {
  background-color: #fdf8f8;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/bodyTwo.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf;wBACsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;EACZ,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;EACxC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".load-more-button {\n  background-color: #dbd8d8;\n  border-color: #007bff;\n  color: black;\n  padding: 10px 20px;\n  font-size: 16px;\n  transition: transform 0.3s ease, background-color 0.3s ease,\n    box-shadow 0.3s ease;\n  border-radius: 0.25rem;\n}\n\n.load-more-button:hover {\n  transform: scale(1.05);\n  background-color: #0056b3;\n  border-color: #0056b3;\n  color: white;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.borders-top {\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n}\n\n.my-4 {\n  margin-top: 1.5rem !important;\n  margin-bottom: 1.5rem !important;\n}\n\n.text-end {\n  text-align: end !important;\n}\n\n.btn-delete {\n  background-color: red;\n  border-color: red;\n}\n\n.design {\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  padding: 15px;\n  background-color: white;\n  border-radius: 10px;\n}\n\n.backgroundColor {\n  background-color: #fdf8f8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
