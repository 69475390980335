import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

interface CategoryIdentity {
  categoryId: number;
}

const GetCategory: React.FC<CategoryIdentity> = ({ categoryId }) => {
  const [category, setCategory] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/vendor-items/get-item-category/${categoryId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setCategory(data.itemsOnSale);
        }
      } catch (error) {
        toast.error(`Error fetching details:, ${error}`);
      }
    };

    fetchData();
  }, [categoryId]);

  return (
    <>
      {category !== null ? category : <Spinner animation="border" size="sm" />}
    </>
  );
};

export default GetCategory;
