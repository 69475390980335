// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Make table borderless */
.table-borderless th,
.table-borderless td {
  border: none;
}

/* Style for table header background */
.table-header-gray thead {
  background-color: gray;
}

/* Remove table header background color */
.table-borderless thead th {
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/table.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;;EAEE,YAAY;AACd;;AAEA,sCAAsC;AACtC;EACE,sBAAsB;AACxB;;AAEA,yCAAyC;AACzC;EACE,6BAA6B;AAC/B","sourcesContent":["/* Make table borderless */\n.table-borderless th,\n.table-borderless td {\n  border: none;\n}\n\n/* Style for table header background */\n.table-header-gray thead {\n  background-color: gray;\n}\n\n/* Remove table header background color */\n.table-borderless thead th {\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
