// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-button {
  background-color: #fa7b2b;
  border-color: #fa7b2b;
  color: white;
}

.delete-button:hover {
  background-color: #d32f2f;
  border-color: #d32f2f;
}

.custom-outline-button {
  border-color: white !important;
  color: white !important;
}

.custom-outline-button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
}

.view-more-container {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.view-more-btn {
  color: black;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.view-more-btn:hover {
  background-color: #b4b0b1;
  transform: scale(1.05);
  color: white;
}

.view-more-btn:focus,
.view-more-btn:active {
  background-color: #b31315;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/button.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE,qDAAqD;EACrD,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,iDAAiD;AACnD;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,YAAY;AACd;;AAEA;;EAEE,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".delete-button {\n  background-color: #fa7b2b;\n  border-color: #fa7b2b;\n  color: white;\n}\n\n.delete-button:hover {\n  background-color: #d32f2f;\n  border-color: #d32f2f;\n}\n\n.custom-outline-button {\n  border-color: white !important;\n  color: white !important;\n}\n\n.custom-outline-button:hover {\n  background-color: rgba(255, 255, 255, 0.1) !important;\n  color: white !important;\n}\n\n.view-more-container {\n  text-align: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.view-more-btn {\n  color: black;\n  border: none;\n  border-radius: 5px;\n  transition: background-color 0.3s, transform 0.3s;\n}\n\n.view-more-btn:hover {\n  background-color: #b4b0b1;\n  transform: scale(1.05);\n  color: white;\n}\n\n.view-more-btn:focus,\n.view-more-btn:active {\n  background-color: #b31315;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
