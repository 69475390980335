// src/hooks/usePageViews.ts
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from "./google-analytics";

const usePageViews = () => {
  const location = useLocation();
  useEffect(() => {
    logPageView();
  }, [location]);
};

export default usePageViews;
