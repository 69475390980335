import { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import { Container, Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import AuthProvider from "../auth/authProvider";
import CustomNavbar from "../headerpages/header";

interface ItemsData {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
  voidStatus: boolean;
}

type RouteParams = {
  id: string;
};

const VendorAddItemDetails = () => {
  const [items, setItems] = useState<ItemsData | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams<RouteParams>();

  const postId = Number(id);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVendorProductData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/vendor-items/get-item-detail-for-vendor/${postId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const itemData = await response.json();

        if (response.ok) {
          setItems(itemData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchVendorProductData();
  }, [postId]);

  const handleUploadlogo = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      const formData = new FormData();
      const fileInput = e.currentTarget[0] as HTMLInputElement;
      const files = fileInput.files;

      if (!files || files.length === 0) {
        console.error("No files selected.");
        setIsSubmitting(false);
        return;
      }

      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });

      const response = await fetch(
        `${BASE_API_URL}/product-images/product-images-upload/${postId}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: formData,
        }
      );

      if (response.ok) {
        navigate("/my-items-on-sale");
      } else {
        const error = await response.json();
        toast.error(error.message);
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthProvider>
      <div className="body">
        <CustomNavbar />

        <Container
          fluid
          style={{
            backgroundColor: "#F5F0F0",
            borderRadius: "20px",
            padding: "30px",
          }}
        >
          <Container>
            <h5 style={{ fontWeight: "bold" }} className="text-danger">
              Polite Notice
            </h5>
            <br />
            1. Since we operate in Kenya, we only allow the sale of strictly{" "}
            <b>Kenyan-registered bikes.</b> Non-Kenyan registered posts will be
            removed.
            <br />
            2. Only posts with a <b>description and images</b> are visible to
            customers. Having trouble uploading images? Try uploading 2 images
            at a time.
            <br />
            <br />
            Happy selling!
          </Container>
        </Container>

        <Container style={{ marginTop: "40px" }}>
          <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
            {items ? (
              <>
                <h5 style={{ fontWeight: "bold" }} className="text-danger">
                  Add images for {items.productName}
                </h5>
              </>
            ) : null}
            <br />
            <br />
            <Form onSubmit={handleUploadlogo}>
              <Form.Group className="mb-4">
                <Form.Control
                  required
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  multiple
                />
              </Form.Group>

              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  size="sm"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting images" : "Submit images"}
                </Button>
              </Modal.Footer>
            </Form>
          </div>
        </Container>
        <br style={{ marginBottom: "80px" }} />
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default VendorAddItemDetails;
