import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import { CheckCircle, XCircle } from "react-bootstrap-icons";

interface BusinessProfile {
  postId: number;
}

const FetchBusinessCompleteness: React.FC<BusinessProfile> = ({ postId }) => {
  const [completeness, setCompleteness] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/vendor-items/get-product-completeness/${postId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCompleteness(data ? "Details available" : "Incomplete details");
        }
      } catch (error) {
        toast.error(`${error}`);
        setCompleteness("Incomplete details");
      }
    };

    fetchData();
  }, [postId]);

  return (
    <div
      style={{ color: completeness === "Incomplete details" ? "red" : "black" }}
    >
      {completeness ? (
        completeness === "Details available" ? (
          <div>
            <CheckCircle style={{ color: "green" }} /> {completeness}
          </div>
        ) : (
          <div>
            <XCircle style={{ color: "red" }} /> {completeness}
          </div>
        )
      ) : (
        "Incomplete details"
      )}
    </div>
  );
};

export default FetchBusinessCompleteness;
