import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";

interface ContactViews {
  postId: number;
}

const FetchContactViews: React.FC<ContactViews> = ({ postId }) => {
  const [viewsCount, setViewsCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/user-view/get-contact-clicks-per-post/${postId}`
        );

        if (response.ok) {
          const data = await response.json();
          setViewsCount(data);
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };

    fetchData();
  }, [postId]);

  return <>{viewsCount !== null ? viewsCount : 0}</>;
};

export default FetchContactViews;
