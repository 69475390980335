import React, { useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import noLogo from "../images/noLogo.png";
import { Col, Container, Row, Pagination, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate } from "react-router-dom";
import CustomNavbar from "../headerpages/header";
import PageLoader from "../components/pageLoader";

interface Vendor {
  id: number;
  userId: number;
  businessName: string;
  businessNature: string;
  businessContacts: string;
  businessEmail: string;
  businessLocation: string;
  returnPolicy: boolean;
  returnDays: number;
  businessDescription: string;
  businessLogo: string;
}

const Vendors: React.FC = () => {
  const [vendor, setVendor] = useState<Vendor[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(false);
  const vendorsPerPage = 20;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVendor = async () => {
      setloading(true);
      try {
        const baseUrl = `${BASE_API_URL}`;
        const methodGet = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        const response = await fetch(
          `${baseUrl}/customer-side/get-vendors`,
          methodGet
        );
        const availableVendors = await response.json();

        if (response.ok) {
          setVendor(availableVendors);
        } else {
          const { statusCode, message } = availableVendors;
          const errorMessage = `${statusCode} - ${message}`;
          toast.error(errorMessage);
        }
      } catch (error) {
        toast.error(`Error: ${error}`);
      } finally {
        setloading(false);
      }
    };

    fetchVendor();
  }, []);

  // Calculate pagination data
  const indexOfLastVendor = currentPage * vendorsPerPage;
  const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
  const currentVendors = vendor.slice(indexOfFirstVendor, indexOfLastVendor);
  const totalPages = Math.ceil(vendor.length / vendorsPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleView = (id: number) => {
    navigate(`/vendor-products/${id}`);
  };

  return (
    <div className="body">
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <CustomNavbar />

          <Container
            style={{ backgroundColor: "#F5F0F0", padding: "40px" }}
            fluid
          >
            <Container style={{ color: "GrayText" }}>
              Home | Registered vendors
            </Container>
          </Container>
          <div className="spacing"></div>
          <Container>
            <h5 className="text-dark" style={{ fontWeight: "bold" }}>
              Registered vendors
            </h5>
            <div className="spacing"></div>
            {vendor.length > 0 ? (
              <>
                {currentVendors.map((data, index) => (
                  <div key={index}>
                    <Card>
                      <Row>
                        <Col md={4}>
                          <img
                            src={data.businessLogo || noLogo}
                            alt="Seller"
                            className="img-fluid"
                            style={{ borderRadius: "15px" }}
                          />
                        </Col>
                        <Col md={8}>
                          <Card.Body>
                            <Card.Title style={{ fontWeight: "bold" }}>
                              {data.businessName}
                            </Card.Title>
                            <div className="spacing"></div>
                            <Card.Text>
                              <p className="vendor-details">
                                <b>Contacts:</b> {data.businessContacts}
                                <br />
                                <b>Email:</b> {data.businessEmail}
                                <br />
                                <b>Location:</b> {data.businessLocation}
                                <br />
                                <b>Accepts returns:</b>{" "}
                                {data.returnPolicy ? "Yes" : "No"}
                                <br />
                                <b>Return days:</b> {data.returnDays}
                                <br />
                                <b>Business details:</b>{" "}
                                {data.businessDescription}
                                {data.userId}
                              </p>
                              <div className="spacing"></div>
                              <div className="spacing"></div>
                              <Row>
                                <Button
                                  onClick={() => handleView(data.id)}
                                  variant="outline-danger"
                                  size="sm"
                                >
                                  View items posted by this vendor
                                </Button>
                              </Row>
                            </Card.Text>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                    <div className="spacing"></div>
                  </div>
                ))}

                <Container className="my-5">
                  <Row className="align-items-center text-center bg-light border rounded py-5">
                    <Col>
                      <h3 className="mb-3">Advertise Here</h3>
                      <p className="text-muted">
                        Reach tens of potential customers by placing your ad
                        with us.
                      </p>
                      <Button variant="primary" size="sm">
                        Contact Us
                      </Button>
                    </Col>
                  </Row>
                </Container>

                <Pagination className="justify-content-center mt-4">
                  <Pagination.First onClick={() => paginate(1)} />
                  <Pagination.Prev
                    onClick={() => paginate(Math.max(1, currentPage - 1))}
                  />
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      paginate(Math.min(totalPages, currentPage + 1))
                    }
                  />
                  <Pagination.Last onClick={() => paginate(totalPages)} />
                </Pagination>
              </>
            ) : (
              <></>
            )}
          </Container>

          <Footer />
        </>
      )}
    </div>
  );
};

export default Vendors;
