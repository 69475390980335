import React, { useEffect } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { getUserId } from "../constants/postViews";
import { toast } from "react-toastify";

interface PostViewProps {
  postId: number;
}

const PostView: React.FC<PostViewProps> = ({ postId }) => {
  useEffect(() => {
    const recordView = async () => {
      try {
        const userId = getUserId();
        const response = await fetch(
          `${BASE_API_URL}/user-view/post-view/${postId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ postId, userId }),
          }
        );

        if (response.ok) {
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    recordView();
  }, [postId]);

  return null;
};

export default PostView;
