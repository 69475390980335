import React, { FormEvent, useContext, useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import {
  Card,
  Spinner,
  Table,
  Pagination,
  Container,
  Button,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { AuthProvider } from "../constants/tokenData";
import Footer from "../headerpages/footer";
import FetchDataCount from "../adminFetchNumbers/fetchTotalUsers";
import FetchDataCountDeletedAccounts from "../adminFetchNumbers/deletedAccounts";
import GetCategory from "../vendorNumbers/get-category";
import FetchBusinessCompleteness from "../components/fetchBusiness";
import FetchImageCompleteness from "../components/fetchImageCompleteness";
import FetchUserDetails from "../admin-fetch-data/fetchUser";
import formatNumberWithCommas from "../components/numberFormat";
import FetchBusinessName from "../admin-fetch-data/fetchBusinessName";
import { UserContext } from "../constants/userContext";
import { BsImageFill } from "react-icons/bs";
import CustomNavbar from "../headerpages/header";
import FetchPostViews from "../components/fetchViews";
import FetchContactViews from "../components/fetchContactClicks";
import FetchContactViewsNet from "../components/fetchContactClicksNet";

interface Userdata {
  productName: string;
  itemCategory: number;
  id: number;
  priceOfItem: number;
  createdAt: string;
  negotiable: boolean;
  availability: boolean;
  userId: number;
}

interface emailsSent {
  createdAt: string;
  emailTo: number;
  messageTitle: string;
  messageContent: string;
  sentBy: number;
}

const FetchAllItems: React.FC = () => {
  const [items, setItems] = useState<Userdata[] | null>(null);
  const [emails, setEmails] = useState<emailsSent[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [userId, setUserId] = useState(0);
  const [postIdentity, setPostIdentity] = useState(0);
  const itemsPerPage = 100;
  const { superAdmin } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

  const [imageModal, setImageModal] = useState(false);
  const showImageModal = (id: number) => {
    setPostIdentity(id);
    setImageModal(true);
  };
  const closeImageModal = () => setImageModal(false);

  const handleClose = () => {
    setMessageTitle("");
    setMessageContent("");
    setShow(false);
  };

  const handleShow = async (id: number) => {
    setSelectedItemId(id);
    setUserId(id);
    setShow(true);

    // Fetch emails sent to the product
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_API_URL}/send-email/get-emails-sent-to-business/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setEmails(data);
      }
    } catch (error) {
      toast.error(`Error fetching emails: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/admin-overview/get-all-items`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setItems(data);
        }
      } catch (error) {
        toast.error(`Error fetching details: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSendEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const gearDetails = {
        emailTo: userId,
        messageTitle,
        messageContent,
      };

      const response = await fetch(
        `${BASE_API_URL}/send-email/send-email-to-business`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(gearDetails),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        handleClose();
        toast.success(`Email sent successfully`);
        setEmails(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error has occured ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const uploadImages = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      const fileInput = e.currentTarget[1] as HTMLInputElement;
      const postIdentityInput = e.currentTarget[0] as HTMLInputElement;
      const files = fileInput.files;

      // Append the post identity to the FormData object
      formData.append("postIdentity", postIdentityInput.value);

      if (!files || files.length === 0) {
        console.error("No files selected.");
        setIsSubmitting(false);
        return;
      }

      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });

      const response = await fetch(
        `${BASE_API_URL}/super-admin/upload-images`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: formData,
        }
      );

      if (response.ok) {
        setIsSubmitting(false);
        window.location.reload();
      } else {
        const error = await response.text();
        toast.error("Upload failed: " + error);
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      throw error;
    }
  };

  // Calculate the number of pages
  const totalPages = items ? Math.ceil(items.length / itemsPerPage) : 1;

  // Determine the users to display on the current page
  const currentUsers = items
    ? items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <AuthProvider>
      <div className="body">
        <CustomNavbar />

        <Container
          fluid
          style={{
            backgroundColor: "#F5F0F0",
            borderRadius: "20px",
            padding: "30px",
          }}
        >
          <div className="spacing"></div>
          <h5 className="text-danger" style={{ fontWeight: "bold" }}>
            Posted items on sale
          </h5>
          <h6>Bikes, riding gear and bike parts</h6>
        </Container>
        <Container fluid>
          <div className="spacing"></div>
          <Card>
            <Card.Body>
              <Row>
                <Col md={3}>
                  <div className="spacing"></div>
                  Total posted items: <FetchDataCount />
                  <br></br>
                  Total sold items: <FetchDataCountDeletedAccounts />
                  <br></br>
                  Total available items: <FetchDataCountDeletedAccounts />
                  <div className="spacing"></div>
                </Col>
                <Col md={3}>
                  <div className="spacing"></div>
                  Total bikes posted: <FetchDataCountDeletedAccounts />
                  <br></br>
                  Total bikes available: <FetchDataCountDeletedAccounts />
                  <br></br>
                  Total bikes Sold: <FetchDataCountDeletedAccounts />
                  <div className="spacing"></div>
                </Col>
                <Col md={3}>
                  <div className="spacing"></div>
                  Total safety gear posted: <FetchDataCountDeletedAccounts />
                  <br></br>
                  Total safety gear available: <FetchDataCountDeletedAccounts />
                  <br></br>
                  Total safety gear Sold: <FetchDataCountDeletedAccounts />
                  <div className="spacing"></div>
                </Col>
                <Col md={3}>
                  <div className="spacing"></div>
                  Total bike parts posted: <FetchDataCountDeletedAccounts />
                  <br></br>
                  Total bike parts available: <FetchDataCountDeletedAccounts />
                  <br></br>
                  Total bike parts Sold: <FetchDataCountDeletedAccounts />
                  <div className="spacing"></div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <div className="spacing"></div>
        <Container fluid>
          <Card>
            <Card.Body>
              <div className="table-responsive">
                {loading ? (
                  <>
                    Loading new users table{" "}
                    <Spinner animation="border" size="sm" />
                  </>
                ) : (
                  <>
                    <Table hover responsive>
                      <thead style={{ backgroundColor: "#FFF5EE" }}>
                        <tr>
                          <th>Date</th>
                          <th>Item</th>
                          <th>Category</th>
                          <th>Price</th>
                          <th>Availability</th>
                          <th>Negotiable</th>
                          <th>Details</th>
                          <th>Images</th>
                          <th>Seller</th>
                          <th style={{ textAlign: "center" }}>Views</th>
                          <th style={{ textAlign: "center" }}>ContactClicks</th>
                          <th style={{ textAlign: "center" }}>NetClicks</th>
                          <th>Contact</th>
                          {superAdmin === true && <th>Add images</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers && currentUsers.length > 0 ? (
                          currentUsers.map((product) => (
                            <tr key={product.id}>
                              <td>
                                {new Date(
                                  product.createdAt
                                ).toLocaleDateString()}
                              </td>
                              <td>{product.productName}</td>
                              <td>
                                <GetCategory
                                  categoryId={product.itemCategory}
                                />
                              </td>
                              <td>
                                {formatNumberWithCommas(product.priceOfItem)}
                              </td>
                              <td>
                                {product.availability === true
                                  ? "Available"
                                  : "Sold"}
                              </td>
                              <td>
                                {product.negotiable === true
                                  ? "Negotiable"
                                  : "Fixed"}
                              </td>
                              <td>
                                <FetchBusinessCompleteness
                                  postId={product.id}
                                />
                              </td>
                              <td>
                                <FetchImageCompleteness postId={product.id} />
                              </td>
                              <td>
                                <FetchUserDetails userId={product.userId} />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <FetchPostViews postId={product.id} />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <FetchContactViews postId={product.id} />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <FetchContactViewsNet postId={product.id} />
                              </td>
                              <td>
                                <Button
                                  size="sm"
                                  variant="primary"
                                  onClick={() => handleShow(product.id)}
                                >
                                  Contact
                                </Button>
                              </td>
                              {superAdmin === true && (
                                <td>
                                  <Button
                                    onClick={() => showImageModal(product.id)}
                                    size="sm"
                                  >
                                    <BsImageFill
                                      className="button-icon"
                                      style={{ marginRight: "7px" }}
                                    />{" "}
                                    Add images
                                  </Button>
                                </td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7} style={{ textAlign: "center" }}>
                              No users found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <div className="spacing"></div>
                    <Pagination>
                      <Pagination.First
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                      />
                      <Pagination.Prev
                        onClick={() =>
                          handlePageChange(
                            currentPage > 1 ? currentPage - 1 : 1
                          )
                        }
                        disabled={currentPage === 1}
                      />
                      {Array.from({ length: totalPages }, (_, idx) => (
                        <Pagination.Item
                          key={idx + 1}
                          active={idx + 1 === currentPage}
                          onClick={() => handlePageChange(idx + 1)}
                        >
                          {idx + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() =>
                          handlePageChange(
                            currentPage < totalPages
                              ? currentPage + 1
                              : totalPages
                          )
                        }
                        disabled={currentPage === totalPages}
                      />
                      <Pagination.Last
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Container>

        {/* Email modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <FetchBusinessName postId={Number(selectedItemId)} />
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
                {emails && emails.length > 0 ? (
                  <div>
                    <h6 style={{ fontWeight: "bold" }}>Previous Emails Sent</h6>
                    <div className="spacing"></div>
                    <ul>
                      {emails.map((email, index) => (
                        <li key={index}>
                          <strong>Title:</strong> {email.messageTitle}
                          <br />{" "}
                          <small style={{ color: "grey" }}>
                            Sent on {new Date(email.createdAt).toLocaleString()}
                          </small>
                          <p>
                            <strong>Email content:</strong>{" "}
                            {email.messageContent}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <>
                    <p>No emails sent for this product.</p>
                  </>
                )}
              </Card.Body>
            </Card>
            <div className="spacing"></div>
            <Card>
              <Card.Body>
                <Form onSubmit={handleSendEmail}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      required
                      hidden
                      type="number"
                      defaultValue={userId}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email title</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={messageTitle}
                      onChange={(e) => setMessageTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email content</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={12}
                      value={messageContent}
                      onChange={(e) => setMessageContent(e.target.value)}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      size="sm"
                      disabled={isSubmitting}
                      variant="secondary"
                      onClick={handleClose}
                    >
                      {isSubmitting === true ? "Close" : "Close"}
                    </Button>
                    <Button
                      size="sm"
                      type="submit"
                      disabled={isSubmitting}
                      variant="primary"
                    >
                      {isSubmitting === true ? "Sending email" : "Send email"}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>

        <Modal show={imageModal} onHide={closeImageModal}>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              <Form onSubmit={uploadImages}>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    hidden
                    type="number"
                    defaultValue={postIdentity}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Add images for item</Form.Label>
                  <Form.Control
                    required
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    multiple
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={closeImageModal}
                  >
                    Exit modal
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    size="sm"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Save item images"}
                  </Button>
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default FetchAllItems;
