// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  background-color: #f8f9fa; /* Background color */
  height: 90px;
  padding: 20px 0;
  margin-top: 10px;
}

.flag-text {
  margin-left: 5px;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/headerCss.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,qBAAqB;EAChD,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB","sourcesContent":[".header-container {\n  background-color: #f8f9fa; /* Background color */\n  height: 90px;\n  padding: 20px 0;\n  margin-top: 10px;\n}\n\n.flag-text {\n  margin-left: 5px;\n}\n\n.btn-success {\n  background-color: #28a745;\n  border-color: #28a745;\n}\n\n.btn-outline-success {\n  color: #28a745;\n  border-color: #28a745;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
