import React, { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Card,
  Pagination,
  Alert,
} from "react-bootstrap";
import CustomNavbar from "../headerpages/header";
import AuthProvider from "../auth/authProvider";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import PageLoader from "../components/pageLoader";
import NoData from "../images/noData.png";
import TimeDisplay from "../components/timeFormat";
import {
  PersonFill,
  Tag,
  PlusCircle,
  ExclamationTriangleFill,
} from "react-bootstrap-icons";

interface Emails {
  id: number;
  createdAt: string;
  messageTitle: string;
  messageContent: string;
  userId: number;
}

interface User {
  id: number;
  fullName: string;
}

const MarketingEmails: React.FC = () => {
  const [emailsSent, setEmailsSent] = useState<Emails[]>([]);
  const [user, setUser] = useState<User[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [messageTitle, setMessageTitle] = useState("");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setMessageTitle("");
    setMessageContent("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const inquiryDetails = { messageContent, messageTitle };

      const response = await fetch(
        `${BASE_API_URL}/marketing-emails/send-an-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(inquiryDetails),
        }
      );

      const data = await response.json();
      if (response.ok) {
        toast.success("Email successfully sent");
        setEmailsSent((prevInquiries) => [data, ...prevInquiries]);
        handleCloseModal();
      } else {
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchEmails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${BASE_API_URL}/marketing-emails/get-all-emails`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setEmailsSent(data.emails);
          setUser(data.user);
        }
      } catch (error) {
        toast.error(`Error: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchEmails();
  }, []);

  // Calculate pagination range
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEmails = emailsSent.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <AuthProvider>
      <div className="body">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <CustomNavbar />
            <Container
              fluid
              className="py-4 mb-3"
              style={{ backgroundColor: "#f0f2f5" }}
            >
              <Container className="text-secondary mb-3">
                <span className="fw-light">Home</span>{" "}
                <span className="px-1">|</span>{" "}
                <span className="fw-light">Inquiries</span>
              </Container>
            </Container>

            <Container
              className="p-4 rounded"
              style={{
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                {/* Header Section */}
                <div className="mb-2 mb-md-0">
                  <h5 className="text-danger fw-bold mb-1">Sent Emails</h5>
                  <p className="text-muted small mb-0">
                    Manage and view all previously sent emails to users.
                  </p>
                  <br />
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <input
                      type="text"
                      placeholder="Search emails..."
                      className="form-control me-3"
                      style={{
                        maxWidth: "250px",
                        borderRadius: "20px",
                        padding: "8px 15px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                  </div>
                </div>

                {/* Button Group */}
                <div className="d-flex justify-content-end w-100 w-md-auto">
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={handleOpenModal}
                    className="d-flex align-items-center me-2 px-3"
                    style={{
                      boxShadow: "0 2px 5px rgba(0, 123, 255, 0.3)",
                      transition: "background-color 0.2s",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor = "#0069d9")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor = "")
                    }
                  >
                    <PlusCircle className="me-2" /> Send an email
                  </Button>
                </div>
              </div>
            </Container>

            <br />
            <Container
              className="p-4 rounded"
              style={{
                backgroundColor: "#ffffff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="text-dark fw-bold">Recent Emails</h5>
              </div>
              <hr />
              <br />
              <br />
              <Row>
                {currentEmails.length > 0 ? (
                  currentEmails.map((data) => (
                    <Col md={6} lg={4} key={data.id} className="mb-4">
                      <Card className="shadow-sm h-100">
                        {/* Card Header */}
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <small className="text-muted">
                            <TimeDisplay timeString={data.createdAt} />
                          </small>
                          <span className="badge bg-success">Email sent</span>
                        </Card.Header>

                        {/* Card Body */}
                        <Card.Body>
                          <Card.Title className="text-dark d-flex">
                            <span>{data.messageTitle}</span>
                          </Card.Title>

                          <Card.Text
                            className="text-muted"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {data.messageContent}
                          </Card.Text>
                        </Card.Body>

                        {/* Card Footer */}
                        <Card.Footer className="d-flex justify-content-between align-items-center">
                          <Button variant="light" size="sm">
                            <Tag className="me-1 text-danger" /> Email ID:{" "}
                            {data.id}
                          </Button>
                          <Button variant="light" size="sm">
                            <PersonFill className="me-1 text-danger" />{" "}
                            {user.find((ui) => ui.id === Number(data.userId))
                              ?.fullName || "Unknown User"}
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col>
                    <div className="text-center py-5">
                      <img
                        src={NoData}
                        alt="No data available"
                        className="img-fluid mb-3"
                        style={{ maxHeight: "200px", borderRadius: "10px" }}
                      />
                      <p className="text-muted font-italic">
                        No emails sent yet. Use the button to start.
                      </p>
                      <Button
                        variant="outline-primary"
                        onClick={handleOpenModal}
                      >
                        <PlusCircle className="me-1" /> Send an Email
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>

              {/* Pagination */}
              <Pagination className="mt-4">
                {Array.from(
                  { length: Math.ceil(emailsSent.length / itemsPerPage) },
                  (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  )
                )}
              </Pagination>

              <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Alert variant="warning" className="d-flex align-items-start">
                    <ExclamationTriangleFill className="me-2" size={24} />
                    <div>
                      <strong>Important:</strong> Please note that once an email
                      is sent, it cannot be edited or deleted. Ensure all
                      details are correct before proceeding, as this action is{" "}
                      <strong>irreversible</strong>. Double-check the content
                      for any errors or missing information to avoid any
                      unintended consequences. By clicking "Send email," you are
                      confirming that the message is final and ready for
                      delivery.
                    </div>
                  </Alert>
                  <br />
                  <Form
                    onSubmit={handleSubmit}
                    className="p-3 border rounded shadow-sm bg-light"
                  >
                    <Form.Group className="mb-4">
                      <Form.Label className="fw-bold">Email Title</Form.Label>
                      <Form.Control
                        type="text"
                        value={messageTitle}
                        onChange={(e) => setMessageTitle(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label className="fw-bold">Email Content</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        value={messageContent}
                        onChange={(e) => setMessageContent(e.target.value)}
                        required
                      />
                      <Form.Text className="text-muted">
                        Ensure your content is concise and informative.
                      </Form.Text>
                    </Form.Group>

                    <Modal.Footer>
                      <Button
                        size="sm"
                        className="me-2"
                        disabled={isSubmitting}
                        variant="outline-secondary"
                        onClick={handleCloseModal}
                      >
                        Close
                      </Button>
                      <Button
                        size="sm"
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Sending emails ...
                          </>
                        ) : (
                          "Send Email"
                        )}
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Modal.Body>
              </Modal>
            </Container>
          </>
        )}
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default MarketingEmails;
