import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { Col, Spinner } from "react-bootstrap";

interface ViewsPerPost {
  postId: number;
}

const FetchDetailsImage: React.FC<ViewsPerPost> = ({ postId }) => {
  const [productImage, setProductImage] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/customer-side/get-product-image-customer/${postId}`,
          {
            method: "GET",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setProductImage(data.imageUrl);
        } else {
          throw new Error("Failed to fetch details");
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };

    fetchData();
  }, [postId]);

  return (
    <div>
      {productImage ? (
        <Col md={12} style={{ textAlign: "center", padding: "30px" }}>
          <img
            style={{ borderRadius: "10px" }}
            src={productImage}
            alt="Product Images"
            className="img-fluid"
          />
        </Col>
      ) : (
        <Col md={12} style={{ textAlign: "center", padding: "30px" }}>
          <Spinner animation="border" size="sm" />
        </Col>
      )}
    </div>
  );
};

export default FetchDetailsImage;
